import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Chart, registerables } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { supabase } from '../supabaseClient';
import Header from './Header';
import { useAuth } from "@clerk/clerk-react";
Chart.register(...registerables);

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

const lightTheme = {
  background: '#f0f0f0',
  color: '#222',
  primary: '#AC544C',
  cardColor: '#fff',
};

const darkTheme = {
  background: 'rgb(31 41 55)',
  color: '#f0f0f0',
  primary: '#AC544C',
  cardColor: 'rgb(55 65 81)',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 100px;
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Card = styled.div`
  width: 33%;
  background-color: ${({ theme }) => theme.cardColor};
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  overflow-x: auto;
  &:hover {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.primary};
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 1.5em;
`;

const CashFlow = () => {
  const { getToken } = useAuth();
  const [theme, setTheme] = useState(lightTheme);
  const [cashFlowData, setCashFlowData] = useState();
  const [symbol, setSymbol] = useState('AAPL');

  const [section1Data, setSection1Data] = useState(null);
  const [section2Data, setSection2Data] = useState(null);
  const [section3Data, setSection3Data] = useState(null);
  const [section4Data, setSection4Data] = useState(null);
  const [section5Data, setSection5Data] = useState(null);
  const [section6Data, setSection6Data] = useState(null);
  const [section7Data, setSection7Data] = useState(null);
  const [section8Data, setSection8Data] = useState(null);
  const [section9Data, setSection9Data] = useState(null);
  const [section10Data, setSection10Data] = useState(null);
  const [section11Data, setSection11Data] = useState(null);
  const [section12Data, setSection12Data] = useState(null);
  const [section13Data, setSection13Data] = useState(null);
  const [section14Data, setSection14Data] = useState(null);
  const [section15Data, setSection15Data] = useState(null);
  const [section16Data, setSection16Data] = useState(null);
  const [section22Data, setSection22Data] = useState(null);
  const [section23Data, setSection23Data] = useState(null);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getToken({ template: "supabase" });
        console.log("Your Clerk JWT:", token);
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };

    fetchToken();
  }, [getToken]);

  const fetchCashFlowData = async () => {
    const { data, error } = await supabase
      .from('CashFlowStatement')
      .select('*')
      .eq('symbol', symbol)
      .eq('period', 'FY');
    if (error) console.error('Error fetching cash flow data:', error);
    else setCashFlowData(data || []);
  };

  useEffect(() => {
    fetchCashFlowData();
  }, [symbol]);

  const chartOptions = {
    scales: {
      'y-left': {
        type: 'linear',
        position: 'left',
        beginAtZero: true,
      },
      'y-right': {
        type: 'linear',
        position: 'right',
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        display: true,
        position: 'top',
      },
    },
  };

  const transformCashFlowData = (data) => {
    setSection1Data({
      labels: data.map((item) => item.date || ''),
      datasets: [{ label: 'netIncome', data: data.map((item) => item.netIncome || 0), type: 'line', fill: false }],
    });
    setSection2Data({
      labels: data.map((item) => item.date || ''),
      datasets: [
        { label: 'depreciationAndAmortization', data: data.map((item) => item.depreciationAndAmortization || 0), backgroundColor: 'rgba(54, 162, 235, 0.5)',yAxisID: 'y-left' },
        { label: 'deferredIncomeTax', data: data.map((item) => item.deferredIncomeTax || 0), backgroundColor: 'rgba(255, 99, 132, 0.5)',yAxisID: 'y-left' },
        { label: 'stockBasedCompensation', data: data.map((item) => item.stockBasedCompensation || 0), type: 'line', fill: false, yAxisID: 'y-right' },
        
      ],
    });
    setSection3Data({
      labels: data.map((item) => item.date || ''),
      datasets: [
        { label: 'changeInWorkingCapital', data: data.map((item) => item.changeInWorkingCapital || 0), type: 'line', fill: false, yAxisID: 'y-right' },
        { label: 'accountsReceivables', data: data.map((item) => item.accountsReceivables || 0), backgroundColor: 'rgba(54, 162, 235, 0.5)',yAxisID: 'y-left' },
        { label: 'inventory', data: data.map((item) => item.inventory || 0), backgroundColor: 'rgba(255, 99, 132, 0.5)',yAxisID: 'y-left' },
      ],
    });
    setSection4Data({
      labels: data.map((item) => item.date || ''),
      datasets: [
        { label: 'accountsPayables', data: data.map((item) => item.accountsPayables || 0), type: 'line', fill: false, yAxisID: 'y-right' },
        { label: 'otherWorkingCapital', data: data.map((item) => item.otherWorkingCapital || 0), backgroundColor: 'rgba(54, 162, 235, 0.5)',yAxisID: 'y-left' },
        { label: 'otherNonCashItems', data: data.map((item) => item.otherNonCashItems || 0), backgroundColor: 'rgba(255, 99, 132, 0.5)',yAxisID: 'y-left' },
      ],
    });
    setSection5Data({
      labels: data.map((item) => item.date || ''),
      datasets: [{ label: 'netCashProvidedByOperatingActivities', data: data.map((item) => item.netCashProvidedByOperatingActivities || 0), type: 'line', fill: false }],
    });
    setSection6Data({
      labels: data.map((item) => item.date || ''),
      datasets: [
        { label: 'investmentsInPropertyPlantAndEquipment', data: data.map((item) => item.investmentsInPropertyPlantAndEquipment || 0), backgroundColor: 'rgba(54, 162, 235, 0.5)',yAxisID: 'y-left' },
        { label: 'acquisitionsNet', data: data.map((item) => item.acquisitionsNet || 0), backgroundColor: 'rgba(255, 99, 132, 0.5)',yAxisID: 'y-right' },
        
        
      ],
    });
    setSection7Data({
      labels: data.map((item) => item.date || ''),
      
      datasets: [
        { label: 'purchasesOfInvestments', data: data.map((item) => item.purchasesOfInvestments || 0), type: 'line', fill: false, yAxisID: 'y-right' },
        { label: 'salesMaturitiesOfInvestments', data: data.map((item) => item.salesMaturitiesOfInvestments || 0), type: 'line', fill: false, yAxisID: 'y-left' },
        
        
      ],
    });
    setSection8Data({
      labels: data.map((item) => item.date || ''),
      datasets: [
        { label: 'otherInvestingActivites', data: data.map((item) => item.otherInvestingActivites || 0), backgroundColor: 'rgba(75, 192, 192, 0.5)' },
        // { label: 'debtRepayment', data: data.map((item) => item.debtRepayment || 0), backgroundColor: 'rgba(54, 162, 235, 0.5)' },
        // { label: 'commonStockRepurchased', data: data.map((item) => item.commonStockRepurchased || 0), backgroundColor: 'rgba(255, 99, 132, 0.5)' },
        // { label: 'dividendsPaid', data: data.map((item) => item.dividendsPaid || 0), type: 'line', fill: false, yAxisID: 'y-right' },
        // { label: 'otherFinancingActivites', data: data.map((item) => item.otherFinancingActivites || 0), backgroundColor: 'rgba(75, 192, 192, 0.5)' },
      ],
    });
    setSection9Data({
      labels: data.map((item) => item.date || ''),
      datasets: [
        { label: 'netCashUsedForInvestingActivites', data: data.map((item) => item.netCashUsedForInvestingActivites || 0), type: 'line', fill: false }
        // { label: 'netCashUsedProvidedByFinancingActivities', data: data.map((item) => item.netCashUsedProvidedByFinancingActivities || 0), type: 'line', fill: false }
    ],
    });
    setSection10Data({
      labels: data.map((item) => item.date || ''),
      datasets: [
        { label: 'netDebtIssuance', data: data.map((item) => item.netDebtIssuance || 0), type: 'line', fill: false,yAxisID: 'y-right' },
        { label: 'longTermNetDebtIssuance', data: data.map((item) => item.netDebtIssuance || 0), yAxisID: 'y-left'},
        { label: 'shortTermNetDebtIssuance', data: data.map((item) => item.netDebtIssuance || 0),yAxisID: 'y-left'}
    ],
    });
    setSection11Data({
      labels: data.map((item) => item.date || ''),
      datasets: [
        { label: 'netStockIssuance', data: data.map((item) => item.netDebtIssuance || 0), type: 'line', fill: false,yAxisID: 'y-right' },
        { label: 'netPreferredStockIssuance', data: data.map((item) => item.netDebtIssuance || 0),yAxisID: 'y-left'},
        { label: 'netDividendsPaid', data: data.map((item) => item.netDebtIssuance || 0),yAxisID: 'y-left'}
    ],
    });
    setSection12Data({
      labels: data.map((item) => item.date || ''),
      datasets: [
        { label: 'otherFinancingActivities', data: data.map((item) => item.otherFinancingActivities || 0),type: 'line', fill: false,  backgroundColor: 'rgba(54, 162, 235, 0.5)' },        
      ],
    });
    setSection13Data({
      labels: data.map((item) => item.date || ''),
      datasets: [{ label: 'netCashProvidedByFinancingActivities', data: data.map((item) => item.netCashProvidedByFinancingActivities || 0), type: 'line', fill: false }],
    });
    setSection14Data({
      labels: data.map((item) => item.date || ''),
      datasets: [
        { label: 'effectOfForexChangesOnCash', data: data.map((item) => item.effectOfForexChangesOnCash || 0), type: 'line', fill: false,yAxisID: 'y-right' },
        { label: 'netChangeInCash', data: data.map((item) => item.netChangeInCash || 0), type: 'line', fill: false,yAxisID: 'y-left' }
    ],
    });
    setSection15Data({
      labels: data.map((item) => item.date || ''),
      datasets: [
        { label: 'cashAtEndOfPeriod', data: data.map((item) => item.cashAtEndOfPeriod || 0), type: 'line', fill: false,yAxisID: 'y-right' },
        { label: 'cashAtBeginningOfPeriod', data: data.map((item) => item.cashAtBeginningOfPeriod || 0),yAxisID: 'y-left'}
    ],
    });
    setSection16Data({
      labels: data.map((item) => item.date || ''),
      datasets: [
        { label: 'operatingCashFlow', data: data.map((item) => item.operatingCashFlow || 0),yAxisID: 'y-left'},
        { label: 'capitalExpenditure', data: data.map((item) => item.capitalExpenditure || 0),yAxisID: 'y-left'},
        { label: 'freeCashFlow', data: data.map((item) => item.freeCashFlow || 0), type: 'line', fill: false,yAxisID: 'y-right' },
    ],
    });
    setSection22Data({
      labels: data.map((item) => item.date || ''),
      datasets: [
        { label: 'incomeTaxesPaid', data: data.map((item) => item.incomeTaxesPaid || 0), type: 'line', fill: false }
    ],
    });
    setSection23Data({
      labels: data.map((item) => item.date || ''),
      datasets: [{ label: 'depreciationAndAmortization', data: data.map((item) => item.depreciationAndAmortization || 0), type: 'line', fill: false }],
    });
    return {};
  };

  useEffect(() => {
    if (cashFlowData) {
      transformCashFlowData(cashFlowData);
    }
  }, [cashFlowData, theme]);

  const handleThemeChange = () => {
    setTheme(theme === lightTheme ? darkTheme : lightTheme);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header layoutModeType={theme === lightTheme ? 'light' : 'dark'} onChangeLayoutMode={handleThemeChange} />
        <MainContent>
          <Card>
            <Title>Net Income</Title>
            {section1Data && <Bar data={section1Data}  />}
          </Card>

          <Card>
            <Title>Depreciation & Amortization</Title>
            {section2Data && <Bar data={section2Data} options={chartOptions} />}
          </Card>

          <Card>
            <Title>Change In Working Capital</Title>
            {section3Data && <Bar data={section3Data} options={chartOptions} />}
          </Card>

          <Card>
            <Title>Account Paybles</Title>
            {section4Data && <Bar data={section4Data} options={chartOptions} />}
          </Card>

          <Card>
            <Title>Net Cash Provided By Operating Activities</Title>
            {section5Data && <Bar data={section5Data}  />}
          </Card>

          <Card>
            <Title>Investment Activities</Title>
            {section6Data && <Bar data={section6Data} options={chartOptions} />}
          </Card>

          <Card>
            <Title>Net Cash Used For Investing Activities</Title>
            {section7Data && <Bar data={section7Data} options={chartOptions} />}
          </Card>

          <Card>
            <Title>Financing Activities</Title>
            {section8Data && <Bar data={section8Data}  />}
          </Card>

          <Card>
            <Title>Net Cash Used Provided By Investing Activities</Title>
            {section9Data && <Bar data={section9Data}  />}
          </Card>

          <Card>
            <Title>Dedt Issuance</Title>
            {section10Data && <Bar data={section10Data} options={chartOptions} />}
          </Card>

          <Card>
            <Title>Stock Issuance</Title>
            {section11Data && <Bar data={section11Data} options={chartOptions} />}
          </Card>

          <Card>
            <Title>Other Financial Activities</Title>
            {section12Data && <Bar data={section12Data}  />}
          </Card>

          <Card>
            <Title>Net Cash Provided by Financial Activities</Title>
            {section13Data && <Bar data={section12Data}/>}
            </Card>

            <Card>
            <Title>Effect of Forex change in cash</Title>
            {section14Data && <Bar data={section14Data} options={chartOptions} />}
          </Card>

          <Card>
            <Title>Cash at end of period</Title>
            {section15Data && <Bar data={section15Data} options={chartOptions} />}
          </Card>

          <Card>
            <Title>Cash Flow</Title>
            {section16Data && <Bar data={section16Data} options={chartOptions} />}
          </Card>



            

          <Card>
            <Title>Income Taxes Paid</Title>
            {section22Data && <Bar data={section22Data}/>}
          </Card>

          <Card>
            <Title>Interest Paid</Title>
            {section23Data && <Bar data={section23Data}  />}
          </Card>
        </MainContent>
      </Container>
    </ThemeProvider>
  );
};

export default CashFlow;