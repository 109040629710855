// src/sanityClient.js
import sanityClient from '@sanity/client';

export const client = sanityClient({
  projectId: 'm8g7evg6', // Your Sanity project ID
  dataset: 'production', // Your Sanity dataset (usually "production")
  apiVersion: '2023-09-14', // Use a current date for the API version
  useCdn: true, // If you want to use the CDN for faster response
});

export const publicClient = sanityClient({
  projectId: 'fnu27aru', // Your Sanity project ID
  dataset: 'production', // Your Sanity dataset (usually "production")
  apiVersion: '2023-09-14', // Use a current date for the API version
  useCdn: true, // If you want to use the CDN for faster response
});
