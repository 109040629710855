import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import styled from 'styled-components';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Container = styled.div`
    min-width: 100%;
  height: 100%;
    display: flex;
`;

const IndustryPESnapshotData = () => {
  const [sectorData, setSectorData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSectorPerformance = async () => {
      try {
        const date = new Date();
        const formattedDate = date.toISOString().split('T')[0];  // Get the date part in YYYY-MM-DD format
        const response = await axios.get(
          `https://financialmodelingprep.com/stable/industry-pe-snapshot?apikey=11cd608ea5136b9fdb748e3a80c1724e&date=${formattedDate}`
        );

        const data = response;
        setSectorData(data);
      } catch (err) {
        console.error('Error fetching sector performance data:', err);
        setError('Failed to load sector performance data');
      }
    };

    fetchSectorPerformance();
  }, []);

  // Prepare data for the bar chart
  const chartData = {
    labels: sectorData.map(item => item.industry),
    datasets: [
      {
        label: 'Average Change',
        data: sectorData.map(item => item.pe),
        backgroundColor: sectorData.map(() => `hsl(${Math.random() * 360}, 70%, 60%)`), // Random colors
        borderColor: sectorData.map(() => '#000'),
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Sector PE',
      },
    },
  };

  return (
    <Container>
      {error ? (
        <p>{error}</p>
      ) : (
        <Bar data={chartData} options={options} />
      )}
    </Container>
  );
};

export default IndustryPESnapshotData;
