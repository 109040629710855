import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import UserSync from "./pages/UserSync";

import { ClerkProvider, RedirectToSignIn } from '@clerk/clerk-react';
import CrispChat from "./common/CrispChat";
// import CrispChatButton from "./common/CrispChatButton";

const store = configureStore({ reducer: rootReducer, devTools: true });

const root = ReactDOM.createRoot(document.getElementById("root"));
const clerkFrontendApi = "pk_test_Y2hhcm1pbmctc2F3Zmx5LTM0LmNsZXJrLmFjY291bnRzLmRldiQ";
root.render(
  <Provider store={store}>
    <React.Fragment>
      
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ClerkProvider publishableKey={clerkFrontendApi}>        
          <CrispChat/>
          {/* <CrispChatButton/> */}
          <App />
          <UserSync />
        </ClerkProvider>
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
