import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import serviceImage3 from '../../assets/images/vegaveta/optimized/pf2.jpg';
import serviceImage4 from '../../assets/images/vegaveta/optimized/9.jpg';
import serviceImage5 from '../../assets/images/vegaveta/optimized/pf1.jpg';
import serviceImage6 from '../../assets/images/vegaveta/optimized/6.jpg';

const CardContainer = styled.div`
  width: 800px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  perspective: 1500px;
`;

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  transition: transform 1s ease-in-out;
  will-change: transform;
  pointer-events: none;
`;

const Card = styled.div`
  width: 300px;
  height: 450px;
  position: absolute;
  transform: rotateY(${({ angle }) => angle}deg) translateZ(400px);
  background: linear-gradient(135deg, #dfe4eb 0%, #5a6b7c 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  color: white;
  padding: 20px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  transition: none;
`;

const CardTitle = styled.h2`
  margin: 0;
  font-size: 1.3rem;
  color: #000;
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover; // Ensures the image covers the container without distortion
`;

const CardContent = styled.p`
  font-size: 1rem;
  color: #fff;
  margin: 10px 0;
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  z-index: 10;
  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
`;

const LeftArrow = styled(ArrowButton)`
  left: -150px;
  background: linear-gradient(135deg, #dfe4eb 0%, #5a6b7c 100%);
  &:hover {
    background: linear-gradient(135deg, #dfe4eb 0%, #5a6b7c 100%);
  }
`;

const RightArrow = styled(ArrowButton)`
  right: -150px;
  background: linear-gradient(135deg, #dfe4eb 0%, #5a6b7c 100%);
  &:hover {
    background: linear-gradient(135deg, #dfe4eb 0%, #5a6b7c 100%);
  }
`;

const cardsData = [
  { image: serviceImage3, title: 'Comprehensive Fundamental Analysis', text: 'In-depth evaluations of companies focusing on financial health, business models, and growth prospects.' },
  { image: serviceImage4, title: 'Regional Analysis', text: 'Gain insights into geographic markets, understanding unique opportunities and risks.' },
  { image: serviceImage5, title: 'In-Depth Portfolio Analysis', text: 'Thorough evaluations with advanced analytics like Monte Carlo simulations, Sharpe Ratio, and VaR.' },
  { image: serviceImage6, title: 'Exclusive Market Insights', text: 'Research reports covering key financial metrics and indicators to stay ahead of market trends.' }
];

const RotatingCards = () => {
  const [rotation, setRotation] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let interval;

    if (!isHovered) {
      interval = setInterval(() => {
        setRotation((prevRotation) => prevRotation + 90);
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [isHovered]);

  const handleLeftArrowClick = () => {
    setRotation((prevRotation) => prevRotation - 90);
  };

  const handleRightArrowClick = () => {
    setRotation((prevRotation) => prevRotation + 90);
  };

  return (
    <CardContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <LeftArrow onClick={handleLeftArrowClick}>&larr;</LeftArrow>
      <CardWrapper style={{ transform: `rotateY(${rotation}deg)` }}>
        {cardsData.map((card, index) => (
          <Card key={index} angle={index * 90}>
            <CardTitle>{card.title}</CardTitle>
            <CardImage src={card.image} alt={card.title} />
            <CardContent>{card.text}</CardContent>
          </Card>
        ))}
      </CardWrapper>
      <RightArrow onClick={handleRightArrowClick}>&rarr;</RightArrow>
    </CardContainer>
  );
};

export default RotatingCards;