import React, { useEffect, useState } from "react";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";

import bullBearImage from '../../assets/images/vegaveta/optimized/main_1.jpg';

import Footer from "../../Components/Common/Footer";
import Header from "../Header";

import serviceImage8 from '../../assets/images/vegaveta/optimized/service8.jpg';

import backGround from '../../assets/images/vegaveta/optimized/bg.jpg';
import backGround1 from '../../assets/images/vegaveta/optimized/trial_consultion.jpg';
import dollar from '../../assets/images/vegaveta/optimized/dollar.jpg';

import CircularCards from "./CardSlider";
import Cards from "./Cards";
import FiveSectionDisplay from "./FiveSectionDisplay";
import { PopupWidget, PopupButton } from "react-calendly";
import SignUpModalComp from "../signup/SignUpModalComp";

// Define your light and dark themes
const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.backgroundColor}; /* Fallback background color */
    color: ${({ theme }) => theme.color};
    transition: background-color 0.3s, color 0.3s;
    font-family: Arial, serif;
  }
`;

const lightTheme = {
  backgroundImage: backGround, // Use imported image directly
  backgroundColor: "#f4f4f4",
  color: '#222',
  primary: '#AC544C',
  secondary: '#b06252',
  captionColor: '#666',
};

const darkTheme = {
  backgroundImage: backGround, // Use imported image directly
  backgroundColor: "#f4f4f4",
  color: '#222',
  primary: '#AC544C',
  secondary: '#b06252',
  captionColor: '#666',
};

// Styled components using theme properties
const Container = styled.div`
  text-align: center;
  font-family: Arial, serif;
  background-image: ${({ theme }) => theme.background};
  background-repeat: ${({ theme }) => theme.backgroundRepeat};
  background-size: ${({ theme }) => theme.backgroundSize};
  background-position: ${({ theme }) => theme.backgroundPosition};
  min-height: 100vh; /* Ensure it covers the full height */
  width: 100%;
`;

const Main = styled.main`
  padding-top: 20px;
`;

const CardTitle = styled.h2`
  font-size: 2.5em;
  font-weight: 500;
  color: #000;
  font-family: Montserrat,Helvetica,Arial,sans-serif;

  @media (max-width: 768px) {
    font-size: 1.8em;
  }
`;

const Title = styled.h1`
  font-size: 2.5em;
  font-weight: 500;
  color: #fff;
  font-weight: bold;
  padding-top: 100px;
  padding-bottom: 70px;
  font-family: Montserrat,Helvetica,Arial,sans-serif;

  @media (max-width: 768px) {
    font-size: 1.8em;
    padding-top: 50px;
    padding-bottom: 30px;
  }
`;

const CardTitle1 = styled.h3`
  font-size: 2.2em;
  color: ${({ theme }) => theme.color};
  font-weight: bold;
  font-family: Arial, serif;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const Subref = styled.p`
  font-size: 2em;
  margin: -30px 0;
  padding: 100px 200px 10px 200px;
  color: #fff;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 90%;
    font-size: 1.2em;
    padding: 50px 20px 10px 20px;
    margin: 0 auto 20px;
  }
`;

const Subref3 = styled.h3`
  font-size: 3em;
  margin: 0px 0;
  padding: 100px 200px 10px 200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  font-weight: bold;
  font-family: Arial, serif;

  @media (max-width: 768px) {
    font-size: 1.8em;
    padding: 50px 20px 10px 20px;
  }
`;

const Subref1 = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  padding: 90px 200px 10px 200px;
  color: #000;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 1.2em;
    padding: 50px 20px 10px 20px;
  }
`;

const Subref8 = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  margin: 0px 0;
  padding: 100px 200px 100px 200px;
  color: #000;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 1.2em;
    padding: 50px 20px 50px 20px;
    width: 90%;
  }
`;

const Subref6 = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  padding: 100px 200px;
  color: #fff;
  background: url(${dollar}) no-repeat;
  background-size: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 1.2em;
    padding: 50px 20px;
  }
`;

const Subref4 = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  margin: 70px 0;
  padding: 10px 200px 40px 200px;
  color: ${({ theme }) => theme.color};
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 1.2em;
    padding: 10px 20px 20px 20px;
  }
`;

const Subref5 = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  margin-bottom: 100px;
  padding: 100px 200px 10px 200px;
  color: ${({ theme }) => theme.color};
  width: 70%;
  margin-top: -100px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 1.2em;
    padding: 50px 20px 10px 20px;
    width: 90%;
  }
`;

const Subref2 = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  margin: 100px 0;
  padding: 50px 200px 0px 100px;
  color: #fff;
  background: url(${backGround1}) no-repeat center center/cover;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  p {
    position: relative;
    color: #fff;
    z-index: 2;
    text-align: center;
    padding: 20px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    font-size: 1.2em;
    padding: 50px 20px 0px 20px;
  }
`;

const Subref7 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  padding: 10px 200px 10px 200px;
  color: ${({ theme }) => theme.color};
  width: 70%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 1.2em;
    padding: 10px 20px 10px 20px;
    width: 90%;
  }
`;

const Image2 = styled.img`
  width: 100%;
  max-width: 900px;
  margin-top: 60px;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const Card1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: ${({ theme }) => theme.backGround};
  color: ${({ theme }) => theme.cardColor};
  overflow: hidden;
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CardContent2 = styled.div`
  width: 100%;
  padding-bottom: 20px;
  font-size: 1.2em;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const CardsContainer1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background: rgb(55 65 81);
  padding: 120px;

  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;

const CardContent1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 100px 0px 100px;
  text-align: center;
  width: 100%;
  font-size: 1.2em;

  @media (max-width: 768px) {
    padding: 50px 20px 0px 20px;
  }
`;

const CustomPopupButton = styled(PopupButton)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent !important;
  border: none !important;
  color: inherit !important;
  font-size: inherit !important;
  padding: 0 !important;
  margin: 0 !important;
  opacity: 0;
`;

const StyledButton = styled.button`
  background: linear-gradient(135deg, #dfe4eb 0%, #5a6b7c 100%);
  color: #000;
  border: none;
  padding: 20px 0px;
  border-radius: 50px;
  cursor: pointer;
  margin-top: 50px;
  font-size: 1.5em;
  width: 500px;
  transition: transform 0.3s ease, background 0.3s ease;
  margin-bottom: 100px;

  &:hover {
    background: linear-gradient(135deg, #5a6b7c 0%, #dfe4eb 100%);
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    width: 80%;
    font-size: 1.2em;
    margin-top: 30px;
    margin-bottom: 50px;
  }
`;

const StyledButton1 = styled.div`
  color: #000;
  border: none;
  padding: 20px 0px;
  border-radius: 50px;
  margin-bottom: 100px;
  margin-top: 50px;
  font-size: 1.1em;
  width: 500px;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 80%;
    font-size: 1em;
    margin-top: 30px;
    margin-bottom: 50px;
  }
`;

const StyledButton2 = styled.button`
  background: linear-gradient(90deg, #dfe4eb, #5a6b7c);
  color: #000;
  border: none;
  padding: 20px 0px;
  border-radius: 50px;
  cursor: pointer;
  margin-bottom: 50px;
  margin-top: 20px;
  font-size: 1.0em;
  width: 500px;
  transition: transform 0.3s ease, background 0.3s ease;
  overflow: hidden;

  &:hover {
    background: linear-gradient(90deg, #5a6b7c, #dfe4eb);
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    width: 80%;
    font-size: 1em;
    margin-top: 10px;
    margin-bottom: 30px;
  }
`;

const CardImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.background};
`;

const CardImage1 = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CardImageOverlay = styled.div`
  position: absolute;
  top: 100px;
  left: 60px;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  color: #000;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  width: 60%;
  font-size: 2.2em;

  h3 {
    margin: 0;
    font-size: 2.5em;
  }

  p {
    margin: 10px 0 0;
    font-weight: bold;
    font-size: 1.2em;
  }

  @media (max-width: 768px) {
    top: 50px;
    left: 20px;
    width: 80%;
    font-size: 1.5em;

    h3 {
      font-size: 1.8em;
    }

    p {
      font-size: 1em;
    }
  }
`;
const StyledContainer = styled.div`
  background-color: #000;
  color: #fff;
  margin-bottom: 10px;
  width: 100%;

  @media (max-width: 768px) {
    width: 200%;
  }
`;
const Homepage = () => {
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      console.log(cleanTheme);

      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const closeSignup = () => setIsSignupOpen(false);
  const openSignup = () => {      
      setIsSignupOpen(true);
  };

  const handleLayoutModeChange = (newMode) => {
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };

  const handleLeafClick = (index) => {
    console.log(`Leaf ${index + 1} clicked!`);
    // Handle the click for the specific leaf here
  };
  
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header
          layoutModeType={theme === lightTheme ? 'light' : 'dark'}
          onChangeLayoutMode={handleLayoutModeChange}
        />
        <Main>        
          <Card1>
            <CardImageContainer>
              <CardImage1 src={bullBearImage} alt="achievement, analysis, background, banking, business, collage, commerce,
              communication, community, competition, computer, concept, connection, corporate,
              data, development, digital, digital technology, economic, exchange, finance, financial,
              future, global, human resources, information, innovation, internet, investment,
              management, market, marketing, media, mobile app, network, networking, online,
              organization, plan, professional, research, social, social media, strategy, streaming,
              team, teamwork, technology, telecommuting" 
                loading="eager" 
                decoding="async"
              />
              <CardImageOverlay>
                <p>Comprehensive financial data, powerful</p><p>analytics, and intuitive portfolio</p> <p> management solutions tailored to your</p><p> investments needs</p>
              </CardImageOverlay>
            </CardImageContainer>
          </Card1>

          <Subref1>
            At VegaVeta, we empower investors with advanced tools and expert insights to manage their stock portfolios effectively. Our platform combines intelligent portfolio management, detailed financial analysis, and advanced analytics, all crafted to help you optimize and achieve your investment goals. With a user-friendly dashboard, we ensure a seamless and efficient experience for all investors.
          </Subref1>
          <StyledButton onClick={openSignup}>Start your free trial</StyledButton>

          <StyledContainer>
          <h1
            style={{
              fontSize: "2.5em",
              fontWeight: "bold",
              color: "#fff",
              paddingTop: "100px",
              paddingBottom: "70px",
              fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
            }}
          >
            Our Unique Advantage
          </h1>

            <FiveSectionDisplay />

            <Subref>
              The QuinBridge Dynamic Model is the foundation of our analytical framework, integrating diverse data sources to provide a comprehensive understanding of the financial landscape. By evaluating key indicators across multiple domains, it answers two critical questions: Is there a risk? Is there an opportunity? This insight equips our clients to navigate uncertainty, identify growth potential, and make informed strategic choices.
            </Subref>
            <StyledButton1></StyledButton1>
          </StyledContainer>
          <Subref2>
            <p>See how VegaVeta can help your management firm reach new and exciting goals for your clients.</p><br />
            <StyledButton2 style={{ fontSize: "0.8em" }} onClick={openSignup}>Start your free trial</StyledButton2>
          </Subref2>
          <Card1>
            <CardContent2>
              <CardTitle1>Discover the ways VegaVeta drives you and your clients toward new success</CardTitle1>
            </CardContent2>
          </Card1>
          <Cards />
          <StyledButton onClick={() => document.querySelector(".calendly-button")?.click()}>
  Schedule a free Consultation
  <CustomPopupButton
    className="calendly-button"
    url="https://calendly.com/vegaveta-marketing"
    rootElement={document.body}
    text="Schedule a free Consultation"
    textColor="#000"
    style={{ display: "none" }} // Hide the actual button
  />
</StyledButton>

          <CardsContainer1 >
            <CircularCards />

          </CardsContainer1>

          <Subref3>Our mission is to simplify intelligent investing.</Subref3>
          <Image2 src={serviceImage8} alt="60, 60s, adult smiling, aged bank, apartment bills, buy house, buyers e, candid middle,
casual mid, computer purchase, couple man, customers living, digital money, document
elderly, economy finance, enjoying grandparents, estate loan, family fun, finance,
financial account, funny bonding, grandfather grandmother, happiness love, happy
laughing, home office, homeowners ebank, household budget, husband together,
internet business, investment, life insurance, mature joy, modern lifestyle, mortgage
investment, new technology, old paper, older retired, online payment, owners pension,
paperwork pay, real people, remote work, retirement age, room plan, senior person,
social table, tax refund, two laptop, using wife, welfare wellbeing50 affectionate, woman
shopping" />
          <CardContent1>
            <CardTitle>Weekly Chartbook</CardTitle>
            <Subref8 style={{ marginTop: "-20px" }}>
              Delivered weekly in a convenient PDF format, our Chartbook provides up-to-date charts and graphs for visual analysis of market trends and performance. This tool helps you stay informed and make data-driven investment decisions.
            </Subref8>
          </CardContent1>
          <Subref6>
            We push the boundaries of investment analysis and portfolio management by integrating advanced artificial intelligence technology with deep financial expertise. Our goal is to empower investors with tools and insights that enhance decision-making and investment performance.
          </Subref6>
          <Subref3>Join Us on Your Investment Journey</Subref3>
          <StyledButton onClick={() => document.querySelector(".calendly-button")?.click()}>
  Schedule a free Consultation
  <CustomPopupButton
    className="calendly-button"
    url="https://calendly.com/vegaveta-marketing"
    rootElement={document.body}
    text="Schedule a free Consultation"
    textColor="#000"
    style={{ display: "none" }} // Hide the actual button
  />
</StyledButton>

          <Subref5>
          With VegaVeta strengthen your competitive edge and future-proof your business with advanced real-time software, enabling growth and delivering exceptional service.
          </Subref5>
          {isSignupOpen && <SignUpModalComp onClose={closeSignup} />}
        </Main>
      </Container>
      <Footer />
    </ThemeProvider>
  );
};

export default Homepage;