import React from "react";
import Chart from "react-apexcharts";

const AreaChart = ({ data }) => {
  const years = [...new Set(data.map((item) => item.Year))].sort((a, b) => a - b);
  
  const revenueData = years.map((year) => {
    const entry = data.find(
      (item) => item.Year === year && item["Subject Descriptor"] === "General government revenue"
    );
    return entry ? entry.Value : null;
  });

  const expenditureData = years.map((year) => {
    const entry = data.find(
      (item) => item.Year === year && item["Subject Descriptor"] === "General government total expenditure"
    );
    return entry ? entry.Value : null;
  });

  const chartOptions = {
    chart: {
      type: "area",
      height: 350,
      toolbar: { show: true },
    },
    xaxis: {
      categories: years,
      title: { text: "Year", style: { fontSize: "14px", fontWeight: "bold" } },
    },
    yaxis: {
      title: { text: "Value (in National Currency)", style: { fontSize: "14px", fontWeight: "bold" } },
    },
    dataLabels: { enabled: false },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.5,
        opacityFrom: 0.7,
        opacityTo: 0.3,
      },
    },
    colors: ["#1E90FF", "#00C49F"],
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 1, // On hover, color turns black
        },
      },
    },
    markers: {
    //   size: 4,
      hover: {
        size: 6,
        colors: ["#000000"], // Turns black on hover
      },
    },
    tooltip: {
      theme: "dark",
    },
    legend: { position: "top", fontSize: "14px" },
  };

  const series = [
    {
      name: "General government revenue",
      data: revenueData,
    },
    {
      name: "General government total expenditure",
      data: expenditureData,
    },
  ];

  return (
    <div style={{ padding: "20px", borderRadius: "10px" }}>
      <h3 style={{ textAlign: "center", marginBottom: "10px" }}>Government Revenue & Expenditure</h3>
      <Chart options={chartOptions} series={series} type="area" height={350} />
    </div>
  );
};

export default AreaChart;
