import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import 'jsvectormap/dist/jsvectormap.css';
import 'jsvectormap/dist/maps/world.js'; // World map
import { supabase } from '../supabaseClient'; // Import your Supabase client
import Header from './Header';
import GDPChartData from './GDPChartData';
import { countryToIso, iso_codes } from '../assets/country_iso_codes';
import DualAxisChart from './EconomicIndicatorChart';
import AreaChart from './NationalCUrrencyAreaChart';
import DebtBarChart from './NationalCurrencyDataInDebt';
import CurrentAccountLineChart from './AccountBalanceLineChart';

// Define light and dark themes
const lightTheme = {
  background: "#ffffff",
  color: "#000000",
  cardBackground: "#f5f5f5",
  cardColor: "#000000",
  borderColor: "#000000",
};

const darkTheme = {
  background: "rgb(31 41 55)",
  color: "#ffffff",
  cardBackground: "rgb(55 65 81)",
  cardColor: "#ffffff",
  borderColor: "#ffffff",
};

// Global styles
const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    margin: 10%;
    height: 100%;
    transition: background-color 0.3s, color 0.3s;
  }
`;

// Main container
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  overflow-y: auto;
  margin-top: 100px;
`;

// Layout for search bar
const SearchBar = styled.input`
  width: 15%;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  margin-bottom: 20px;
  outline: none;
  font-size: 16px;
`;

// Dropdown for country suggestions
const SuggestionList = styled.ul`
  list-style: none;
  padding: 0;
  margin-top: 40px;
  border: 1px solid ${({ theme }) => theme.borderColor};
  background: ${({ theme }) => theme.cardBackground};
  width: 30%;
  position: absolute; // Absolute position to overlay suggestions
  z-index: 1;
  max-height: 200px; // Limit height
  overflow-y: auto; // Enable scrolling
`;

// Individual suggestion item
const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background: #7ebdc2; // Highlight on hover
  }
`;

// Card styling
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 15px;
  background: ${({ theme }) => theme.cardBackground};
  color: ${({ theme }) => theme.cardColor};
  // border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  height: 400px;
  overflow-y:auto;
`;
const CardContainer1 = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 15px;
  background: ${({ theme }) => theme.cardBackground};
  color: ${({ theme }) => theme.cardColor};
  // border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  height: 195px;
  overflow-y:auto;
`;
const CardTitle = styled.h2`
  margin-bottom: 10px;
  font-size:1.2em;
  color: ${({ theme }) => theme.cardColor};
`;

const CardContent = styled.p`
  margin: 10px;  
  
`;

const Column = styled.div`
  flex: 1; // Each column takes equal space
  margin: 1px; // Margin between columns
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px; // Adds consistent spacing between columns
`;

const EconomicPage = () => {
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });

  const [searchQuery, setSearchQuery] = useState(localStorage.getItem('country') || 'United States');
  const [countries, setCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem('country') || 'United States'); // Default to USA (ISO code)
  
  const [valueObj, setValueObj] = useState({});
  const [economicSummary, setEconomicSummary] = useState([]);
  const [gdpData, setGdpData] = useState([]);
  const [economicIndicatorsData, setEconomicIndicatorsData] = useState([]);
  const [creditRatings, setCreditRatings] = useState([]);
  const [agriculturalProducts, setAgriculturalProducts] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [environment, setEnvironment] = useState([]);
  const [landUse, setLandUse] = useState([]);
  const [electrification, setEletrification] = useState([]);
  const [energyConsumption, setEnergyConsumption] = useState([]);
  const [petroleum, setPetrolium] = useState([]);
  const [infrastructure, setInfrastructure] = useState([]);
  const [emissions, setEmissions] = useState([]);
  const [economicIndicatorsDataForInvestment, setEconomicIndicatorsDataForInvestment] = useState([]);
  const [nationalCurrenyData, setNationalCurrenyData] = useState([]);
  const [nationalCurrenyDataInDebt, setNationalCurrenyDataInDebt] = useState([]);
  const [accountBalance, setAccountBalance] = useState([]);
  
  

  // Fetch countries and values from Supabase when the component loads
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const { data, error } = await supabase.rpc('get_distinct_countries_with_values');
        if (error) {
          console.error("Error fetching countries:", error);
          return;
        }
        setCountries(data);
        setFilteredCountries(data);
        const countriesObject = data.reduce((acc, country) => {
          acc[country.country] = country.value; // Assuming country.value contains the value you want to store
          return acc;
        }, {});
        setValueObj(countriesObject);
      } catch (err) {
        console.error('Error:', err);
      }
    };

    fetchCountries();
  }, []);

  // Filter countries based on search query
  useEffect(() => {
    const result = countries.filter(country =>
      country.country.toLowerCase().includes(searchQuery.toLowerCase())
    );    
    setFilteredCountries(result);    
  }, [searchQuery, countries]);

  const fetchEconomicSummary = async (country) => {
    const { data, error } = await supabase
      .from('CIA_Factbook')
      .select('Value')
      .eq('Country', country)
      .eq('Variable', 'Economic Overview');

    if (error) {
      console.error('Error fetching economic summary:', error);
      return null;
    }
    return data;
  };

  const fetchAgriculturalProducts = async (country) => {
    const { data, error } = await supabase
      .from('CIA_Factbook')
      .select('Variable, Value')
      .eq('Country', country)
      .eq('Variable', 'Agricultural Products');

    if (error) {
      console.error('Error fetching agricultural products:', error);
      return null;
    }
    return data;
  };

const fetchGDPData = async (country) => {
  const { data, error } = await supabase
    .rpc('fetch_gdp_data', {
      country: country,
      weo_codes: ['NGDP', 'NGDP_R'],
    });

  if (error) {
    console.error('Error fetching GDP data:', error);
    return null;
  }
  return data;
};

async function getEconomicIndicators(country) {
  const { data, error } = await supabase
    .from('GlobalEconomicIndicators')
    .select('"Subject Descriptor", "Value", "Year"') // Ensure proper quoting
    .eq('Country', country) // Replace with actual country
    .in('Subject Descriptor', [
      'Gross national savings',
      'Total investment'
    ])
    .gte('Year', new Date().getFullYear() - 10); // Last 10 years

    console.log("-----data------",data);
    
  if (error) {
    console.error('Error fetching economic indicators:', error);
    return null;
  }
  return data;
}
  

  const fetchCreditRatings = async (country) => {
    const { data, error } = await supabase
      .from('CIA_Factbook')
      .select('Variable, Value')
      .eq('Country', country)
      .in('Variable', [
        'Credit Ratings: Fitch Rating', 
        'Credit Ratings: Moody\'s Rating', 
        'Credit Ratings: Standard & Poors Rating', 
        'Credit Ratings Note'
      ]);

    if (error) {
      console.error('Error fetching credit ratings:', error);
      return null;
    }
    return data;
  };

  const fetchIndustries = async (country) => {
    const { data, error } = await supabase
      .from('CIA_Factbook')
      .select('Variable, Value')
      .eq('Country', country)
      .eq('Variable', 'Industries');

    if (error) {
      console.error('Error fetching industries:', error);
      return null;
    }
    return data;
  };

  // Fetch environment data


// Fetch environment data
const fetchEnvironmentData = async (country) => {
  const { data, error } = await supabase
    .from('CIA_Factbook')
    .select('Variable, Value')
    .eq('Country', country)
    .in('Variable', ['Environment - Current Issues', 'Environment - International Agreements Party To', 'Environment - International Agreements Signed, But Not Ratified'])
    .order('created_at', { ascending: false })
    // .limit(1);

  if (error) {
    console.error('Error fetching Environment Data:', error);
    return [];
  }
  return data;
};

// Fetch land use data
const fetchLandUseData = async (country) => {
  const { data, error } = await supabase
    .from('CIA_Factbook')
    .select('Variable, Value')
    .eq('Country', country)
    .in('Variable', ['Land Use – Agricultural', 'Land Use – Forest', 'Land Use – Other', 'Revenue from Forest Resources', 'Revenue from Coal'])
    .order('created_at', { ascending: false })
    // .limit(1);

  if (error) {
    console.error('Error fetching Land Use Data:', error);
    return [];
  }
  return data;
};

// Fetch electrification data
const fetchElectrificationData = async (country) => {
  const { data, error } = await supabase
    .from('CIA_Factbook')
    .select('Variable, Value')
    .eq('Country', country)
    .in('Variable', ['Electricity Access - Total Population', 'Electricity Consumption’, ‘Electricity Installed Generating Capacity', 'Electricity Exports',
       'Electricity Imports', 'Electricity Generation Sources - Fossil Fuels', 'Electricity Generation Nuclear', 'Electricity Generation Sources - Solar',
      'Electricity Generation - Wind', 'Electricity Generation - Hydroelectricity', 'Electricity Generation - Geothermal', 'Electricity Generation - Biomass and Waste'])
    .order('created_at', { ascending: false })
    // .limit(1);

  if (error) {
    console.error('Error fetching Electrification Data:', error);
    return [];
  }
  return data;
};

// Fetch energy consumption data
const fetchEnergyConsumptionData = async (country) => {
  const { data, error } = await supabase
    .from('CIA_Factbook')
    .select('Variable, Value')
    .eq('Country', country)
    .in('Variable', ['Total Energy Consumption per Capita', 'Number of Operational Nuclear Reactors', 'Net Capacity of Operational Nuclear Reactors', 'Percent of Total Nuclear Electricity Production', 'Number of Nuclear Reactors Permanently Shut Down', 'Coal – Production', 'Coal – Consumption', 'Coal – Exports', 'Coal – Imports', 'Coal – Proven Reserves'])
    .order('created_at', { ascending: false })
    // .limit(1)

  if (error) {
    console.error('Error fetching Energy Consumption Data:', error);
    return [];
  }
  return data;
};

// Fetch petroleum data
const fetchPetroleumData = async (country) => {
  const { data, error } = await supabase
    .from('CIA_Factbook')
    .select('Variable, Value')
    .eq('Country', country)
    .in('Variable', ['Total Petroleum Production', 'Refined Petroleum Consumption', 'Crude Oil Estimated Reserves', 'Natural gas – Production', 'Natural gas – Consumption', 'Natural gas – Export', 'Natural gas – Imports', 'Carbon Dioxide Emissions - from Coal and Metallurgical Coke', 'Carbon Dioxide Emissions - from Petroleum and Other Liquids', 'Carbon Dioxide Emissions - from Consumed Natural Gas'])
    .order('created_at', { ascending: false })
    // .limit(1);

  if (error) {
    console.error('Error fetching Petroleum Data:', error);
    return [];
  }
  return data;
};

// Fetch infrastructure data
const fetchInfrastructureData = async (country) => {
  const { data, error } = await supabase
    .from('CIA_Factbook')
    .select('Variable, Value')
    .eq('Country', country)
    .in('Variable', ['Pipelines', 'Railways Total', 'Roadways', 'Waterways', 'Airports'])
    .order('created_at', { ascending: false })
    // .limit(1);

  if (error) {
    console.error('Error fetching Infrastructure Data:', error);
    return [];
  }
  return data;
};

// Fetch emissions data
const fetchEmissionsData = async (country) => {
  const { data, error } = await supabase
    .from('CIA_Factbook')
    .select('Variable, Value')
    .eq('Country', country)
    .in('Variable', ['Particulate Matter Emissions', 'Carbon Dioxide Emissions', 'Methane Emissions'])
    .order('created_at', { ascending: false })
    // .limit(1);

  if (error) {
    console.error('Error fetching Emissions Data:', error);
    return [];
  }
  return data;
};


const fetchElectrificationDataForInvestment = async (country) => {
  const { data, error } = await supabase
  .from('GlobalEconomicIndicators')
  .select('"Subject Descriptor", "Value", "Year"')
  .eq('Country', country)
  .in('Subject Descriptor', ['Gross national savings', 'Total investment'])
  .gte('Year', new Date().getFullYear() - 10)
  .order('Year', { ascending: false });

    if (error) {
      console.error("Error fetching data:", error);
    } else {
      console.log("Fetched Data:", data);
    }
    return data;
};

const fetchNationalCurrencyData = async (country) => {
  const { data, error } = await supabase
  .from('GlobalEconomicIndicators')
  .select('"Subject Descriptor", "Value", "Year"')
  .eq('Country', country)
  .in('Subject Descriptor', ['General government revenue', 'General government total expenditure'])
  .eq("Units", "National currency")
  .gte('Year', new Date().getFullYear() - 10)
  .order('Year', { ascending: false });

    if (error) {
      console.error("Error fetching data:", error);
    } else {
      console.log("Fetched Data:", data);
    }
    return data;
};

const fetchNationalCurrencyDataInDebt = async (country) => {
  const { data, error } = await supabase
  .from('GlobalEconomicIndicators')
  .select('"Subject Descriptor", "Value", "Year"')
  .eq('Country', country)
  .in('Subject Descriptor', ['General government net debt', 'General government gross debt'])
  .eq("Units", "National currency")
  .gte('Year', new Date().getFullYear() - 10)
  .order('Year', { ascending: false });

    if (error) {
      console.error("Error fetching data:", error);
    } else {
      console.log("Fetched Data:", data);
    }
    return data;
};

const fetchAccountBalance = async (country) => {
  const { data, error } = await supabase
  .from('GlobalEconomicIndicators')
  .select('"Subject Descriptor", "Value", "Year"')
  .eq('Country', country)
  .in('Subject Descriptor', ['Current account balance'])
  .eq("Units", "U.S. dollars")
  .gte('Year', new Date().getFullYear() - 10)
  .order('Year', { ascending: false });

    if (error) {
      console.error("Error fetching data:", error);
    } else {
      console.log("Fetched Data:", data);
    }
    return data;
};

  useEffect(() => {
    const country = selectedCountry; // Example country
    const getEconomicData = async () => {
      const economicSummary = await fetchEconomicSummary(country);
      const gdpData = await fetchGDPData(country);
      const economicIndicatorsData = await getEconomicIndicators(country);
      const creditRatings = await fetchCreditRatings(country);
      const agriculturalProducts = await fetchAgriculturalProducts(country);
      const industries = await fetchIndustries(country);
      const environment = await fetchEnvironmentData(country);
      const landUse = await fetchLandUseData(country);
      const electrification = await fetchElectrificationData(country);
      const energyConsumption = await fetchEnergyConsumptionData(country);
      const petroleumData = await fetchPetroleumData(country);
      // const creditRatings = await fetchCreditRatingsData(country);
      const infrastructure = await fetchInfrastructureData(country);
      const emissions = await fetchEmissionsData(country);
      const economicIndicatorsDataForInvestment = await fetchElectrificationDataForInvestment(country);
      const nationalCurrenyData = await fetchNationalCurrencyData(country);
      const nationalCurrenyDataInDebt = await fetchNationalCurrencyDataInDebt(country);
      const accountBalance = await fetchAccountBalance(country);

      setEconomicSummary(economicSummary);
      setGdpData(gdpData || []);
      setEconomicIndicatorsData(economicIndicatorsData || []);
      setCreditRatings(creditRatings);
      setAgriculturalProducts(agriculturalProducts);
      setIndustries(industries);
      setLandUse(landUse);
      setEnvironment(environment);
      setEletrification(electrification);
      setEnergyConsumption(energyConsumption);
      setPetrolium(petroleumData);
      setInfrastructure(infrastructure);
      setEmissions(emissions);
      setFilteredCountries([]);
      setEconomicIndicatorsDataForInvestment(economicIndicatorsDataForInvestment || []);
      setNationalCurrenyData(nationalCurrenyData || []);
      setNationalCurrenyDataInDebt(nationalCurrenyDataInDebt || []);
      setAccountBalance(accountBalance || []);
      // Handle the data as needed
      console.log({ economicSummary, gdpData, creditRatings, agriculturalProducts, industries });
    };

    getEconomicData();
  }, [selectedCountry]);

  // Handle country selection from search results
  const handleCountryClick = (country) => {
    setSelectedCountry(country);
    setFilteredCountries([]);
    setSearchQuery(country); 
    localStorage.setItem('country',country);
    const code = countryToIso[country];
    localStorage.setItem('code',code);
    
  };

  const handleLayoutModeChange = (newMode) => {
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header
          layoutModeType={theme === lightTheme ? 'light' : 'dark'}
          onChangeLayoutMode={handleLayoutModeChange}
        />

        {/* Search bar */}
        <SearchBar
          type="text"
          placeholder="Search for a country"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        {/* Suggestion List for matching countries */}
        {searchQuery && filteredCountries.length > 0 && (
          <SuggestionList>
            {filteredCountries.map((country) => (
              <SuggestionItem key={country.country} onClick={() => handleCountryClick(country.country)}>
                {country.country}
              </SuggestionItem>
            ))}
          </SuggestionList>
        )}

        {/* Row to contain two columns */}
        <Row>
          <Column>
            <CardContainer>
              {/* <CardTitle>Economic Overview</CardTitle> */}
              <CardContent><strong style={{fontWeight: 'bold'}}>Economic Overview: </strong><p>{economicSummary?.length > 0 ? economicSummary[0].Value : 'No data available'}</p></CardContent>

              {/* <CardTitle>Industries</CardTitle> */}
              <CardContent>
                {industries.length > 0 ? (
                    industries.map((item, index) => (
                    <div key={index}>
                        <strong style={{fontWeight: 'bold'}}>{item.Variable}: </strong><p> {item.Value}</p>
                    </div>
                    ))
                ) : (
                    'No data available'
                )}
                </CardContent>

                {/* <CardTitle>Agricultural Products</CardTitle> */}
              <CardContent>
                {agriculturalProducts.length > 0 ? (
                    agriculturalProducts.map((item, index) => (
                    <div key={index}>
                        <strong style={{fontWeight: 'bold'}}>{item.Variable}: </strong> <p>{item.Value}</p>
                    </div>
                    ))
                ) : (
                    'No data available'
                )}
                </CardContent>
            </CardContainer>

            {/* <CardContainer>
              <CardTitle>Agricultural Products</CardTitle>
              <CardContent>
                {agriculturalProducts.length > 0 ? (
                    agriculturalProducts.map((item, index) => (
                    <div key={index}>
                        <strong style={{fontWeight: 'bold'}}>{item.Variable}:</strong> {item.Value}
                    </div>
                    ))
                ) : (
                    'No data available'
                )}
                </CardContent>
            </CardContainer>

            <CardContainer>
              <CardTitle>Industries</CardTitle>
              <CardContent>
                {industries.length > 0 ? (
                    industries.map((item, index) => (
                    <div key={index}>
                        <strong style={{fontWeight: 'bold'}}>{item.Variable}:</strong> {item.Value}
                    </div>
                    ))
                ) : (
                    'No data available'
                )}
                </CardContent>
            </CardContainer> */}
          </Column>

          <Column>
            <CardContainer>
              <CardTitle>GDP Data</CardTitle>
              {economicIndicatorsData.length > 0 &&
              <CardContent>
                  <div>
                <strong>CPI (average annual):</strong> {economicIndicatorsData[0]?.Value} &nbsp;&nbsp;
                <strong>GDP deflator (Index):</strong> {economicIndicatorsData[1]?.Value} &nbsp;&nbsp;
                <strong>GDP per capita:</strong> {economicIndicatorsData[2]?.Value}
              </div>
              </CardContent>
              }
              <GDPChartData data={gdpData}></GDPChartData>
              {/* <CardContent>{gdpData.length > 0 ? gdpData.map(item => `${item.Year}: ${item.Value}`).join(', ') : 'No data available'}</CardContent> */}
            </CardContainer>

            {/* <CardContainer>
              <CardTitle>Credit Ratings</CardTitle>
              <CardContent>
                {creditRatings.length > 0 ? (
                  ['Credit Ratings: Fitch Rating', 
                  'Credit Ratings: Moody\'s Rating', 
                  'Credit Ratings: Standard & Poors Rating', 
                  'Credit Ratings Note'].map((label, index) => {
                    const item = creditRatings.find((item) => item.Variable === label);
                    return (
                      <div key={index}>
                        <strong style={{ fontWeight: 'bold' }}>{label}</strong> <p>{item ? item.Value : 'Not Available'}</p>
                      </div>
                    );
                  })
                ) : (
                  'No data available'
                )}
              </CardContent>

            </CardContainer> */}
          </Column>
        </Row>
        <Row>
        <Column>
          <CardContainer>
          <CardContent>
          {economicIndicatorsDataForInvestment.length > 0 &&
                <DualAxisChart data={economicIndicatorsDataForInvestment}/>
          }
                </CardContent>
                
          </CardContainer>
          </Column>
          <Column>
          <CardContainer>
          <CardContent>
                {environment.length > 0 ? (
                  ['Environment - Current Issues', 'Environment - International Agreements Party To', 'Environment - International Agreements Signed, But Not Ratified']
                    .map((variable) => environment.find(item => item.Variable === variable))
                    .filter(Boolean) // Remove any items that are undefined if they aren't in the data
                    .map((item, index) => (
                      <div key={index}>
                        <strong style={{ fontWeight: 'bold' }}>{item.Variable}:</strong> <p>{item.Value}</p>
                      </div>
                    ))
                ) : (
                  'No data available'
                )}
                </CardContent>
                
          </CardContainer>
          </Column>
          
          {/* <Column>
          <CardContainer>
          <CardContent>
                {landUse.length > 0 ? (
                    landUse.map((item, index) => (
                    <div key={index}>
                        <strong style={{fontWeight: 'bold'}}>{item.Variable}: </strong> <p>{item.Value}</p>
                    </div>
                    ))
                ) : (
                    'No data available'
                )}
                </CardContent>
                
          </CardContainer>
          </Column> */}
        </Row>
        <Row>
          <Column>
             <CardContainer>
              <CardTitle>Credit Ratings</CardTitle>
              <CardContent>
                {creditRatings.length > 0 ? (
                  ['Credit Ratings: Fitch Rating', 
                  'Credit Ratings: Moody\'s Rating', 
                  'Credit Ratings: Standard & Poors Rating', 
                  'Credit Ratings Note'].map((label, index) => {
                    const item = creditRatings.find((item) => item.Variable === label);
                    return (
                      <div key={index}>
                        <strong style={{ fontWeight: 'bold' }}>{label}</strong> <p>{item ? item.Value : 'Not Available'}</p>
                      </div>
                    );
                  })
                ) : (
                  'No data available'
                )}
              </CardContent>

            </CardContainer>
          </Column>
          <Column>
                <CardContainer>
                  <CardContent>
                    <AreaChart data={nationalCurrenyData}/>
                  </CardContent>
                </CardContainer>
          </Column>
        </Row>
        <Row>
        <Column>
                <CardContainer>
                  <CardContent>
                    <DebtBarChart data={nationalCurrenyDataInDebt}/>
                  </CardContent>
                </CardContainer>
          </Column>
          <Column>
                <CardContainer>
                  <CardContent>
                    <CurrentAccountLineChart data={accountBalance}/>
                  </CardContent>
                </CardContainer>
          </Column>
        </Row>
        <Row>
          <Column>
          <CardContainer>
          <CardContent>
          
          {
  electrification.length > 0 ? (
    <>
      {['Electricity Access - Total Population', 'Electricity Consumption', 'Electricity Installed Generating Capacity', 'Electricity Exports', 'Electricity Imports']
        .map((variable) => electrification.find(item => item.Variable === variable))
        .filter(Boolean) // Filter out any variables not present in data
        .map((item, index) => (
          <div key={index}>
            <strong style={{ fontWeight: 'bold' }}>{item.Variable}:</strong> <p>{item.Value}</p>
          </div>
        ))
      }

      {
        electrification.some(item => item.Variable.includes('Electricity Generation')) && (
          <div style={{ fontWeight: 'bold', marginTop: '8px' }}>
            <p style={{ fontWeight: 'bold' }}>Electricity Generation Sources:</p>
            <div style={{ paddingLeft: '20px', marginTop: '4px' }}>
              {['Electricity Generation Sources - Fossil Fuels', 'Electricity Generation Nuclear', 'Electricity Generation Sources - Solar', 'Electricity Generation - Wind', 'Electricity Generation - Hydroelectricity', 'Electricity Generation - Geothermal', 'Electricity Generation - Biomass and Waste']
                .map(source => electrification.find(item => item.Variable === source))
                .filter(Boolean) // Filter out any sources not present in data
                .map((item, index) => (
                  <p key={index} style={{ margin: '4px 0' }}>
                    <strong style={{ fontWeight: 'bold' }}>{item.Variable.replace(/Electricity Generation( Sources)?\s*-\s*/i, '')}:</strong> {item.Value}<br/><br/>
                  </p>
                ))
              }
            </div>
          </div>
        )
      }

          </>
        ) : (
          'No data available'
        )
      }

      </CardContent>
                
          </CardContainer>
          </Column>
          <Column>
          <CardContainer>
          <CardContent>
          {energyConsumption.length > 0 ? (
  <div>
    {/* Display Total Energy Consumption per Capita */}
    {energyConsumption.find(item => item.Variable === 'Total Energy Consumption per Capita') && (
      
      <div>
        <strong style={{ fontWeight: 'bold' }}>Total Energy Consumption per Capita: </strong>
        <p>{energyConsumption.find(item => item.Variable === 'Total Energy Consumption per Capita').Value}</p>
      </div>
    )}

    {/* Nuclear Energy Section */}
    {['Number of Operational Nuclear Reactors', 'Net Capacity of Operational Nuclear Reactors', 'Percent of Total Nuclear Electricity Production', 'Number of Nuclear Reactors Permanently Shut Down']
      .map(variable => energyConsumption.find(item => item.Variable === variable))
      .filter(Boolean)
      .length > 0 && (
      <div style={{ marginTop: '16px' }}>
        <p style={{ fontWeight: 'bold' }}>Nuclear Energy:</p>
        <div style={{ paddingLeft: '20px' }}>
          {['Number of Operational Nuclear Reactors', 'Net Capacity of Operational Nuclear Reactors', 'Percent of Total Nuclear Electricity Production', 'Number of Nuclear Reactors Permanently Shut Down']
            .map(variable => energyConsumption.find(item => item.Variable === variable))
            .filter(Boolean)
            .map((item, index) => (
              <p key={index} style={{ margin: '4px 0' }}>
                <strong style={{ fontWeight: 'bold' }}>{item.Variable}:</strong> {item.Value}<br/><br/>
              </p>
            ))}
        </div>
      </div>
    )}

    {/* Coal Section */}
    {['Coal – Production', 'Coal – Consumption', 'Coal – Exports', 'Coal – Imports', 'Coal – Proven Reserves']
      .map(variable => energyConsumption.find(item => item.Variable === variable))
      .filter(Boolean)
      .length > 0 && (
      <div style={{ marginTop: '16px' }}>
        <p style={{ fontWeight: 'bold' }}>Coal:</p>
        <div style={{ paddingLeft: '20px' }}>
          {['Coal – Production', 'Coal – Consumption', 'Coal – Exports', 'Coal – Imports', 'Coal – Proven Reserves']
            .map(variable => energyConsumption.find(item => item.Variable === variable))
            .filter(Boolean)
            .map((item, index) => (
              <p key={index} style={{ margin: '4px 0' }}>
                <strong>{item.Variable}:</strong> {item.Value}
              </p>
            ))}
        </div>
      </div>
    )}
  </div>
) : (
  'No data available'
)}

                </CardContent>
                
          </CardContainer>
          </Column>
          <Column>
          <CardContainer>
          <CardContent>
          {petroleum.length > 0 ? (
  <div>
    {/* Petroleum Section */}
    {['Total Petroleum Production', 'Refined Petroleum Consumption', 'Crude Oil Estimated Reserves']
      .map(variable => petroleum.find(item => item.Variable === variable))
      .filter(Boolean)
      .length > 0 && (
      <div style={{ marginTop: '16px' }}>
        <p style={{ fontWeight: 'bold' }}>Petroleum:</p>
        <div style={{ paddingLeft: '20px' }}>
          {['Total Petroleum Production', 'Refined Petroleum Consumption', 'Crude Oil Estimated Reserves']
            .map(variable => petroleum.find(item => item.Variable === variable))
            .filter(Boolean)
            .map((item, index) => (
              <p key={index} style={{ margin: '4px 0' }}>
                <strong style={{ fontWeight: 'bold' }}>{item.Variable}:</strong> {item.Value} <br/><br/>
              </p>
            ))}
        </div>
      </div>
    )}

    {/* Natural Gas Section */}
    {['Natural gas – Production', 'Natural gas – Consumption', 'Natural gas – Export', 'Natural gas – Imports']
      .map(variable => petroleum.find(item => item.Variable === variable && item.Value && !isNaN(item.Value)))
      .filter(Boolean)
      .length > 0 && (
      <div style={{ marginTop: '16px' }}>
        <p style={{ fontWeight: 'bold' }}>Natural Gas:</p>
        <div style={{ paddingLeft: '20px' }}>
          {['Natural gas – Production', 'Natural gas – Consumption', 'Natural gas – Export', 'Natural gas – Imports']
            .map(variable => petroleum.find(item => item.Variable === variable))
            .filter(Boolean)
            .map((item, index) => (
              <p key={index} style={{ margin: '4px 0' }}>
                <strong style={{ fontWeight: 'bold' }}>{item.Variable}:</strong> {item.Value}
              </p>
            ))}
        </div>
      </div>
    )}

    {/* Emissions Section */}
    {['Carbon Dioxide Emissions - from Coal and Metallurgical Coke', 'Carbon Dioxide Emissions - from Petroleum and Other Liquids', 'Carbon Dioxide Emissions - from Consumed Natural Gas']
      .map(variable => petroleum.find(item => item.Variable === variable))
      .filter(Boolean)
      .length > 0 && (
      <div style={{ marginTop: '16px' }}>
        <p style={{ fontWeight: 'bold' }}>Carbon Dioxide Emissions:</p>
        <div style={{ paddingLeft: '20px' }}>
          {['Carbon Dioxide Emissions - from Coal and Metallurgical Coke', 'Carbon Dioxide Emissions - from Petroleum and Other Liquids', 'Carbon Dioxide Emissions - from Consumed Natural Gas']
            .map(variable => petroleum.find(item => item.Variable === variable))
            .filter(Boolean)
            .map((item, index) => (
              <p key={index} style={{ margin: '4px 0' }}>
                <strong style={{ fontWeight: 'bold' }}>{item.Variable}:</strong> {item.Value}<br/><br/>
              </p>
            ))}
        </div>
      </div>
    )}
  </div>
) : (
  'No data available'
)}

                </CardContent>
                
          </CardContainer>
          </Column>
        </Row>
        

    <Row>
      <Column>
        <CardContainer1>
          <CardContent>
          {creditRatings.length > 0 ? (
  <div>
    {/* Display Credit Ratings section only once */}
    {['Credit Ratings: Fitch Rating', 'Credit Ratings: Moody\'s Rating', 'Credit Ratings: Standard & Poors Rating']
      .some(variable => creditRatings.some(item => item.Variable === variable && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== '')) && (
      <div style={{ marginTop: '16px' }}>
        <p style={{ fontWeight: 'bold' }}>Credit Ratings:</p>
        <div style={{ paddingLeft: '20px' }}>
          {['Credit Ratings: Fitch Rating', 'Credit Ratings: Moody\'s Rating', 'Credit Ratings: Standard & Poors Rating']
            .map(variable => creditRatings.find(item => item.Variable === variable))
            .filter(Boolean) // Filter out undefined items
            .map((item, index) => (
              <p key={index} style={{ margin: '4px 0' }}>
                <strong style={{ fontWeight: 'bold' }}>{item.Variable.replace('Credit Ratings:','')}:</strong> {item.Value}
              </p>
            ))}
        </div>
      </div>
    )}

    {/* Display Credit Ratings Note section separately */}
    {creditRatings.some(item => item.Variable === 'Credit Ratings Note' && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== '') && (
      <div style={{ marginTop: '16px' }}>
        <p style={{ fontWeight: 'bold' }}>Credit Ratings Note:</p>
        <div style={{ paddingLeft: '20px' }}>
          {creditRatings
            .filter(item => item.Variable === 'Credit Ratings Note' && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== '')
            .map((item, index) => (
              <p key={index} style={{ margin: '4px 0' }}>
                <strong style={{ fontWeight: 'bold' }}>{item.Variable.replace('Credit Ratings Note','')}</strong> {item.Value}
              </p>
            ))}
        </div>
      </div>
    )}
  </div>
) : (
  'No data available'
)}


          </CardContent>
        </CardContainer1>
        <CardContainer1>
          <CardContent>
          {emissions.length > 0 ? (
  <div>
    {/* Display Emissions section only once */}
    {['Particulate Matter Emissions', 'Carbon Dioxide Emissions', 'Methane Emissions']
      .some(variable => emissions.some(item => item.Variable === variable && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== '')) && (
      <div style={{ marginTop: '16px' }}>
        <p style={{ fontWeight: 'bold' }}>Air Poluutants:</p>
        <div style={{ paddingLeft: '20px' }}>
          {['Particulate Matter Emissions', 'Carbon Dioxide Emissions', 'Methane Emissions']
            .map(variable => emissions.find(item => item.Variable === variable && item.Value && item.Value !== 'NaN' && item.Value !== null && item.Value !== ''))
            .filter(Boolean) // Filter out undefined items
            .map((item, index) => (
              <p key={index} style={{ margin: '4px 0' }}>
                <strong style={{ fontWeight: 'bold' }}>{item.Variable.replace('Emissions','')}:</strong> {item.Value}
              </p>
            ))}
        </div>
      </div>
    )}
  </div>
) : (
  'No data available'
)}

          </CardContent>
        </CardContainer1>
      </Column>

      <Column>
        <CardContainer>
          <CardContent>
            {infrastructure.length > 0 ? (
              infrastructure.map((item, index) => (
                <div key={index}>
                  <strong style={{ fontWeight: 'bold' }}>{item.Variable}: </strong>
                  <p>{item.Value}</p>
                </div>
              ))
            ) : (
              'No data available'
            )}
          </CardContent>
        </CardContainer>
      </Column>
    </Row>
      </Container>
    </ThemeProvider>
  );
};

export default EconomicPage;
