import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider, createGlobalStyle, useTheme } from 'styled-components';
import { supabase } from "../supabaseClient";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import Header from './Header';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PortfolioModal from './PortfolioModal';
import GearIconWithMenu from './GearIconWithMenu';
import axios from 'axios';


// Global Styles
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

const lightTheme = {
  background: '#f0f0f0',
  color: '#222',
  primary: '#AC544C',
  secondary: '#b06252',
};

const darkTheme = {
  background: '#222122',
  color: '#f0f0f0',
  primary: '#AC544C',
  secondary: '#b06252',
};

const TableHeader = styled.th`
  color: #fff;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.primary};
  text-align: center;
`;


const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  margin-top: 150px;
  margin-left:10px;
  margin-right:10px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
`;


const Button = styled.button`
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
margin-right:10px;
  &:hover {
    background-color: ${({ theme }) => theme.secondary};
  }
`;


const templateData = [
  { 'Portfolio Name': '', 'Model': '', 'Benchmark': '' }
];


const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.background};
  padding: 30px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  text-align: left;
  transition: all 0.3s ease;
`;

const ModalTitle = styled.h3`
  margin-bottom: 20px;
  color: ${({ theme }) => theme.primary};
  font-size: 24px;
`;

const ModalButton = styled.button`
  padding: 12px 20px;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.secondary};
  }

  &:not(:first-child) {
    margin-left: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const generateTemplate = (type) => {
  const ws = XLSX.utils.json_to_sheet(templateData, { header: ['Portfolio Name', 'Model', 'Benchmark'] });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Template');

  if (type === 'xlsx') {
    XLSX.writeFile(wb, 'portfolio_template.xlsx');
  } else if (type === 'csv') {
    const csvData = XLSX.utils.sheet_to_csv(ws);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'portfolio_template.csv');
    link.click();
  }
};

const ViewPortfolio = ({onPortfolioChange}) => {
  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(false);
//   const theme = useTheme();
  const [clientUserId, setClientUserId] = useState(localStorage.getItem('client_id'));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [portfolioSums, setPortfolioSums] = useState({});
  const [openMenu, setOpenMenu] = useState(null);
  const handleMenuToggle = (id) => {
    setOpenMenu((prev) => (prev === id ? null : id));
  };
  const [portfolioData, setPortfolioData] = useState({
    symbol: '',
    shares: '',
    weight: '',
    Purchase_Value: '',
    currency: 'USD',
  });
  const closeModal = () => setIsModalOpen(false);
  
const [pfId, setPfId] = useState();
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme ? savedTheme.replace(/^"|"$/g, '') : 'light';
      
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme;
    }
  });

  const fetchPortfolios = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase.from('PortfolioIdentity')      
      .select('*')
      .eq('Client_ID',clientUserId);

      if (error) {
        throw error;
      }
      setPortfolios(data);
      console.log(data,'data');
      
      onPortfolioChange(data);
      // toast.success('Portfolios fetched successfully!');
    } catch (error) {
      toast.error(`Error fetching portfolios: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(async() => {
    fetchPortfolios();
    await calculateRealTimePortfolioValue();
  }, []);

  async function calculateRealTimePortfolioValue() {    
    let portfolioData = [];
    const currentPrices = {};
    const portfolioSums = {}; // Store the sum of share prices by Portfolio_ID
  
    try {
      // Step 1: Fetch portfolio benchmark data from Supabase
      const { data, error } = await supabase
        .from('PortfolioComposition')
        .select('Symbol, Share, Portfolio_ID')
        .eq('Client_ID', clientUserId);
  
      if (error) {
        console.error(`Error fetching portfolio data: ${error.message}`);
        // return { portfolioSums: {}, portfolioData: [] };
      }
  
      if (data) {
        portfolioData = data;
      }
  
      // Step 2: Fetch real-time prices for each symbol
      for (const { Symbol } of portfolioData) {
        const url = `https://financialmodelingprep.com/api/v3/quote-short/${Symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e`;
        try {
          const response = await axios.get(url);
          currentPrices[Symbol] = response[0]?.price || 0;
        } catch (err) {
          console.error(`Error fetching price for ${Symbol}:`, err);
          currentPrices[Symbol] = 0;
        }
      }
  
      // Step 3: Calculate the sum of share prices by Portfolio_ID
      portfolioData.forEach(({ Portfolio_ID, Symbol, Share }) => {
        const price = currentPrices[Symbol] || 0;
        if (!portfolioSums[Portfolio_ID]) {
          portfolioSums[Portfolio_ID] = 0;
        }
        portfolioSums[Portfolio_ID] += Share * price;
      });
  
      console.log(portfolioSums,'sum');
      
  setPortfolioSums(portfolioSums);
    } catch (err) {
      console.error('Error calculating portfolio real-time value:', err);
      // return { portfolioSums: {}, portfolioData: [] };
    }
  }
  
  

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true);
    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel') {
      // Handle XLSX file
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        const formattedData = jsonData.map(row => ({
          portfolio_name: row['Portfolio Name'],
          model: row['Model'],
          benchmark: row['Benchmark'],
        }));

        supabase
          .from('PortfolioComposition')
          .insert(formattedData)
          .then(({ data, error }) => {
            if (error) {
              throw error;
            }
            toast.success('XLSX data inserted successfully!');
            fetchPortfolios(); // Refresh the list
          })
          .catch((error) => {
            toast.error(`Error inserting XLSX data: ${error.message}`);
          })
          .finally(() => {
            setLoading(false);
          });
      };
      reader.readAsArrayBuffer(file);
    } else if (file.type === 'text/csv') {
      // Handle CSV file
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const data = results.data;

          const formattedData = data.map(row => ({
            portfolio_name: row['Portfolio Name'],
            model: row['Model'],
            benchmark: row['Benchmark'],
          }));

          supabase
            .from('PortfolioComposition')
            .insert(formattedData)
            .then(({ data, error }) => {
              if (error) {
                throw error;
              }
              toast.success('CSV data inserted successfully!');
              fetchPortfolios(); // Refresh the list
            })
            .catch((error) => {
              toast.error(`Error inserting CSV data: ${error.message}`);
            })
            .finally(() => {
              setLoading(false);
            });
        },
      });
    } else {
      toast.error('Invalid file format. Please upload a CSV or XLSX file.');
      setLoading(false);
    }
  };

  const downloadData = () => {
    const ws = XLSX.utils.json_to_sheet(portfolios, { header: ['Portfolio Name', 'Model', 'Benchmark'] });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, 'portfolio_data.xlsx');
  };

  const handleModalToggle = () => {            
    console.log("Current state of isModalOpen:", isModalOpen);  // Log current state
    setIsModalOpen(true)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setIsModalOpen(false);

    console.log(pfId,'pfid');
    
    try {
      const { error } = await supabase
        .from('PortfolioComposition')
        .insert([
          {
            Client_ID: parseInt(localStorage.getItem('client_id')),
            Symbol: portfolioData.symbol,
            Share: parseFloat(portfolioData.shares),
            Weight: portfolioData.weight || undefined,
            Purchase_Value: parseFloat(portfolioData.Purchase_Value),
            Portfolio_ID: parseInt(pfId),
          },
        ]);

      if (error) {
        console.error("Error adding portfolio to database: " + error.message);
      } else {
        console.error(null);
        console.log("Portfolio added to database.");
      }
    } catch (err) {
      console.error("An unexpected error occurred: " + err.message);
    }

    setPortfolioData({
      symbol: '',
      shares: '',
      weight: '',
      Purchase_Value: '',
    });
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPortfolioData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleLayoutModeChange = (newMode) => {          
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };

  return ( 
        <ThemeProvider theme={theme}>
                   <GlobalStyle />
                   <Header 
                   layoutModeType={theme === lightTheme ? 'light' : 'dark'}
                   onChangeLayoutMode={handleLayoutModeChange}
                   />
    <Container>       
      {loading && <p>Loading...</p>}
      {/* <div style={{marginLeft:"auto"}}><GearIconWithMenu/></div> */}
      
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <thead>
          <tr style={{ backgroundColor: theme.primary, color: '#fff', fontWeight: 'bold' }}>
            {/* <TableHeader>Index</TableHeader> */}
            <TableHeader>Portfolio Name</TableHeader>
            <TableHeader>Portfolio Value</TableHeader>
            <TableHeader>Model</TableHeader>
            
            <TableHeader>Benchmark</TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader>Settings</TableHeader>
          </tr>
        </thead>
        <tbody>
          {portfolios.map((portfolio, index) => (
            <tr
              key={portfolio.id}
              style={{
                backgroundColor: theme.background,
                color: theme.color,
              }}
            >
              {/* <td style={{ padding: '10px', border: `1px solid ${theme.primary}`, textAlign: 'center' }}>
                {index + 1}
              </td> */}
              <td style={{ padding: '10px', border: `1px solid ${theme.primary}`, textAlign: 'center' }}>
                {portfolio.Portfolio_Name}
              </td>
              <td style={{ padding: '10px', border: `1px solid ${theme.primary}`, textAlign: 'center' }}>
                {portfolioSums[portfolio.Portfolio_ID]?.toFixed(2)}
              </td>
              <td style={{ padding: '10px', border: `1px solid ${theme.primary}`, textAlign: 'center' }}>
                {portfolio.Portfolio_Model}
              </td>
              <td style={{ padding: '10px', border: `1px solid ${theme.primary}`, textAlign: 'center' }}>
                {portfolio.Benchmark}
              </td>
              <td  style={{ padding: '10px', border: `1px solid ${theme.primary}`, textAlign: 'center' }}>
                {portfolio.Portfolio_Status === 'Active' || portfolio.Portfolio_Status ? 'Active' : 'InActive'}
              </td>

              <td style={{ padding: '10px', border: `1px solid ${theme.primary}`, textAlign: 'center' }}>
              <GearIconWithMenu 
                isMenuOpen={openMenu === index} 
                onToggle={() => handleMenuToggle(index)} 
                pfId={portfolio.Portfolio_ID}
                pfName={portfolio.Portfolio_Name}
                color={theme.color}
                background={theme.background}
              />
</td>

            </tr>
          ))}
        </tbody>
      </table>
      <br/>
      {/* <input
        type="file"
        accept=".csv, .xlsx, .xls"
        onChange={handleFileUpload}
        style={{ marginBottom: '20px' }}
      /><br/> */}
      
      <div>
      <br/>
      {/* <Button onClick={() => generateTemplate('csv')} style={{ marginBottom: '20px' }}>
        Download CSV Template
      </Button>
      <Button onClick={() => generateTemplate('xlsx')} style={{ marginBottom: '20px' }}>
        Download Excel Template
      </Button>
      
      <Button onClick={downloadData} style={{ marginBottom: '20px' }}>
        Download Data
      </Button> */}
      <Button  onClick={handleModalToggle} style={{ marginBottom: '20px' }}>
        Add new portfolio
      </Button>
      </div>
      {
        isModalOpen && <PortfolioModal isModalOpen={isModalOpen} closeModal={closeModal} pfCount={portfolios.length} />         
      }
      {/* <Modal isOpen={isModalOpen}>
          <ModalContent>
            <ModalTitle>Add New Shares</ModalTitle>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Label>Symbol</Label>
                <Input
                  type="text"
                  name="symbol"
                  value={portfolioData.symbol}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label>Shares</Label>
                <Input
                  type="number"
                  name="shares"
                  value={portfolioData.shares}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label>Weight</Label>
                <Input
                  type="text"
                  name="weight"
                  value={portfolioData.weight}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label>Purchase Value</Label>
                <Input
                  type="number"
                  name="Purchase_Value"
                  value={portfolioData.Purchase_Value}
                  onChange={handleChange}
                />
              </FormGroup>
              <ButtonContainer>
                <ModalButton type="submit">Submit</ModalButton>
                <ModalButton type="button" onClick={handleModalToggle}>Cancel</ModalButton>
              </ButtonContainer>
            </form>
          </ModalContent>
        </Modal> */}
    </Container>
    </ThemeProvider>
  );
};

export default ViewPortfolio;
