import React, { useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle, keyframes } from 'styled-components';
import Header from '../../pages/Header/index';
import Footer from '../../Components/Common/Footer';
import serviceImage1 from '../../assets/images/vegaveta/services/comprensives1.jpg';
import serviceImage2 from '../../assets/images/vegaveta/economic1.jpg';
import serviceImage3 from '../../assets/images/vegaveta/economic2.jpg';
import serviceImage4 from '../../assets/images/vegaveta/services/interactive_1.jpg';
import serviceImage5 from '../../assets/images/vegaveta/services/interactive_2.jpg';
import serviceImage6 from '../../assets/images/vegaveta/services/Regional_1.jpg';
import serviceImage7 from '../../assets/images/vegaveta/services/Regional_2.jpg';
import serviceImage8 from '../../assets/images/vegaveta/services/Chartbook.jpg';
import serviceImage9 from '../../assets/images/vegaveta/services/Market.jpg';
import serviceImage10 from '../../assets/images/vegaveta/services/Port.jpg';
import serviceImage11 from '../../assets/images/vegaveta/services/sec.jpg';
import serviceImage12 from '../../assets/images/vegaveta/forecasting1.jpg';
import serviceImage13 from '../../assets/images/vegaveta/forecasting2.jpg';
import flickerStyleImage from '../../assets/images/vegaveta/modified_flickerstyle.jpeg';
// Theme definitions
const lightTheme = {
  color: "#000000",
  cardColor: "#000000",
  titleColor: "#AC544C",
  textColor: "#333333",
  background: "#fff"
};

const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    transition: background-color 0.3s, color 0.3s;
    font-family: 'Lora', serif;
    margin: 0;
    padding: 0;
  }
`;

const Container = styled.div`
  padding: 50px;
  margin-top: 100px;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;


const flicker = keyframes`
  0% {
    filter: drop-shadow(0 0 0 rgba(0, 255, 0, 0.3)); /* Green glow */
  }
  2%, 8% {
    filter: drop-shadow(0 0 15px rgba(0, 255, 0, 0.3)); /* Stronger green glow */
  }
  18%, 20%, 22%, 25%, 30%, 35%, 40%, 45%, 50%, 55%, 60%, 65%, 70%, 75%, 80%, 85%, 90%, 95%, 98% {
    filter: drop-shadow(0 0 0 rgba(0, 255, 0, 0.3));
  }
  3%, 9%, 19%, 21%, 23%, 26%, 31%, 36%, 41%, 46%, 51%, 56%, 61%, 66%, 71%, 76%, 81%, 86%, 91%, 96%, 99% {
    filter: drop-shadow(0 0 4px rgba(0, 255, 0, 0.3));
  }
`;
const Card = styled.div`
  background-color: rgb(55 65 81); /* Dark black background */
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-image 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  text-align: center;
  position: relative;
  overflow: hidden;
  color: white;

  &:hover {
    background-color: rgb(55 65 81); /* Set a dark background */
    /* Use the flickerstyleimage as the background image */
    background-image: url('${flickerStyleImage}');
    background-repeat: repeat; /* Or 'no-repeat', 'space', 'round' as needed */
    background-size: auto; /* Or 'cover', 'contain', specific dimensions */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
`;



const Title = styled.h2`
  font-size: 1.5em;
  color: #fff;
  margin: 0;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  max-width: 1200px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #333;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
  align-items: center;
  margin-bottom: 40px;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const TextContent = styled.div`
  flex: 1;
  padding: 10px;
`;

const ImageContent = styled.div`
  flex: 1;
  padding: 10px;
  
  img {
    width: 100%;
    height: auto;
    border-radius: 6px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }
`;

const ModalTitle = styled.h1`
  font-size: 2em;
  color: #AC544C;
  margin-bottom: 30px;
  text-align: center;
`;

const ModalText = styled.p`
  font-size: 1.2em;
  line-height: 1.6;
  color: #333;
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const RightColumn = styled.div`
  flex: 1;
  padding: 10px;
  
  img {
    width: 80%;
    height: auto;
    border-radius: 6px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }
`;

const SectionTitle = styled.h3`
  font-size: 2.0em;
  color: rgba(55, 65, 81, 0.8);
  
  margin-bottom: 15px;
  margin-top: 0;
  font-weight:bold;
`;

const FutureServicesTitle = styled.h2`
  font-size: 2em;
  color: rgba(55, 65, 81);;
  text-align: center;
  font-weight: bold;
  margin: 40px 0 20px;
  position: relative;
  
  &::after {
    content: '';
    display: block;
    width: 100px;
    height: 3px;
    background: rgb(55 65 81);
    margin: 15px auto;
  }
`;

// Sample image URLs - replace with your actual images
const sampleImages = [
  serviceImage1,
  serviceImage2,
  serviceImage3,serviceImage4, serviceImage5, serviceImage6, serviceImage7, serviceImage8
];

const otherServices = [
  { 
    title: "Comprehensive Analysis", 
    text: "Our comprehensive company-level fundamental analysis examines financial health through key indicators like revenue, profitability, cash flow, and debt structure. It evaluates the sustainability of the business model, competitive standing within the industry, and potential for long-term growth. By analyzing historical performance, market trends, and strategic initiatives, we provide a deep understanding of a company's overall strength and trajectory.",
    content: [
      {
        sectionTitle: 'Comprehensive Company-Level Fundamental Analysis',
        text: "Uncover a company's true intrinsic and relative value through a methodical and data-driven approach. Our analysis delves into key financial metrics, including revenue, earnings, cash flow, and balance sheet strength, providing a clear picture of financial health and long-term viability. By evaluating profitability, debt levels, operational efficiency, and industry position, we help investors make informed decisions based on a company's fundamental strengths, ensuring a solid foundation for strategic investment planning.",
        image: serviceImage1
      },
      
    ]
  },
  { 
    title: "Economic Data", 
    text: "Our economic data service provides in-depth understanding of macroeconomic indicators, trends, and forecasts to support strategic decision-making. We analyze key metrics such as GDP growth, inflation, interest rates, employment figures, and consumer spending. By leveraging real-time data and historical patterns, we help businesses and investors anticipate economic shifts and adapt to changing market conditions.",
    content: [
      {
        sectionTitle:'Premier Economic Data',
        text: " Gain a comprehensive perspective on the global economy with our in-depth economic data service. We track GDP growth rates, inflation trends, employment statistics, trade balances, and other key macroeconomic indicators to deliver a clear understanding of market conditions. By analyzing historical data and real-time economic shifts, our service equips businesses and investors with critical insights to anticipate trends, assess risks, and make well-informed decisions in an evolving economic landscape.",
        image: serviceImage2
      },
      {
        sectionTitle: 'Strategic Market Insights',
        text: " Tailored for investors and analysts, our service provides a deep understanding of the macroeconomic environment and its influence on investment opportunities and risk assessment. By analyzing key economic indicators, market trends, and financial dynamics, we offer key insights that support strategic investment decisions. With a focus on broader market movements, our analysis helps you anticipate shifts, identify emerging trends, and strengthen your competitive position in the market.",
        image: serviceImage3
      }
    ]
  },
  // Continue with other services in the same format
  { 
    title: "Interactive Chart", 
    text: "Our interactive charts enable clients to create dynamic, customized visualizations using our extensive financial and economic data. With access to a vast database, users can tailor charts to highlight key metrics, track trends, and analyze indicators in real time. This powerful tool enhances informed decision-making by transforming complex information into clear, actionable guidance.",
    content: [
      {
        sectionTitle: 'Dynamic Data Visualization',
        text: "VegaVeta empowers clients with dynamic, customizable charting tools that leverage our extensive financial and economic data. With access to a vast database, users can tailor visualizations to track key metrics, market trends, and economic indicators aligned with their investment strategies. Our intuitive interface enables seamless customization, allowing investors to analyze data in real-time, compare historical trends, and gain deeper insights into financial markets—all in a visually compelling and actionable format.",
        image: serviceImage4
      },
      {
        sectionTitle: 'Interactive Data Visualization',
        text: "This powerful interactive tool enables dynamic analysis, allowing clients to explore data in a way that aligns with their specific investment objectives. Whether tracking market movements, comparing company performance, or evaluating economic indicators, VegaVeta’s platform provides the depth, flexibility, and precision needed for comprehensive analysis. By offering customizable visualizations and real-time data access, our tool enhances data-driven decision-making, ensuring investors can identify key trends, refine strategies, and stay ahead in an evolving financial landscape.",
        image: serviceImage5
      }
    ]
  },
  { 
    title: "Regional Analysis", 
    text: "Our regional analysis provides in-depth evaluations of specific geographic markets, assessing the economic, social, and industry-specific factors that shape local market dynamics. By analyzing key indicators such as economic growth, consumer behavior, regulatory landscape, and demographic trends, we deliver a clear understanding of regional strengths, challenges, and market potential to support strategic decision-making.",
    content: [
      {
        sectionTitle: 'Regional Market Insights',
        text: "VegaVeta’s Regional Market Insights provide a deep analysis of economic, social, and political factors shaping specific geographic markets. We assess local economic conditions, industry dynamics, demographic shifts, and regulatory policies to uncover market trends and growth potential. By examining consumer behavior, infrastructure developments, and business climates, our insights offer a nuanced understanding of opportunities and challenges in different regions. This localized approach enables investors and businesses to make strategic decisions in diverse and evolving markets.",
        image: serviceImage6
      },
      {
        sectionTitle: 'Informed Regional Decisions',
        text: "Whether navigating emerging markets or established economies, our analysis equips you with the knowledge to make informed decisions. By uncovering hidden opportunities and anticipating regional risks, we provide the strategic clarity needed to adapt to changing market conditions. With a quantitative approach, VegaVeta helps you stay ahead of economic shifts, regulatory changes, and evolving consumer trends, ensuring a competitive edge in diverse regional landscapes.",
        image: serviceImage7
      }
    ]
  },
  { 
    title: "Weekly Chartbook", 
    text: "Our Weekly Chartbook delivers up-to-date graphical analyses of market trends and performance in a convenient PDF format. Featuring a curated selection of charts, it provides clear visual interpretations of key financial, economic, and industry movements. Designed for quick reference, this resource helps clients stay informed with the latest market perspectives.",
    content: [
      {
        sectionTitle: "VegaVeta's weekly chartbook",
        text: "VegaVeta’s Weekly Chartbook provides a visually compelling analysis of market trends, performance metrics, and key financial patterns. Updated regularly, it features detailed graphs covering equities, ETFs, mutual funds, commodities, and economic indicators, offering a clear snapshot of market movements. Delivered in a convenient PDF format, it enables investors to integrate the latest data into reports, presentations, and strategic planning. This powerful visual tool helps track emerging opportunities, monitor risks, and refine investment approaches with timely, data-driven insights.",
        image: serviceImage8
      },      
    ]
  },
  { 
    title: "Market Insights", 
    text: "Our Market Insights service provides comprehensive coverage of key financial metrics and indicators, offering a deeper understanding of market dynamics. We analyze trends across equities, mutual funds, ETFs, REITs, commodities, and macroeconomic factors, delivering actionable intelligence to support informed decision-making. With real-time data and expert analysis, we help clients navigate evolving market conditions with confidence.",
    content: [
      {
        sectionTitle: "Comprehensive Market Insights",
        text: "VegaVeta delivers in-depth market insights, analyzing a broad spectrum of financial metrics and economic indicators to support informed decision-making. Our reports cover macroeconomic trends, sector performance, asset valuations, and key financial ratios, offering a comprehensive view of market dynamics. By leveraging real-time data and expert analysis, investors can identify emerging opportunities, navigate risks, and optimize strategies. Whether tracking industry shifts or assessing market sentiment, VegaVeta’s insights provide the clarity needed to stay ahead in evolving financial landscapes.",
        image: serviceImage9
      },      
    ]
  },
  { 
    title: "Portfolio Analysis", 
    text: "VegaVeta's Portfolio Analysis service offers a comprehensive evaluation of your investment portfolio, going beyond traditional performance metrics. We assess asset allocation, risk exposure, diversification, and overall efficiency to ensure alignment with your financial goals. By leveraging advanced analytics and industry benchmarks, we provide effective strategies to optimize portfolio performance and enhance long-term investment strategies.",
    content: [
      {
        sectionTitle: "Advanced Risk Analysis",
        text: "Our Advanced Risk Analysis service leverages sophisticated analytical techniques to assess portfolio risk and performance. Using Monte Carlo simulations, we model potential market scenarios to estimate future volatility and returns. Sharpe Ratio assessments help evaluate risk-adjusted returns, ensuring optimal reward-to-risk balance. Value at Risk (VaR) calculations quantify potential losses under different conditions. By integrating these methodologies, we provide a comprehensive evaluation of risk exposure, helping clients make informed investment decisions.",
        image: serviceImage10
      },
      {
        sectionTitle: "Strategic Portfolio Optimization",
        text: "Achieving long-term investment success requires a well-balanced, adaptable portfolio. Our Strategic Portfolio Optimization service provides research-driven guidance tailored to your financial goals and risk tolerance. We analyze asset allocation, market conditions, and investment performance to enhance diversification, maximize growth potential, and mitigate risks. By continuously evaluating opportunities and adjusting strategies, we help ensure your portfolio remains resilient in changing market environments, positioning you for sustained financial success and stability.",
        image: serviceImage10
      }
    ]
  }
];
const newServices = [
    { 
      title: "Securities Recommendations", 
      text: "Our comprehensive company-level fundamental analysis examines financial health through key indicators like revenue, profitability, cash flow, and debt structure. It evaluates the sustainability of the business model, competitive standing within the industry, and potential for long-term growth. By analyzing historical performance, market trends, and strategic initiatives, we provide a deep understanding of a company's overall strength and trajectory.",
      content: [
        {
          sectionTitle: 'Unbiased Investment Recommendations ',
          text: "VegaVeta delivers well-researched, unbiased securities recommendations, leveraging a rigorous analytical approach to identify high-potential investments across equities, ETFs, REITs, mutual funds, commodities, and other financial instruments. By integrating fundamental and technical analysis with our proprietary insights, we pinpoint securities poised for strong performance. Whether you're a seasoned investor or just entering the markets, our expert-driven recommendations are designed to enhance portfolio resilience, optimize returns, and align with your investment strategy, helping you navigate the markets with confidence.",
          image: serviceImage11
        },
        
      ]
    },
    { 
      title: "Forecasting", 
      text: "Our economic data service provides in-depth understanding of macroeconomic indicators, trends, and forecasts to support strategic decision-making. We analyze key metrics such as GDP growth, inflation, interest rates, employment figures, and consumer spending. By leveraging real-time data and historical patterns, we help businesses and investors anticipate economic shifts and adapt to changing market conditions.",
      content: [
        {
          sectionTitle:'Accurate Market Forecasting',
          text: "Utilizing state-of-the-art predictive models and artificial intelligence, VegaVeta’s advanced forecasting methods offer unprecedented accuracy in market predictions.",
          image: serviceImage12
        },
        {
          sectionTitle: 'Investment Insights',
          text: "VegaVeta’s cutting-edge forecasting service leverages state-of-the-art predictive models and artificial intelligence to deliver highly accurate market projections. Designed for investors seeking a competitive edge, our advanced methodologies detect emerging trends, price movements, and investment opportunities ahead of the curve. By analyzing vast datasets with sophisticated algorithms, we provide research-driven forecasts that empower you to make informed decisions and refine your investment strategy for optimal performance.",
          image: serviceImage13
        }
      ]
    },
       
  ];

const Services = () => {
  const [selectedService, setSelectedService] = useState(null);

  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle />
      <Header />
      <Container>
        <ContentWrapper>
          {otherServices.map((service, index) => (
            <Card key={index} onClick={() => setSelectedService(service)} style={{className:"sparkle" }}>
              <Title>{service.title}</Title>
            </Card>
          ))}
        </ContentWrapper>        
        <FutureServicesTitle>Future Services</FutureServicesTitle>
        <ContentWrapper>
          {newServices.map((service, index) => (
            <Card key={index} onClick={() => setSelectedService(service)}>
              <Title>{service.title}</Title>
            </Card>
          ))}
        </ContentWrapper>
      </Container>

      {selectedService && (
  <ModalOverlay onClick={() => setSelectedService(null)}>
    <ModalContent onClick={e => e.stopPropagation()}>
      <CloseButton onClick={() => setSelectedService(null)}>×</CloseButton>
      {/* <ModalTitle>{selectedService.title}</ModalTitle> */}
      
      {selectedService.content.map((item, index) => (
        <ContentRow key={index} reverse={index % 2 !== 0}>
          <LeftColumn>
            <SectionTitle>{item.sectionTitle || `Key Insight ${index }`}</SectionTitle>
            <ModalText>{item.text}</ModalText>
          </LeftColumn>
          <RightColumn>
            <img src={item.image} alt={item.alt || selectedService.title} />
          </RightColumn>
        </ContentRow>
      ))}
    </ModalContent>
  </ModalOverlay>
)}

      <Footer />
    </ThemeProvider>
  );
};

export default Services;