import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { supabase } from '../supabaseClient';
import '@fortawesome/fontawesome-free/css/all.min.css';

// Styled Components
const GearMenuContainer = styled.div`
  position: relative;
  display: inline-block;
  // float: right;
`;

const GearIcon = styled.button`
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;

  &:hover {
    color: #4caf50;
  }
`;

const Menu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 10;
  width: 200px;
`;

const MenuItem = styled.div`
  padding: 12px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ showModal }) => (showModal ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  max-width: 100%;
  position: relative;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  font-size: 18px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;

  &:hover {
    background-color: #45a049;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid ${({ isError }) => (isError ? 'red' : '#ddd')};
  border-radius: 4px;
  font-size: 16px;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd;
`;

const TableHeader = styled.th`
  padding: 8px;
  text-align: left;
`;

const TableData = styled.td`
  padding: 8px;
`;

const GearIconWithMenu = ({ isMenuOpen, onToggle, pfId, pfName, color, background }) => {
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [portfolioId, setPortfolioId] = useState(null);
  const [portfolioList, setPortfolioList] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [symbol, setSymbol] = useState('');
  const [shares, setShares] = useState('');
  const [purchaseValue, setPurchaseValue] = useState('');
  const [errors, setErrors] = useState({});
  const [editModal, setEditModal] = useState(false);
  const [editPortfolioModal, setEditPortfolioModal] = useState(false);
  const [portfolioName, setPortfolioName] = useState('');
  
  // Fetch Portfolio data based on selected Portfolio ID
  const fetchPortfolio = async (portfolioId) => {
    const { data, error } = await supabase
      .from('PortfolioComposition')
      .select('*')
      .eq('Portfolio_ID', portfolioId)
      .eq('Client_ID', localStorage.getItem('client_id'));

    if (error) {
      console.error('Error fetching portfolio:', error);
    } else {
      setPortfolio(data);
    }
  };

  // Fetch the list of portfolios
  const fetchPortfolioList = async () => {
    const { data, error } = await supabase
      .from('PortfolioIdentity')
      .select('*')
      .eq('Client_ID', localStorage.getItem('client_id'));

    if (error) {
      console.error('Error fetching portfolio list:', error);
    } else {
      setPortfolioList(data);
      if (data.length > 0) {
        setPortfolioId(data[0].Portfolio_ID); // Automatically select the first portfolio
      }
    }
  };

  useEffect(() => {
    // fetchPortfolioList();
  }, []);

  useEffect(() => {
    // if (portfolioId) {
      fetchPortfolio(pfId);
    // }
  }, [pfId]);

  const handleEditShare = (item) => {
    setSymbol(item.Symbol);
    setShares(item.Share);
    setPurchaseValue(item.Purchase_Value);
    setShowModal(true);
    setEditModal(false);
  };

  const handleDeleteShare = async (symbol) => {
    const { data, error } = await supabase
      .from('PortfolioComposition')
      .delete()
      .eq('Portfolio_ID', portfolioId)
      .eq('Symbol', symbol);

    if (error) {
      console.error('Error deleting share:', error);
    } else {
      setPortfolio((prev) => prev.filter((item) => item.symbol !== symbol));
    }
  };

  const handleAddOrUpdateShares = async () => {
    const updatedShare = {
      Symbol,
      Share: parseFloat(shares),
      Purchase_Value: parseFloat(purchaseValue),
    };

    const { data, error } = await supabase
      .from('PortfolioComposition')
      .upsert([updatedShare], { onConflict: ['Symbol', 'Portfolio_ID'] });

    if (error) {
      console.error('Error updating shares:', error);
    } else {
      setPortfolio((prev) =>
        prev.map((item) =>
          item.symbol === symbol
            ? { ...item, Share: updatedShare.Share, Purchase_Value: updatedShare.Purchase_Value }
            : item
        )
      );
      setShowModal(false); // Close modal after adding or updating
    }
  };

  const handleAddShares = () => {
    setSymbol('');
    setShares('');
    setPurchaseValue('');
    setShowModal(true); // Open the modal to add shares
  };


  const handleEditPortfolio = async () => {
    const { data, error } = await supabase
      .from('PortfolioIdentity')
      .update({ Portfolio_Name: portfolioName })
      .eq('Portfolio_ID', portfolioId);

    if (error) {
      console.error('Error updating portfolio:', error);
    } else {
      fetchPortfolioList(); // Refresh the portfolio list
      setEditPortfolioModal(false);
    }
  };

  return (
    <div>
      <GearMenuContainer style={{color:color}}>
        <GearIcon onClick={onToggle} style={{color:color}}>
          <i className="fas fa-cog"></i>
        </GearIcon>

        <Menu isOpen={isMenuOpen} style={{color:'#000'}}>
          <MenuItem onClick={handleAddShares}>Add New Shares</MenuItem>
          <MenuItem onClick={() => setEditModal(true)}>Edit Shares</MenuItem>
          <MenuItem onClick={() => setEditPortfolioModal(true)}>Edit Portfolio</MenuItem>
        </Menu>
      </GearMenuContainer>

      {/* Modal for Adding/Editing Shares */}
      <ModalContainer showModal={showModal}>
        <ModalContent >
          <CloseButton onClick={() => setShowModal(false)}>&times;</CloseButton>
          <h2>{symbol ? 'Edit Shares' : 'Add New Shares'}: {pfName}</h2>
          {/* <Select onChange={(e) => setPortfolioId(e.target.value)} value={portfolioId}>
            <option value="" disabled>Select Portfolio</option>
            {portfolioList.map((portfolioItem) => (
              <option key={portfolioItem.Portfolio_ID} value={portfolioItem.Portfolio_ID}>
                {portfolioItem.Portfolio_Name}
              </option>
            ))}
          </Select> */}
          <Input
            type="text"
            value={symbol}
            onChange={(e) => setSymbol(e.target.value.toUpperCase())}
            placeholder="Company Symbol"
          />
          <Input
            type="number"
            value={shares}
            onChange={(e) => setShares(e.target.value)}
            placeholder="Shares"
          />
          <Input
            type="number"
            value={purchaseValue}
            onChange={(e) => setPurchaseValue(e.target.value)}
            placeholder="Purchase Value"
          />
          <Button onClick={handleAddOrUpdateShares}>Save</Button>
        </ModalContent>
      </ModalContainer>

      {/* Edit Shares Table */}
      <ModalContainer showModal={editModal}>
        <ModalContent style={{color:'#000'}}>
          <CloseButton onClick={() => setEditModal(false)}>&times;</CloseButton>
          <h2>Edit Shares: {pfName}</h2> 
          {/* <Select onChange={(e) => setPortfolioId(e.target.value)} value={portfolioId}>
            <option value="" disabled>Select Portfolio</option>
            {portfolioList.map((portfolioItem) => (
              <option key={portfolioItem.Portfolio_ID} value={portfolioItem.Portfolio_ID}>
                {portfolioItem.Portfolio_Name}
              </option>
            ))}
          </Select> */}
          <Table>
            <thead>
              <tr>
                <TableHeader>Symbol</TableHeader>
                <TableHeader>Shares</TableHeader>
                <TableHeader>Purchase Value</TableHeader>
                <TableHeader>Actions</TableHeader>
              </tr>
            </thead>
            <tbody>
              {portfolio.map((item) => (
                <TableRow key={item.Symbol}>
                  <TableData>{item.Symbol}</TableData>
                  <TableData>{item.Share}</TableData>
                  <TableData>{item.Purchase_Value}</TableData>
                  <TableData>
                    <button
                      onClick={() => handleEditShare(item)}
                      style={{ cursor: 'pointer', marginRight: '10px' }}
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </button>
                    <button
                      onClick={() => handleDeleteShare(item.Symbol)}
                      style={{ cursor: 'pointer' }}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </TableData>
                </TableRow>
              ))}
            </tbody>
          </Table>
        </ModalContent>
      </ModalContainer>
      <ModalContainer showModal={editPortfolioModal}>
        <ModalContent>
          <CloseButton onClick={() => setEditPortfolioModal(false)}>&times;</CloseButton>
          <h2>Edit Portfolio Name</h2>
          <Input
            type="text"
            value={portfolioName}
            onChange={(e) => setPortfolioName(e.target.value)}
            placeholder="Portfolio Name"
          />
          <Button onClick={handleEditPortfolio}>Save Changes</Button>
        </ModalContent>
      </ModalContainer>
    </div>
  );
};

export default GearIconWithMenu;
