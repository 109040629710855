import React, {useState} from 'react';
import axios from 'axios';
import { FaSearch ,FaEdit} from 'react-icons/fa'; // Import a search icon from react-icons
import { Responsive, WidthProvider } from 'react-grid-layout';
import Watchlist from './Watchlist'; // Component for watchlist
import LineChart from './LineChart'; // Component for line chart
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import Header from '../Header';
import Footer from '../../Components/Common/Footer';
import { useAuth } from "@clerk/clerk-react";
import { supabase } from '../../supabaseClient';
import { toast, ToastContainer } from 'react-toastify';
import { FiSettings } from "react-icons/fi";
import GainersData from './GainersData';
import LosersData from './LosersData';
import ActivesData from './ActivesData';
import SectorPerformanceData from './SectorPerformanceData';
import IndustryPerformanceData from './IndustryPerformanceData';
import SectorPESnapshotData from './SectorPESnapshotData';
import IndustryPESnapshotData from './IndustryPESnapshotData';


const ResponsiveGridLayout = WidthProvider(Responsive);

const lightTheme = {
  cardBackground: "#ffffff",
  color: "#000000",
  background: "#f5f5f5",
  cardColor: "#000000",
  borderColor: "#000000",
  primary: '#AC544C',
  secondary: '#b06252',
};

const darkTheme = {
  background: "rgb(31 41 55)",
  color: "#ffffff",
  cardBackground: "rgb(55 65 81)",
  cardColor: "#ffffff",
  borderColor: "#ffffff",
  primary: '#AC544C',
  secondary: '#b06252',
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    height: 100%;
    transition: background-color 0.3s, color 0.3s;
  }
`;


const MarketPageWrapper = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  margin-top:10px;
`;

const Card = styled.div`
  background: ${({ theme }) => theme.cardBackground};
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
   
  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    cursor: se-resize;
    background-color:  ${({ theme }) => theme.color};/* Optional styling for visibility */
    border-radius: 50%; /* Optional for circular handle */
        z-index: 2; /* Make sure resize handle appears above other elements */
  }
  button {
    position: relative;
    z-index: 3; /* Ensure buttons stay clickable above other content */
  }
`;


const WatchlistCard = styled(Card)`
  // min-height: 150px;
  width: 100%;
  // height: 250px;
  // overflow: auto;
  // margin-top:10%;
`;

const LineChartCard = styled(Card)`
  height: 400px;
`;

const layout = [
  { i: 'watchlist', x: 0, y: 0, w: 3, h: 3, minW: 2, minH: 2, maxW: 4, maxH: 4 },
  // { i: 'chart1', x: 2, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 4, maxH: 4 },
  // { i: 'chart2', x: 4, y: 0, w: 2, h: 2, minW: 2, minH: 2, maxW: 4, maxH: 4 },
  // { i: 'chart3', x: 0, y: 2, w: 2, h: 2, minW: 2, minH: 2, maxW: 4, maxH: 4 },
  // { i: 'chart4', x: 2, y: 2, w: 2, h: 2, minW: 2, minH: 2, maxW: 4, maxH: 4 },
  // { i: 'chart5', x: 4, y: 2, w: 2, h: 2, minW: 2, minH: 2, maxW: 4, maxH: 4 },
  { i: 'gainers', x: 4, y: 2, w: 3, h: 3, minW: 2, minH: 2, maxW: 4, maxH: 4 },
  { i: 'losers', x: 4, y: 2, w: 3, h: 3, minW: 2, minH: 2, maxW: 4, maxH: 4 },
  { i: 'actives', x: 4, y: 2, w: 3, h: 3, minW: 2, minH: 2, maxW: 4, maxH: 4 },
  { i: 'sectorperformance', x: 0, y: 2, w: 3, h: 3, minW: 2, minH: 2, maxW: 4, maxH: 4 },
  { i: 'industryperformance', x: 0, y: 2, w: 3, h: 3, minW: 2, minH: 2, maxW: 4, maxH: 4 },
  { i: 'sectorpe', x: 0, y: 2, w: 3, h: 3, minW: 2, minH: 2, maxW: 4, maxH: 4 },
  { i: 'industrype', x: 4, y: 2, w: 3, h: 3, minW: 2, minH: 2, maxW: 4, maxH: 4 },
];

const SearchBarContainer = styled.div`
 
position: relative;
  width: 15%;
  margin-top:60px;
  // margin-bottom:20px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px 40px 10px 15px; /* Added padding to accommodate the icon */
  font-size: 16px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
`;

const Dropdown = styled.div`
  position: 20px;
  // top: 110px; /* Adjust based on the SearchBar height */
  left: 0;
  width: 30%;
  max-height: 200px; /* Set the maximum height for scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  z-index: 1000; /* Ensure dropdown appears above other content */
  color: ${({ theme }) => theme.background};
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.color};
  // &:hover {
  //   background-color: ${({ theme }) => theme.primary};
  //   color: ${({ theme }) => theme.color};
  // }
`;



const SearchIcon = styled(FaSearch)`
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust position as needed */
  transform: translateY(-50%);
  color: ${({ theme }) => theme.primary};
`;

const WatchListButton = styled.button `
  float:right;
  marginLeft: '10px',
  padding: '5px 10px',
  // background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.color};
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
`;

const GearMenuContainer = styled.div`
  // postion:relative;
  margin-top:100px;
  // display: inline-block;
  // float: right;

`;

const GearIcon = styled.button`
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;

  &:hover {
    color: #4caf50;
  }
`;

const Menu = styled.div`
  position: absolute; /* Ensures it floats above other elements */
  top: 8%; /* Positions it directly below its parent */
  right: 2%;
  background-color: ${({ theme }) => theme.cardBackground};
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 9999; /* Ensures it stays above other elements */
  width: 400px;
`;
const MenuItem = styled.div`
  padding: 12px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: ${({ theme }) => theme.background};;
  }
`;

const SubMenu = styled.div`
  // position: absolute;
  top: 0;
  left: 100%;
  margin-left:20px;
  // background-color: ${({ theme }) => theme.background};
  // border: 1px solid #ddd;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  // border-radius: 4px;
  // display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  z-index: 10000;
  // width: 220px;
  padding: 5px;
`;
const SubMenuItem = styled.div`
  padding: 6px 10px;
  font-weight: normal;
  &:hover {
    background: #f0f0f0;
  }
`;


const MarketPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchName, setSearchName] = useState('');
  const [selectedSymbol, setSelectedSymbol] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { userId } = useAuth();
  const [callWatchList, setCallWatchList] = useState(true);

  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark' ? darkTheme : lightTheme;
  });

  const handleThemeChange = () => {
    const newTheme = theme === lightTheme ? darkTheme : lightTheme;
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme === lightTheme ? 'light' : 'dark');
  };

  const handleSearchChange = async (e) => {
    const query = e.target.value;
    setSearchTerm(query);

    if (query.length > 2) {
      await fetchSearchResults(query);
    } else {
      setSearchResults([]);
    }
  };


  const fetchSearchResults = async (query) => {
    try {
      const response = await axios.get(`https://financialmodelingprep.com/api/v3/search?query=${query}&apikey=11cd608ea5136b9fdb748e3a80c1724e`);
      setSearchResults(response);
    } catch (error) {
      console.error('Error fetching search results', error);
    }
  };

  const fetchChartData = async (symbol, range) => {
    setLoading(true);
    try {
      const response = await axios.get(`https://financialmodelingprep.com/api/v3/historical-price-full/${symbol}?apikey=11cd608ea5136b9fdb748e3a80c1724e&from=2023-01-01`);
      const data = response.historical.map(item => ({
        date: item.date,
        adjClose: item.adjClose,
        volume: item.volume,
        open: item.open,
        high: item.high,
        low: item.low,
      }));
      
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };


  const handleSearchSelect = (res) => {
    setSelectedSymbol(res.symbol);
    setSearchTerm('');
    setSearchName(res.name + " - " +res.symbol);
    setSearchResults([]);
    
  };

  const handleAddToWatchlist = async (result) => {
    console.log(userId,result);
    const { data, error } = await supabase.from('market_watchlist').insert([
      {
        user_id: userId,
        name: result.name,
        symbol: result.symbol,
      },
    ]);

    if (error) {
      console.error('Error adding to watchlist:', error);
      toast.error('Error adding to watchlist');
      
    } else {      
      setSearchResults([]);
      setSearchTerm('');
      setCallWatchList(!callWatchList);
      toast.success('Successfully added to watchlist!');
    }
  };

  const [openMenu, setOpenMenu] = useState(false);

  const menuData = [
    {
      title: "Market Performance",
      items: [
        "Sector Performance Snapshot",
        "Industry Performance Snapshot",
        "Historical Sector Performance",
        "Historical Industry Performance",
      ],
    },
    {
      title: "PE Ratio",
      items: [
        "Sector PE Snapshot",
        "Industry PE Snapshot",
        "Historical Sector PE",
        "Historical Industry PE",
      ],
    },
    {
      title: "Market Leaders",
      items: ["Biggest Gainers", "Biggest Losers", "Most Active"],
    },
  ];

  const toggleMenu = () => {
    setOpenMenu((prev) => !prev);
  };
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header
        layoutModeType={theme === lightTheme ? 'light' : 'dark'}
        onChangeLayoutMode={handleThemeChange}
      />
      
      <MarketPageWrapper>
        <div style={{ display: "flex", flexWrap: "nowrap", alignItems: "center", justifyContent: "space-between" }}>

      <SearchBarContainer>
            <SearchInput
            type="text"
            placeholder="Search for a symbol (e.g., AAPL)"
            value={searchTerm}
            onChange={handleSearchChange}
            />
            <SearchIcon />
            
        </SearchBarContainer>
        <GearMenuContainer >
              <GearIcon onClick={toggleMenu} >
                <i className="fas fa-cog"></i>
              </GearIcon>

              <Menu isOpen={openMenu}>
      {menuData.map((category, index) => (
        <div key={index}>
          <MenuItem>{category.title}</MenuItem>
          <SubMenu>
            {category.items.map((item, subIndex) => (
              <SubMenuItem key={subIndex}>{item}</SubMenuItem>
            ))}
          </SubMenu>
        </div>
      ))}
    </Menu>
              {/* <Menu isOpen={openMenu} >
                <MenuItem >Add New Shares</MenuItem>
                <MenuItem >Edit Shares</MenuItem>
                <MenuItem >Edit Portfolio</MenuItem>
              </Menu> */}
            </GearMenuContainer>
            </div>
        {searchResults.length > 0 && (
              <Dropdown>
                {searchResults.map(result => (
                  <DropdownItem key={result.symbol} onClick={() => handleSearchSelect(result)}>
                    {result.symbol} - {result.name}
                    <WatchListButton
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the `onClick` of the DropdownItem
                      handleAddToWatchlist(result);
                    }}                    
                  >
                    Add to watchlist
                  </WatchListButton>
                  </DropdownItem>
                ))}
              </Dropdown>
            )}

            
        <ResponsiveGridLayout
          className="layout"
          layouts={{ lg: layout }}
          breakpoints={{ lg: 1200, md: 996, sm: 768 }}
          cols={{ lg: 6, md: 4, sm: 2 }}
          rowHeight={150}
          isResizable
          isDraggable
          resizeHandles={['se']} // Restrict resizing to southeast corner
          onResizeStop={(layout) => {
            // Prevent resizing below current height except when maximized
            layout.forEach((item) => {
              if (item.i !== 'watchlist' && item.h < 2) {
                item.h = 2; // Reset to minimum height if height is too small
              }
            });
          }}
          style={{ zIndex: 1, pointerEvents: 'auto' }} // Add pointerEvents: 'auto' here to allow interaction
        >
          <WatchlistCard key="watchlist">
            Watchlist
            <Watchlist callWatchList={callWatchList}/>
          </WatchlistCard>
          <WatchlistCard key="gainers">
            Top Gainers
            <GainersData />
          </WatchlistCard>
          <WatchlistCard key="losers">
            Top Losers
            <LosersData />
          </WatchlistCard>
          <WatchlistCard key="actives">
            Most actives
            <ActivesData />
          </WatchlistCard>
          <WatchlistCard key="sectorperformance">
            Sector Performance Snapshot
            <SectorPerformanceData />
          </WatchlistCard>
          <WatchlistCard key="industryperformance">
            Industry Performance Snapshot
            <IndustryPerformanceData />
          </WatchlistCard>
          <WatchlistCard key="sectorpe">
            Sector PE Snapshot
            <SectorPESnapshotData />
          </WatchlistCard>
          <WatchlistCard key="industrype">
            Industry PE Snapshot
            <IndustryPESnapshotData />
          </WatchlistCard>
          
          
          {/* <LineChartCard key="chart1">
            <LineChart />
          </LineChartCard>
          <LineChartCard key="chart2">
            <LineChart />
          </LineChartCard>
          <LineChartCard key="chart3">
            <LineChart />
          </LineChartCard>
          <LineChartCard key="chart4">
            <LineChart />
          </LineChartCard>
          <LineChartCard key="chart5">
            <LineChart />
          </LineChartCard> */}
        </ResponsiveGridLayout>
      </MarketPageWrapper>
      {/* <Footer /> */}
      <ToastContainer/>      
    </ThemeProvider>
  );
};

export default MarketPage;
