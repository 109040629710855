import React, { useState } from "react";
import countriesCitiesData from "../../assets/countriesandcities.json";
import styled from "styled-components";

const SelectWrapper = styled.div`
  margin-bottom: 1rem;
`;

const Select = styled.select`
  width: 100%;
  padding: 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  background-color: #fff;
  color: ${props => props.value ? '#333' : '#999'};
  transition: all 0.2s;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23333%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px auto;
  
  &:focus {
    border-color: #4a90e2;
    outline: none;
    box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.2);
  }
`;

function CountryCitySelect({ onCountryChange, onCityChange, placeholder }) {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  const handleCountryChange = (event) => {
    const country = event.target.value;
    setSelectedCountry(country);
    setSelectedCity(""); // Reset city when country changes
    onCityChange(""); // Clear city selection
    
    // Get unique cities for the selected country
    if (country && countriesCitiesData[country]) {
      const uniqueCities = [...new Set(countriesCitiesData[country])];
      setCities(uniqueCities.sort());
    } else {
      setCities([]);
    }
    
    onCountryChange(country);
  };

  const handleCityChange = (event) => {
    const city = event.target.value;
    setSelectedCity(city);
    onCityChange(city);
  };

  return (
    <div>
      {/* Country Dropdown */}
      <SelectWrapper>
        <Select 
          id="country" 
          onChange={handleCountryChange} 
          value={selectedCountry}
          required
        >
          <option value="">{placeholder || "Select country"}</option>
          {Object.keys(countriesCitiesData).sort().map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </Select>
      </SelectWrapper>

      {/* City Dropdown - Only show if country is selected */}
      {selectedCountry && (
        <SelectWrapper>
          <Select 
            id="city" 
            onChange={handleCityChange} 
            value={selectedCity}
            required
          >
            <option value="">Select city</option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </Select>
        </SelectWrapper>
      )}
    </div>
  );
}

export default CountryCitySelect;