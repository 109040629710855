import React, { useState } from 'react';
import styled from 'styled-components';
import { supabase } from '../supabaseClient';
import * as XLSX from 'xlsx';
// Styled components (Same as before)
const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  color: #333;
  padding: 40px;
  border-radius: 8px;
  max-width: 800px;
  max-height:850px;
  // overflow-y:auto;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
`;

const ProgressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  position: relative;
`;

const ProgressTab = styled.div`
  position: relative;
  flex: 1;
  text-align: center;
  font-size: 16px;
  color: ${({ isActive }) => (isActive ? '#4caf50' : '#ccc')};

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    background-color: ${({ isActive }) => (isActive ? '#4caf50' : '#ccc')};
    border-radius: 50%;
    border: 2px solid #fff;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 2px;
    background-color: ${({ isActive }) => (isActive ? '#4caf50' : '#ccc')};
  }
`;

const ProgressLine = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ccc;
  z-index: -1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 2px;
    background-color: #4caf50;
    visibility: ${({ isStepTwo }) => (isStepTwo ? 'visible' : 'hidden')};
  }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
`;

const Select = styled.select`
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid ${({ isValid }) => (isValid ? '#ddd' : '#e74c3c')};
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;

  &:focus {
    border-color: #4caf50;
    outline: none;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid ${({ isValid }) => (isValid ? '#ddd' : '#e74c3c')};
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;

  &:focus {
    border-color: #4caf50;
    outline: none;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;

  th, td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }

  thead {
  position: sticky;
  top: 0;
  background-color: #fff; /* Optional: Background for header */
  z-index: 1; /* Ensure header is above other rows */
}

tbody {
  // display: block;
  height: 600px;
  overflow-y: auto;
}

    

  input {
    // width: 90%;
  }
`;

const TableButton = styled.button`
  padding: 8px 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  margin-top:20px;

  &:hover {
    background-color: #45a049;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;  
`;

const Button = styled.button`
  padding: 12px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  font-size: 18px;

  &:hover {
    background-color: #45a049;
  }
`;
const Button1 = styled.button`
  padding: 12px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  font-size: 18px;
  margin-left:10px;

  &:hover {
    background-color: #45a049;
  }
`;
const Button2 = styled.button`
  padding: 10px;
  background-color: #AC544C;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  font-size: 12px;
  margin-left:5px;

  &:hover {
    background-color: #45a049;
  }
`;
const CloseButton = styled.button`
  padding: 12px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  width: 100%;
  font-size: 18px;
  margin-top: 20px;

  &:hover {
    background-color: #e60000;
  }
`;

const templateData = [
  { 'Symbol': '', 'Shares': '', 'Purchase Value': '' }
];


const PortfolioModal = ({ isModalOpen, closeModal, pfCount }) => {
  const [step, setStep] = useState(1);
  const [portfolioName, setPortfolioName] = useState('');
  const [financialGoal, setFinancialGoal] = useState('');
  const [investmentStrategy, setInvestmentStrategy] = useState('');
  const [accountType, setAccountType] = useState('');
  const [benchmark, setBenchmark] = useState('');
  const [portfolioModel, setPortfolioModel] = useState('');
  const [portfolioData, setPortfolioData] = useState([{ symbol: '', shares: '', purchaseValue: '' },{ symbol: '', shares: '', purchaseValue: '' },
    { symbol: '', shares: '', purchaseValue: '' },{ symbol: '', shares: '', purchaseValue: '' },{ symbol: '', shares: '', purchaseValue: '' }
  ]); // Array of shares

  const handleClose = () => {
    if (typeof closeModal === 'function') {
      closeModal(); // Close the modal
    } else {
      console.error('closeModal is not a function');
    }
  };

  const addPortfolio = async () => {
    if (!portfolioName || !accountType) {
      alert('Please fill out all fields.');
      return;
    }

    try {
      const { data, error } = await supabase.from('PortfolioIdentity').insert([
        {
          Client_ID: localStorage.getItem('client_id'),
          Portfolio_ID: pfCount + 1,
          Portfolio_Name: portfolioName,
          // Financial_Goal: financialGoal,
          Account_Types: accountType,
          Benchmark: benchmark,
          // Portfolio_Model: portfolioModel,
          Portfolio_Status: true,
          DateTime: new Date()
        },
      ]);

      if (error) {
        throw error;
      }

      setStep(2); // Move to Step 2 (Add Shares)
    } catch (error) {
      alert('Error adding portfolio: ' + error.message);
    }
  };

  const addShares = async () => {
    if (portfolioData.some(data => !data.symbol || !data.shares || !data.purchaseValue)) {
      alert('Please fill out all fields for each share.');
      return;
    }

    try {
      const insertData = portfolioData.map(data => ({
        Client_ID: parseInt(localStorage.getItem('client_id')),
        Symbol: data.symbol,
        Share: parseFloat(data.shares),
        Purchase_Value: parseFloat(data.purchaseValue),
        Portfolio_ID: pfCount + 1,
        DateTime: new Date()
      }));

      const { error } = await supabase.from('PortfolioComposition').insert(insertData);

      if (error) {
        throw error;
      }

      closeModal(); // Close the modal after adding shares
    } catch (error) {
      alert('Error adding shares: ' + error.message);
    }
  };

  const handleNext = () => {
    if (step === 1 && (portfolioName  && accountType )) {
      addPortfolio();
    }
    if (step === 2 && portfolioData.every(data => data.symbol && data.shares && data.purchaseValue)) {
      addShares();
    }
  };

  const handleBack = () => {
    setStep(1);
  };

  const handleAddRow = () => {
    setPortfolioData([...portfolioData, { symbol: '', shares: '', purchaseValue: '' }]);
  };

  const handleRemoveRow = (index) => {
    const updatedData = [...portfolioData];
    updatedData.splice(index, 1);
    setPortfolioData(updatedData);
  };

  const generateTemplate = (type) => {
    const ws = XLSX.utils.json_to_sheet(templateData, { header: ['symbol', 'shares', 'purchaseValue'] });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Template');
  
    if (type === 'xlsx') {
      XLSX.writeFile(wb, 'portfolio_template.xlsx');
    } else if (type === 'csv') {
      const csvData = XLSX.utils.sheet_to_csv(ws);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'portfolio_template.csv');
      link.click();
    }
  };

  const downloadData = () => {
    const ws = XLSX.utils.json_to_sheet(portfolioData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, 'portfolio_data.xlsx');
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Assume the first sheet is the one with data
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      // Validate and assign the data to portfolioData
      const isValid = jsonData.every((row) =>
        ['symbol', 'shares', 'purchaseValue'].every((key) => key in row)
      );

      if (isValid) {
        setPortfolioData(jsonData);
      } else {
        alert('Invalid file format. Please upload a file with the correct columns.');
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return isModalOpen ? (
    <ModalContainer>
      <ModalContent>
        <Title>{step === 1 ? 'Create Portfolio' : 'Add Shares'}</Title>

        <ProgressContainer>
          <ProgressTab isActive={step === 1}>Step 1</ProgressTab>
          <ProgressLine isStepTwo={step === 2} />
          <ProgressTab isActive={step === 2}>Step 2</ProgressTab>
        </ProgressContainer>

        {step === 1 ? (
          <form>
          <FormGroup>
            <Label>Portfolio Name</Label>
            <Input
              type="text"
              value={portfolioName}
              onChange={(e) => setPortfolioName(e.target.value)}
              isValid={portfolioName.length > 0}
            />
          </FormGroup>

          {/* <FormGroup>
            <Label>Financial Goal</Label>
            <Select
              value={financialGoal}
              onChange={(e) => setFinancialGoal(e.target.value)}
              isValid={financialGoal.length > 0}
            >
              <option value="">Select Goal</option>
              <option value="Growth">Growth</option>
              <option value="Income">Income</option>
              <option value="Stability">Stability</option>
            </Select>
          </FormGroup> */}

          {/* <FormGroup>
            <Label>Investment Strategy</Label>
            <Select
              value={investmentStrategy}
              onChange={(e) => setInvestmentStrategy(e.target.value)}
              isValid={investmentStrategy.length > 0}
            >
              <option value="">Select Strategy</option>
              <option value="Aggressive">Aggressive</option>
              <option value="Moderate">Moderate</option>
              <option value="Conservative">Conservative</option>
            </Select>
          </FormGroup> */}

          <FormGroup>
            <Label>Account Type</Label>
            <Select
              value={accountType}
              onChange={(e) => setAccountType(e.target.value)}
              isValid={accountType.length > 0}
            >
              <option value="">Select Type</option>
              <option value="Taxable">Taxable</option>
              <option value="Tax-Deferred">Tax-Deferred</option>
              <option value="Tax-Free">Tax-Free</option>
            </Select>
          </FormGroup>

          <FormGroup>
            <Label>Benchmark</Label>
            <Input
              type="text"
              value={benchmark}
              onChange={(e) => setBenchmark(e.target.value)}
              isValid={benchmark.length > 0}
            />
          </FormGroup>

          {/* <FormGroup>
            <Label>Portfolio Model</Label>
            <Select
              value={portfolioModel}
              onChange={(e) => setPortfolioModel(e.target.value)}
              isValid={portfolioModel.length > 0}
            >
              <option value="">Select Model</option>
              <option value="Growth">Growth</option>
              <option value="Conservative">Conservative</option>
            </Select>
          </FormGroup> */}

          <ButtonContainer>
            <Button type="button" onClick={handleNext}>Next</Button>
          </ButtonContainer>
        </form>
        ) : (
          <>
          <div style={{display:"flex"}}>
            {/* <Button onClick={() => generateTemplate('csv')} style={{ marginBottom: '20px' }}>
        Download CSV Template
      </Button>
      <Button onClick={() => generateTemplate('xlsx')} style={{ marginBottom: '20px' }}>
        Download Excel Template
      </Button>
      
      <Button onClick={downloadData} style={{ marginBottom: '20px' }}>
        Download Data
      </Button> */}
      <Button2 onClick={() => generateTemplate('csv')}>
        Download CSV Template
      </Button2>
      <Button2  onClick={() => generateTemplate('xlsx')}>
        Download Excel Template
      </Button2>
      
      <Button2 onClick={downloadData} >
        Download Data
      </Button2>
      
      </div>
          <div style={{ overflowY: "auto", height: "450px" }}>
            <Table>
              <thead>
                <tr>
                  <th>Symbol</th>
                  <th>Shares</th>
                  <th>Purchase Value</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody >
                {portfolioData.map((row, index) => (
                  <tr key={index}>
                    <td><Input value={row.symbol} onChange={e => {
                      const newData = [...portfolioData];
                      newData[index].symbol = e.target.value;
                      setPortfolioData(newData);
                    }} /></td>
                    <td><Input value={row.shares} onChange={e => {
                      const newData = [...portfolioData];
                      newData[index].shares = e.target.value;
                      setPortfolioData(newData);
                    }} /></td>
                    <td><Input value={row.purchaseValue} onChange={e => {
                      const newData = [...portfolioData];
                      newData[index].purchaseValue = e.target.value;
                      setPortfolioData(newData);
                    }} /></td>
                    <td>
                      <button onClick={() => handleRemoveRow(index)}>Remove</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            </div>
            <TableButton onClick={handleAddRow}>Add Row</TableButton><br/><br/>
            <input
            type="file"
            accept=".csv, .xlsx, .xls"
            onChange={handleFileUpload}
            style={{ marginBottom: '20px' }}
          /><br/>

            <ButtonContainer>
              <Button onClick={handleBack}>Back</Button>
              <Button1 onClick={handleNext}>Save Portfolio</Button1>
            </ButtonContainer>
            
          </>
        )}
        <CloseButton onClick={handleClose}>Close</CloseButton>
      </ModalContent>
    </ModalContainer>
  ) : null;
};

export default PortfolioModal;
