import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import Header from '../pages/Header'; // Adjust the path based on your project structure
import Footer from '../Components/Common/Footer'; // Adjust the path based on your project structure
import { client } from '../sanityClient'; // Your Sanity client configuration
import imageUrlBuilder from '@sanity/image-url'; // Used to get images from Sanity
import { PortableText } from '@portabletext/react'; // Portable Text renderer

const builder = imageUrlBuilder(client);

const urlFor = (source) => builder.image(source);

const lightTheme = {
  background: "#ffffff",
  color: "#000000",
  cardBackground: "#f5f5f5",
  cardColor: "#000000",
  borderColor: "#000000",
};

const darkTheme = {
  background: "rgb(31 41 55)",
  color: "#ffffff",
  cardBackground: "rgb(55 65 81)",
  cardColor: "#ffffff",
  borderColor: "#ffffff",
};

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: background-color 0.3s, color 0.3s;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top:100px;
`;

const ArticleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow-y: auto; /* Allows vertical scrolling if needed */
  padding: 20px;
  background-color: ${({ theme }) => theme.background};
`;

const ArticleCard = styled.div`
  background-color: ${({ theme }) => theme.cardBackground};
  color: ${({ theme }) => theme.cardColor};
  padding: 20px;
  border-radius: 8px;
  // border: 1px solid ${({ theme }) => theme.borderColor};
  width: 100%;
  max-width: 100vh; /* Adjust based on your design */
  text-align: center;
  box-sizing: border-box; /* Ensures padding and border are included in width */
`;

const Title = styled.h1`
  font-size: 1.5em;
  font-family: 'Lora', serif;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.cardColor};
`;

const ArticleImage = styled.img`
  width: 50%;
  border-radius: 8px;
  margin-bottom: 10px;
`;

const Button = styled.button`
  background-color: ${({ theme }) => theme.cardColor};
  color: ${({ theme }) => theme.background};
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    opacity: 0.8;
  }
`;

const ArticleBody = styled.div`
  text-align: left;
  margin-top: 10px;
`;

const Articles = () => {
  const [posts, setPosts] = useState([]);
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      return savedTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });

  useEffect(() => {
    // Fetch posts from Sanity
    client
      .fetch(
        `*[_type == "post"]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            }
          },
          body
        }`
      )
      .then((data) => setPosts(data))
      .catch(console.error);
  }, []);

  const handleLayoutModeChange = (newMode) => {
    setTheme(newMode === 'light' ? lightTheme : darkTheme);
  };


  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
  <Header
          layoutModeType={theme === lightTheme ? 'light' : 'dark'}
          onChangeLayoutMode={handleLayoutModeChange}
        />
        <ArticleContainer>
          {posts.map((post, index) => (
            <ArticleCard key={index}>
              <Title>{post.title}</Title>
              {post.mainImage && (
                <ArticleImage src={urlFor(post.mainImage).width(400).url()} alt={post.title} />
              )}
              <ArticleBody>
                <PortableText value={post.body} />
              </ArticleBody>
              {/* <Button>Read More</Button> */}
            </ArticleCard>
          ))}
        </ArticleContainer>
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default Articles;
