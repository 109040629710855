import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
// Keyframes for animations
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const flicker = keyframes`
  0% { mask-position: 50% 50%; }
  // 100% { mask-position: -256px 50%; }
`;

// Styled components


const Box1 = styled.div`
  position: absolute;
  width: 180px;
  height: 75px;
  background: #000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  animation: ${fadeIn} 1s ease;
  --size: 180px 75px;
  border: 0.1px solid gray;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);

    &::before { 
      content: "";
      position: absolute;
      inset: 0;
      background: conic-gradient(
        from 360deg at 50% 70%,
        #fc6eec 0deg,
        #fc6eec 72deg,
        #fc6eec 144deg,
        #fc6eec 216deg,
        #fc6eec 288deg,
        #fc6eec 360deg
      );

      width: 100%;
      height: 100%;
      mask: radial-gradient(circle at 50% 50%, #fc6eec 1px, transparent 1.5px) 100% 100% / var(--size) var(--size), 
        url("https://assets.codepen.io/605876/noise-mask.png") 512px 50% / 512px 512px;
      mask-composite: intersect;
      --size: 8px;
      animation: ${flicker} 50s infinite linear;
      transform-origin: center;
      z-index: -1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: conic-gradient(
      from 180deg at 50% 50%, 
      #fc6eec 0deg,
      #fc6eec 60deg,
      #fc6eec 120deg,
      #fc6eec 180deg,
      #fc6eec 240deg,
      #fc6eec 300deg,
      #fc6eec 360deg
    );

    mask: radial-gradient(circle at 50% 50%, #fc6eec 2px, transparent 2.5px) 50% 50% / var(--size),
      url("https://assets.codepen.io/605876/noise-mask.png") 256px 50% / 256px 256px;
    -webkit-mask-composite: intersect;
    mask-composite: intersect;
    animation: ${flicker} 20s infinite linear;
  }
`;

const BoxColumn = styled(Box1)`
  position: relative;
  margin: 15px 0; // Increased from 10px
  animation: ${fadeIn} 0.5s ease;

  &:hover {
    animation: ${pulse} 1s infinite;
  }
`;


const Box2 = styled.div`
  position: absolute;
  width: 180px;
  height: 75px;
  background: #000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  animation: ${fadeIn} 1s ease;
  --size: 180px 75px;
  border: 0.1px solid gray;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);

    &::before { 
      content: "";
      position: absolute;
      inset: 0;
      background: conic-gradient(
        from 360deg at 50% 70%,
        #A6B7AA 0deg,
        #A6B7AA 72deg,
        #A6B7AA 144deg,
        #A6B7AA 216deg,
        #A6B7AA 288deg,
        #A6B7AA 360deg
      );

      width: 100%;
      height: 100%;
      mask: radial-gradient(circle at 50% 50%, #A6B7AA 1px, transparent 1.5px) 100% 100% / var(--size) var(--size), 
        url("https://assets.codepen.io/605876/noise-mask.png") 512px 50% / 512px 512px;
      mask-composite: intersect;
      --size: 8px;
      animation: ${flicker} 50s infinite linear;
      transform-origin: center;
      z-index: -1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: conic-gradient(
      from 180deg at 50% 50%, 
      #A6B7AA 0deg,
      #A6B7AA 60deg,
      #A6B7AA 120deg,
      #A6B7AA 180deg,
      #A6B7AA 240deg,
      #A6B7AA 300deg,
      #A6B7AA 360deg
    );

    mask: radial-gradient(circle at 50% 50%, #A6B7AA 2px, transparent 2.5px) 50% 50% / var(--size),
      url("https://assets.codepen.io/605876/noise-mask.png") 256px 50% / 256px 256px;
    -webkit-mask-composite: intersect;
    mask-composite: intersect;
    animation: ${flicker} 20s infinite linear;
  }
`;


const Box3 = styled.div`
  position: absolute;
  width: 180px;
  height: 75px;
  background: #000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  animation: ${fadeIn} 1s ease;
  --size: 180px 75px;
  border: 0.1px solid gray;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);

    &::before { 
      content: "";
      position: absolute;
      inset: 0;
      background: conic-gradient(
        from 360deg at 50% 70%,
        #D2A96A 0deg,
        #D2A96A 72deg,
        #D2A96A 144deg,
        #D2A96A 216deg,
        #D2A96A 288deg,
        #D2A96A 360deg
      );

      width: 100%;
      height: 100%;
      mask: radial-gradient(circle at 50% 50%, #D2A96A 1px, transparent 1.5px) 100% 100% / var(--size) var(--size), 
        url("https://assets.codepen.io/605876/noise-mask.png") 512px 50% / 512px 512px;
      mask-composite: intersect;
      --size: 8px;
      animation: ${flicker} 50s infinite linear;
      transform-origin: center;
      z-index: -1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: conic-gradient(
      from 180deg at 50% 50%, 
      #D2A96A 0deg,
      #D2A96A 60deg,
      #D2A96A 120deg,
      #D2A96A 180deg,
      #D2A96A 240deg,
      #D2A96A 300deg,
      #D2A96A 360deg
    );

    mask: radial-gradient(circle at 50% 50%, #D2A96A 2px, transparent 2.5px) 50% 50% / var(--size),
      url("https://assets.codepen.io/605876/noise-mask.png") 256px 50% / 256px 256px;
    -webkit-mask-composite: intersect;
    mask-composite: intersect;
    animation: ${flicker} 20s infinite linear;
  }
`;


const Box4 = styled.div`
  position: absolute;
  width: 180px;
  height: 75px;
  background: #000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  animation: ${fadeIn} 1s ease;
  --size: 180px 75px;
  border: 0.1px solid gray;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);

    &::before { 
      content: "";
      position: absolute;
      inset: 0;
      background: conic-gradient(
        from 360deg at 50% 70%,
        #D39D87 0deg,
        #D39D87 72deg,
        #D39D87 144deg,
        #D39D87 216deg,
        #D39D87 288deg,
        #D39D87 360deg
      );

      width: 100%;
      height: 100%;
      mask: radial-gradient(circle at 50% 50%, #D39D87 1px, transparent 1.5px) 100% 100% / var(--size) var(--size), 
        url("https://assets.codepen.io/605876/noise-mask.png") 512px 50% / 512px 512px;
      mask-composite: intersect;
      --size: 8px;
      animation: ${flicker} 50s infinite linear;
      transform-origin: center;
      z-index: -1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: conic-gradient(
      from 180deg at 50% 50%, 
      #D39D87 0deg,
      #D39D87 60deg,
      #D39D87 120deg,
      #D39D87 180deg,
      #D39D87 240deg,
      #D39D87 300deg,
      #D39D87 360deg
    );

    mask: radial-gradient(circle at 50% 50%, #D39D87 2px, transparent 2.5px) 50% 50% / var(--size),
      url("https://assets.codepen.io/605876/noise-mask.png") 256px 50% / 256px 256px;
    -webkit-mask-composite: intersect;
    mask-composite: intersect;
    animation: ${flicker} 20s infinite linear;
  }
`;

const Box5 = styled.div`
  position: absolute;
  width: 180px;
  height: 75px;
  background: #000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  animation: ${fadeIn} 1s ease;
  --size: 180px 75px;
  border: 0.1px solid gray;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);

    &::before { 
      content: "";
      position: absolute;
      inset: 0;
      background: conic-gradient(
        from 360deg at 50% 70%,
        #FF5733 0deg,  /* Bright Orange */
        #FF5733 72deg,
        #33FFEC 144deg, /* Electric Blue */
        #FF33EC 216deg, /* Neon Pink */
        #33FF57 288deg, /* Bright Green */
        #FF5733 360deg
      );

      width: 100%;
      height: 100%;
      mask: radial-gradient(circle at 50% 50%, #FF5733 1px, transparent 1.5px) 100% 100% / var(--size) var(--size), 
        url("https://assets.codepen.io/605876/noise-mask.png") 512px 50% / 512px 512px;
      mask-composite: intersect;
      --size: 8px;
      animation: ${flicker} 50s infinite linear;
      transform-origin: center;
      z-index: -1;
    }
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: conic-gradient(
      from 180deg at 50% 50%, 
      #FF5733 0deg,
      #33FFEC 60deg,
      #FF33EC 120deg,
      #33FF57 180deg,
      #FFD700 240deg, /* Bright Yellow */
      #FF5733 300deg,
      #FF5733 360deg
    );

    mask: radial-gradient(circle at 50% 50%, #FF5733 2px, transparent 2.5px) 50% 50% / var(--size),
      url("https://assets.codepen.io/605876/noise-mask.png") 256px 50% / 256px 256px;
    -webkit-mask-composite: intersect;
    mask-composite: intersect;
    animation: ${flicker} 2s infinite linear;
  }
`;



const ContentBox = styled.div`
  flex: 1;
  // background: linear-gradient(145deg, #ffffff, #f0f0f0);
  padding: 30px; // Increased from 20px
  border-radius: 20px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: ${fadeIn} 0.5s ease;
  // max-width: 300px;
  // color:#000;
  font-size:1.5em;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: row; /* Ensure horizontal layout */
  width: 700px;
  margin: auto;
  transition: all 0.3s;
  animation: ${fadeIn} 0.5s ease;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px; // Increased from 10px
  margin-left: 75px; // Increased from 50px
`;

const draw = keyframes`
  to {
    stroke-dashoffset: 2;
  }
`;


const Title = styled.h3`
  font-size: 2.5em;
  font-weight: 500;
  color: #fff;
  font-family: Montserrat,Helvetica,Arial,sans-serif;
`;

const Container = styled.div`
  position: relative;
  width: 600px;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  overflow: hidden;

  @media (max-width: 768px) {
    // width: 100%;
    height: auto;
    padding: 20px;
  }
`;

const AnimatedPath = ({ d, stroke, strokeWidth, strokeDasharray, $length }) => {
  return (
    <motion.path
      d={d}
      stroke={stroke}
      strokeWidth={strokeWidth}
      fill="none"
      strokeDasharray={strokeDasharray}
      initial={{ strokeDashoffset: $length, opacity: 1 }}
      animate={{ strokeDashoffset: 0, opacity: 0 }}
      transition={{ duration: 5, ease: "easeInOut" }}
    />
  );
};

const MovingDotsPath = ({ d, stroke }) => {
  return (
    <motion.path
      d={d}
      stroke={stroke}
      strokeWidth="1"
      fill="none"
      strokeDasharray="4 12"
      strokeDashoffset="0"
      initial={{ opacity: 1 }}
      animate={{
        strokeDashoffset: [-48, 0], 
        opacity: [1, 1, 0],
      }}
      transition={{
        repeat: Infinity, 
        duration: 3, 
        ease: "linear",
      }}
    />
  );
};
const boxData = {
  Financial: { color: '#fc6eec', position: { x: 300, y: 52.5 }, connectsTo: 'Economic' },
  Technical: { color: '#A6B7AA', position: { x: 120, y: 187.5 }, connectsTo: 'Financial' },
  Economic: { color: '#D2A96A', position: { x: 480, y: 187.5 }, connectsTo: 'Political' },
  Social: { color: '#D39D87', position: { x: 150, y: 337.5 }, connectsTo: 'Technical' },
  Political: { color: '#BB7154', position: { x: 450, y: 337.5 }, connectsTo: 'Social' }
};


const moveDots = keyframes`
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -6;
  }
`;

const CircularLine = styled(motion.path)`
  stroke: rgba(255, 255, 255, 0.3);
  stroke-width: 1;
  fill: none;
  stroke-dasharray: 0;
  // animation: ${moveDots} 3s linear infinite;
`;

const MovingDot = styled(motion.circle)`
  fill: red;
  stroke: red;
  r: 3; /* Dot size */
`;



const FiveSectionDisplay = () => {
  const [selected, setSelected] = useState(null);
  const [hoveredBox, setHoveredBox] = useState(null);

  const calculateLineLength = (start, end) => {
    const dx = end.x - start.x;
    const dy = end.y - start.y;
    return Math.sqrt(dx * dx + dy * dy);
  };


  const content = {
    Financial: "The financial model analyzes key metrics such as revenue, profitability, cash flow, debt levels, and market valuation. It incorporates historical financial statements, real-time market data, and risk indicators like credit ratings and volatility measures. By evaluating financial stability and trends, this model helps determine whether a business, investment, or sector is financially vulnerable or poised for growth, feeding directly into the overarching risk and opportunity assessment.",
    Technical: "The technical model focuses on data-driven trends, price movements, and statistical patterns within financial markets. It utilizes historical price data, momentum indicators, trading volume, and algorithmic signals to predict future movements. By identifying market trends, overbought or oversold conditions, and sentiment shifts, the technical model enhances the broader assessment of whether specific investments or sectors face potential risk or opportunity.",
    Economic: "The economic model examines macroeconomic and microeconomic factors, including GDP growth, inflation, interest rates, employment figures, and consumer spending patterns. It integrates real-time economic reports, central bank policies, and trade data to assess economic conditions and forecast potential shifts. By identifying economic headwinds or tailwinds, this model informs the larger system on whether broader market conditions present risks or opportunities.",
    Social: "The social model evaluates consumer behavior, demographic trends, and public sentiment using data from social media, surveys, cultural shifts, and brand reputation analyses. It incorporates real-time consumer insights, sentiment analysis, and trend forecasting to assess market adaptability and societal impact. This model helps the larger system determine whether changing social dynamics present emerging risks or opportunities for industries, businesses, or investments.",
    Political: "The political model analyzes regulatory changes, government policies, geopolitical risks, and legislative developments. It integrates legal frameworks, policy decisions, global trade relations, and political stability indicators. By assessing how political events and regulations impact industries and markets, this model contributes to the overall evaluation of risks and opportunities, ensuring strategic decision-making aligns with evolving political landscapes.",
  };

  const handleClick = (section, event) => {
    event.stopPropagation();
    setSelected(section);
  };

  const handleOutsideClick = (e) => {
    if (!e.target.closest(".box")) {
      setSelected(null);
    }
  };

  const drawCurvedLine = (start, end) => {
    const midX = (start.x + end.x) / 2;
    const midY = (start.y + end.y) / 2;
    const controlPoint = { x: midX, y: midY - 100 };

    return `M ${start.x} ${start.y} Q ${controlPoint.x} ${controlPoint.y} ${end.x} ${end.y}`;
  };

  const dotVariants = {
    animate: {
      pathLength: [0, 1], // Move along the path
      transition: {
        duration: 3,
        repeat: Infinity,
        ease: "linear",
      },
    },
  };
  
  return (
  <div onClick={handleOutsideClick}>
    {selected ? (
      <Layout>
        <ContentBox>
          <Title>{selected}</Title>
          <p>{content[selected]}</p>
        </ContentBox>
        <Column>
          {Object.keys(content).map((key) => (
            <BoxColumn key={key} onClick={(e) => handleClick(key, e)} className="box">
              {key}
            </BoxColumn>
          ))}
        </Column>
      </Layout>
    ) : (
      <Container>
        <svg width="600" height="450">
          {/* Circular/Pentagon Path */}
          <CircularLine 
             d="M 300 40 
             C 425 40, 535 150, 475 260
             C 415 370, 185 370, 125 260
             C 65 150, 175 40, 300 40 Z"
          />

          {/* Moving Bus (5 connected dots) */}
          <motion.g>
  {[0, 0.008, 0.016, 0.024, 0.032, 0.040, 0.048, 0.056, 0.064, 0.072,0.076,0.080,0.084,0.088,0.092,0.096,0.100,0.104,0.108,0.112,0.120,0.128,0.136,0.144,0.152,0.160].map((offset, index) => (
    <g key={index}>
      {/* <motion.circle
        cx="0"
        cy="0"
        r="2"
        fill={index === 0 ? "#4a90e2" : "#4a90e2"}
      >
        <animateMotion
          repeatCount="indefinite"
          dur="1.6s" // Faster animation to match tighter spacing
          keyPoints="0;1"
          keyTimes="0;1"
          calcMode="linear"
          begin={`${offset}s`}
        >
          <mpath href="#path" />
        </animateMotion>
      </motion.circle> */}
      {/* {index > 0 && ( */}
        <motion.line
          x1="0" y1="0" x2="0" y2="0"
          stroke="#4a90e2"
          strokeWidth="2"
          strokeLinecap="round"
        >
          <animateMotion
            repeatCount="indefinite"
            dur="4.6s"
            keyPoints="0;1"
            keyTimes="0;1"
            begin={`${offset}s`}
          >
            <mpath href="#path" />
          </animateMotion>
          {/* <animateMotion
            repeatCount="indefinite"
            dur="4.6s"
            keyPoints="0;1"
            keyTimes="0;1"
            begin={`${offset - 0.004}s`} // Tight spacing
          >
            <mpath href="#path" />
          </animateMotion> */}
        </motion.line>
      {/* )} */}
    </g>
  ))}
</motion.g>

          {/* Hidden Path for Motion Reference */}
          <path
            id="path"
            d="M 300 40 
            C 425 40, 535 150, 475 260
            C 415 370, 185 370, 125 260
            C 65 150, 175 40, 300 40 Z"
            fill="none"
            stroke="transparent"
          />
        </svg>

        {Object.entries(boxData).map(([key, { position }]) => {
          const BoxComponent = {
            Financial: Box1,
            Technical: Box2,
            Economic: Box3,
            Social: Box4,
            Political: Box5,
          }[key] || Box1;

          return (
            <div
              key={key}
              className="box"
              style={{
                position: 'absolute',
                top: position.y - 37.5,
                left: position.x - 90,
              }}
              onMouseEnter={() => setHoveredBox(key)}
              onMouseLeave={() => setHoveredBox(null)}
              onClick={(e) => handleClick(key, e)}
            >
              {React.createElement(BoxComponent, null, key)}
            </div>
          );
        })}
      </Container>
    )}
  </div>
);
  
  
}  
export default FiveSectionDisplay;

