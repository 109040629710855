import { useEffect } from "react";

const CrispChat = () => {
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "c2d1f038-f005-4e86-8a2a-e9074be39c6f";

    (function () {
      const d = document;
      const s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    // Hide Crisp default button
    window.$crisp.push(["config", "hide:on:desktop"]);
    window.$crisp.push(["config", "hide:on:mobile"]);
  }, []);

  return null;
};

export default CrispChat;
