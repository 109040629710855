import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import Header from '../../pages/Header/index'; // Ensure this path is correct
import Footer from '../../Components/Common/Footer';

import backGround from '../../assets/images/vegaveta/bg.jpg';
import serviceImage1 from '../../assets/images/vegaveta/services/comprensives1.jpg';
import serviceImage2 from '../../assets/images/vegaveta/compr2.jpg';

const lightTheme = {
  // background: "#ffffff",
  color: "#000000",
  // cardBackground: "#f5f5f5",
  cardColor: "#000000",
  borderColor: "#000000",
  titleColor: "#AC544C",
  textColor: "#333333",
  backgroundImage: `#fff`,
};

const darkTheme = {
  // background: "#ffffff",
  color: "#000000",
  // cardBackground: "#f5f5f5",
  cardColor: "#000000",
  borderColor: "#000000",
  titleColor: "#AC544C",
  textColor: "#333333",
  backgroundImage: `#fff`,
};

const GlobalStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.backgroundImage};
    color: ${({ theme }) => theme.color};
    transition: background-color 0.3s, color 0.3s;
  }
`;


const Card = styled.div`

  display: flex;
backgroundImage: backGround,
  color: ${({ theme }) => theme.cardColor};
  // border-radius: 8px;
  padding: 0;
  margin-top:100px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  // border: 1px solid ${({ theme }) => theme.cardColor};

  @media (max-width: 1000px) {
    flex-direction: column;
    width: calc(100% - 20px);
    height: fit-content;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: calc(50% - 20px);
  }

  @media (max-width: 480px) {
    flex-direction: column;
    width: calc(100% - 20px);
    height: fit-content;
  }
`;

const CardImage = styled.img`
  width: 45%;
  height: 600px;
  object-fit: cover;
  margin:50px;
  
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  text-align: center;
  width: 55%;
  font-size: 1.5em;
`;
const CardTitle = styled.h3`
  font-size: 1.8em;
  color: ${({ theme }) => theme.color};
  font-weight: bold;
  font-family: 'Lora', serif;
`;



const Subref2 = styled.div`
  display: flex; /* Enable flexbox */
  justify-content: center; /* Center columns horizontally */
  align-items: center; /* Center content vertically */
  gap: 20px; /* Space between columns */
  font-size: 2em;
  margin: 70px auto; /* Center horizontally and add vertical spacing */
  padding: 50px 200px;
  color: ${({ theme }) => theme.color};
  background: #ac544c;
  width: 100%; /* Adjust as needed for responsive layout */

  @media (max-width: 768px) {
    flex-direction: column; /* Stack columns vertically on small screens */
    width: 100%;
    font-size: 1.2em;
    margin: 20px auto;
    padding: 20px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column; /* Stack items vertically within each column */
  align-items: center; /* Center horizontally within the column */
  text-align: center;
  width:40%;
`;
const Column1 = styled.div`
  display: flex;
  flex-direction: column; /* Stack items vertically within each column */
  align-items: center; /* Center horizontally within the column */
  text-align: center;
  width:60%;
`;

const Text = styled.p`
  width: 100%;
  text-align: center;
//   margin-bottom: 20px; /* Add spacing between text and button */
`;

const StyledButton = styled.button`
  display: flex;
  background-color: ${({ theme }) => theme.color};
  color: #fff;
  border: none;
  padding: 20px 60px;
  border-radius: 50px; /* Adjust for full rounding */
  cursor: pointer;
  font-size: 0.7em;
  justify-content: center;
  align-items: center;

  &:hover {
    // background-color: ${({ theme }) => theme.buttonHoverBackground};
  }`;

const Comprehensive = () => {
  const [theme, setTheme] = useState(() => {
    try {
      const savedTheme = localStorage.getItem('theme');
      const cleanTheme = savedTheme.replace(/^"|"$/g, '');
      return cleanTheme === 'dark' ? darkTheme : lightTheme;
    } catch (error) {
      console.error('Error reading theme from localStorage', error);
      return lightTheme; // Default theme
    }
  });

  

  // useEffect(() => {
  //   const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
  //   setTheme(mediaQuery.matches ? darkTheme : lightTheme);
  //   const handleChange = (e) => setTheme(e.matches ? darkTheme : lightTheme);
  //   mediaQuery.addEventListener("change", handleChange);
  //   return () => mediaQuery.removeEventListener("change", handleChange);
  // }, []);

  

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />

        <Card>
            <CardContent>
              <CardTitle>Comprehensive Company-Level Fundamental Analysis</CardTitle>
              <p>Uncover a company's true intrinsic and relative value through a methodical and data-driven approach. Our analysis delves into key financial metrics, including revenue, earnings, cash flow, and balance sheet strength, providing a clear picture of financial health and long-term viability. By evaluating profitability, debt levels, operational efficiency, and industry position, we help investors make informed decisions based on a company's fundamental strengths, ensuring a solid foundation for strategic investment planning.</p>
            </CardContent>
            <CardImage src={serviceImage1} alt="administrator, agency, aggregation, banking, broker, business, buyer, capital, colleague,
computer, confident, control, data, dispatch, display, diversity, economy, employee,
equity, female, finance, graph, information, intelligence, internet, job, leader, man,
manager, monitor, monitoring, office, operator, people, professional, public, room,
screen, server, service, share, specialist, stock market, support, team,
telecommunications, trading, visualization, woman, work" />
          </Card>

          {/* <Card>
          <CardImage src={serviceImage2} alt="Image 1" />
            <CardContent>
              <CardTitle>Comprehensive Company-Level Fundamental Analysis</CardTitle>
              <p>Discover a company’s intrinsic value with a methodical approach examining revenue, earnings, cash flow, and balance sheet strength.</p>
            </CardContent>
            
          </Card>               */}
      {/* <Subref2>
        <Column>
      <Text>Dive in to a new financial analysis with a free trial.</Text>
      </Column>
      <Column1>
            <StyledButton>Start your free trial</StyledButton>
            </Column1>
          </Subref2> */}
          <div style={{position:"fixed",bottom:"0px",width:"100%"}}>
          <Footer/>
          </div>
      
    </ThemeProvider>
  );
};

export default Comprehensive;
