import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Chart, registerables } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { supabase } from '../supabaseClient';
import Header from './Header';
import { useAuth } from "@clerk/clerk-react";
Chart.register(...registerables);

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

const lightTheme = {
  background: '#f0f0f0',
  color: '#222',
  primary: '#AC544C',
  cardColor: '#fff',
};

const darkTheme = {
  background: 'rgb(31 41 55)',
  color: '#f0f0f0',
  primary: '#AC544C',
  cardColor: 'rgb(55 65 81)',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 100px;
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Card = styled.div`
  width: 33%;
  background-color: ${({ theme }) => theme.cardColor};
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  overflow-x: auto;
  &:hover {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.primary};
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 1.5em;
`;

const IncomeStatement = () => {

    // const getTokenInfo = async () => {
        const { getToken } = useAuth();
getToken( {template: "supabase"}).then(token => {
  console.log("Your Clerk JWT:", token);
}).catch(error => {
  console.error("Error fetching token:", error);
});
    //   };

  const [theme, setTheme] = useState(lightTheme);
  const [incomeData, setIncomeData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [geoData, setGeoData] = useState([]);
  const [symbol, setSymbol] = useState('AAPL');

  const [productChartData, setProductChartData] = useState(null);
  const [geoChartData, setGeoChartData] = useState(null);
  const [incomeChartData, setIncomeChartData] = useState(null);
  const [revenueTrendData, setRevenueTrendData] = useState(null);
  const [researchData, setResearchData] = useState(null);
  const [sellsData, setSellsData] = useState(null);
  const [expenseData, setExpenseData] = useState(null);
  const [interestData, setInterestData] = useState(null);
  const [nonOperatingIncomeData, setNonOperatingIncomeData] = useState(null);
  const [ebitdaData, setEbitdaData] = useState(null);
  const [operatingIncomeData, setOperatingIncomeData] = useState(null);
  const [totalOtherIncomeExpensesNetData, setTotalOtherIncomeExpensesNetData] = useState(null);
  const [incomeBeforeTaxData, setIncomeBeforeTaxData] = useState(null);
  const [incomeTaxExpenseData, setIncomeTaxExpenseData] = useState(null);
  const [deprciationData, setDeprciationData] = useState(null);
  const [netIncomeData, setNetIncomeData] = useState(null);
  const [revenueGrossProfitData, setRevenueGrossProfitData] = useState(null);

  const fetchIncomeData = async () => {
    const { data, error } = await supabase
      .from('IncomeStatement')
      .select('*')
      .eq('symbol', symbol)
      .gte('fiscalYear', 2019)
      .lte('fiscalYear', 2025)
      .eq('period', 'FY');
    if (error) console.error('Error fetching income data:', error);
    else setIncomeData(data || []);
  };

  const fetchRevenueData = async () => {
    try {
      const productRes = await fetch(
        `https://financialmodelingprep.com/stable/revenue-product-segmentation?symbol=${symbol}&period=annual&apikey=11cd608ea5136b9fdb748e3a80c1724e`
      );
      const productJson = await productRes.json();
      setProductData(Array.isArray(productJson) ? productJson : []);

      const geoRes = await fetch(
        `https://financialmodelingprep.com/stable/revenue-geographic-segmentation?symbol=${symbol}&period=annual&apikey=11cd608ea5136b9fdb748e3a80c1724e`
      );
      const geoJson = await geoRes.json();
      setGeoData(Array.isArray(geoJson) ? geoJson : []);
    } catch (error) {
      console.error('Error fetching revenue data:', error);
    }
  };

  useEffect(() => {
    fetchIncomeData();
    fetchRevenueData();
  }, [symbol]);

  
  const transformSellsData = (data) => {
    const labels = data.map((item) => item.date || '');
    const datasets = [
      {
        label: 'General & Administrative Expenses',
        data: data.map((item) => item.generalAndAdministrativeExpenses || 0),
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
      },
      {
        label: 'Selling & Marketing Expenses',
        data: data.map((item) => item.sellingAndMarketingExpenses || 0),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Selling, General & Administrative Expenses',
        data: data.map((item) => item.sellingGeneralAndAdministrativeExpenses || 0),
        borderColor: 'rgba(75, 192, 192, 1)',
        type: 'line',
        fill: false,
      },
    ];
    return { labels, datasets };
  };

  const transformExpenseData = (data) => {
    const labels = data.map((item) => item.date || '');
    const datasets = [
      {
        label: 'Other Expenses',
        data: data.map((item) => item.otherExpenses || 0),
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        yAxisID: 'y-left',
      },
      {
        label: 'Operating Expenses',
        data: data.map((item) => item.operatingExpenses || 0),
        borderColor: 'rgba(75, 192, 192, 1)',
        type: 'line',
        fill: false,
        yAxisID: 'y-right', 
      },
      {
        label: 'Cost and Expenses',
        data: data.map((item) => item.costAndExpenses || 0),
        borderColor: 'rgba(255, 99, 132, 1)',
        type: 'line',
        fill: false,
        yAxisID: 'y-right', 
      },
    ];
    return { labels, datasets };
  };
  const transformEbitdaData = (data) => {
    const labels = data.map((item) => item.date || '');
    const datasets = [
      {
        label: 'Ebitda',
        data: data.map((item) => item.ebitda || 0),
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        type: 'line',
        fill: false,
      },
      
    ];
    return { labels, datasets };
  };
  const expenseChartOptions = {
    scales: {
        'y-left': {
          type: 'linear',
          position: 'left',
          beginAtZero: true,
        },
        'y-right': {
          type: 'linear',
          position: 'right',
          beginAtZero: true,
        },
      },
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: false,
        },
        legend: {
          display: true,
          position: 'top',
        },
      },
  };

   
  const transformInterestsData = (data) => {
    const labels = data.map((item) => item.date || '');
    const datasets = [
      
      {
        label: 'Interest Income',
        data: data.map((item) => item.interestIncome || 0),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y-left', 
      },
      {
        label: 'Interest Expense',
        data: data.map((item) => item.interestExpense || 0),
        borderColor: 'rgba(75, 192, 192, 1)',        
      },
      {
        label: 'Net Interest Income',
        data: data.map((item) => item.netInterestIncome || 0),
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        type: 'line',
        fill: false,
        yAxisID: 'y-right',
      },
    ];
    return { labels, datasets };
  };

  const transformNonOperatingIncome = (data) => {
    const labels = data.map((item) => item.date || '');
    const datasets = [
      {
        label: 'Net Interest Income',
        data: data.map((item) => item.nonOperatingIncomeExcludingInterest || 0),
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        type: 'line',
        fill: false,        
      },
      
    ];
    return { labels, datasets };
  };
  const transformOtherData = (data) => {
    setOperatingIncomeData({
        labels : data.map((item) => item.date || ''),
        datasets : [
          {
            label: 'Operating Income',
            data: data.map((item) => item.operatingIncome || 0),
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            type: 'line',
            fill: false,        
          },          
        ]
    })
    setTotalOtherIncomeExpensesNetData({
        labels : data.map((item) => item.date || ''),
        datasets : [
          {
            label: 'Operating Income',
            data: data.map((item) => item.totalOtherIncomeExpensesNet || 0),
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            // type: 'line',
            // fill: false,        
          },          
        ]
    })

    setDeprciationData({
      labels : data.map((item) => item.date || ''),
      datasets : [
        {
          label: 'Depreciation and Amortization',
          data: data.map((item) => item.depreciationAndAmortization || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          yAxisID: 'y-left', 
          // type: 'line',
          // fill: false,        
        },          
        {
          label: 'Ebit',
          data: data.map((item) => item.ebit || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          type: 'line',
          fill: false,        
          yAxisID: 'y-right', 
        },          
      ]
    })

    setIncomeBeforeTaxData({
      labels : data.map((item) => item.date || ''),
      datasets : [
        {
          label: 'Income before tax',
          data: data.map((item) => item.incomeBeforeTax || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          type: 'line',
          fill: false,        
        },          
      ]
    })

    setIncomeTaxExpenseData({
      labels : data.map((item) => item.date || ''),
      datasets : [
        {
          label: 'Income Tax Expense',
          data: data.map((item) => item.incomeTaxExpense || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          type: 'line',
          fill: false,        
        },          
      ]
    })
    setNetIncomeData({
      labels : data.map((item) => item.date || ''),
      datasets : [
        {
          label: 'Net Income',
          data: data.map((item) => item.netIncome || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          type: 'line',
          fill: false,        
        },          
      ]
    })

    setRevenueGrossProfitData({
      labels : data.map((item) => item.date || ''),
      datasets : [
        {
          label: 'Cost of Revenue',
          data: data.map((item) => item.costOfRevenue || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          type: 'line',
          fill: false,        
        },
        {
          label: 'Gross Profit',
          data: data.map((item) => item.grossProfit || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          type: 'line',
          fill: false,        
        },          
      ]
    })
    return {};
  }
  useEffect(() => {
    if (productData.length > 0) {
      setProductChartData(transformProductData(productData));
    }
    if (geoData.length > 0) {
      setGeoChartData(transformGeoData(geoData));
    }
    if (incomeData.length > 0) {
      setIncomeChartData(transformIncomeData(incomeData));
      setSellsData(transformSellsData(incomeData));
      setExpenseData(transformExpenseData(incomeData));
      setInterestData(transformInterestsData(incomeData));
      setNonOperatingIncomeData(transformNonOperatingIncome(incomeData));
      setEbitdaData(transformEbitdaData(incomeData));
      transformOtherData(incomeData);
      setRevenueTrendData({
        labels: incomeData.map((d) => d.date || ''),
        datasets: [{
          label: 'Revenue',
          data: incomeData.map((d) => d.revenue || 0),
          fill: false,
          borderColor: theme.primary,
        }],
      });
      setResearchData({
        labels: incomeData.map((d) => d.date || ''),
        datasets: [{
          label: 'Research and Development',
          data: incomeData.map((d) => d.researchAndDevelopmentExpenses || 0),
        //   fill: false,
        //   borderColor: theme.primary,
        }],
      });

      
    }
  }, [productData, geoData, incomeData, theme]);

  const handleThemeChange = () => {
    setTheme(theme === lightTheme ? darkTheme : lightTheme);
  };

  const transformGeoData = (geoData) => {
    if (!geoData?.length) return null;

    const labels = geoData.map((item) => item.date || '');
    const datasets = [
      { label: 'Americas', data: geoData.map((item) => item.data?.['Americas Segment'] || 0), backgroundColor: '#00E396' },
      { label: 'Europe', data: geoData.map((item) => item.data?.['Europe Segment'] || 0), backgroundColor: '#FEB019' },
      { label: 'Greater China', data: geoData.map((item) => item.data?.['Greater China Segment'] || 0), backgroundColor: '#FF4560' },
      { label: 'Japan', data: geoData.map((item) => item.data?.['Japan Segment'] || 0), backgroundColor: '#775DD0' },
    ];
    return { labels, datasets };
  };

  const transformProductData = (productData) => {
    if (!productData?.length) return null;

    const labels = productData.map((item) => item.date || '');
    const products = Object.keys(productData[0]?.data || {});
    const datasets = products.map((product, index) => ({
      label: product,
      data: productData.map((item) => item.data?.[product] || 0),
      backgroundColor: ['#00E396', '#FEB019', '#FF4560', '#775DD0'][index % 4],
    }));

    return { labels, datasets };
  };

  const transformIncomeData = (incomeData) => {
    if (!incomeData?.length) return null;

    const labels = incomeData.map((item) => item.date || '');
    const datasets = [
      { label: 'Operating Expenses', data: incomeData.map((item) => item.operatingExpenses || 0), backgroundColor: '#00E396' },
      { label: 'R&D Expenses', data: incomeData.map((item) => item.researchAndDevelopmentExpenses || 0), backgroundColor: '#FEB019' },
      { label: 'Net Income', data: incomeData.map((item) => item.netIncome || 0), backgroundColor: '#008FFB' },
    ];
    return { labels, datasets };
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header layoutModeType={theme === lightTheme ? 'light' : 'dark'} onChangeLayoutMode={handleThemeChange} />
        <MainContent>
          <Card >
            <Title>Revenue Trend</Title>
            {revenueTrendData && <Line data={revenueTrendData} />}
          </Card>
          <Card>
            <Title>Revenue by Product</Title>
            {productChartData && <Bar data={{ labels: productChartData.labels, datasets: productChartData.datasets }} />}
          </Card>
          <Card >
            <Title>Cost of Revenue and Gross Profit</Title>
            {revenueGrossProfitData && (
              <Bar data={{ labels: revenueGrossProfitData.labels, datasets: revenueGrossProfitData.datasets }}/>
            )}
          </Card>
          <Card>
          
            <Title>Revenue by Geography</Title>
            {geoChartData && <Bar data={{ labels: geoChartData.labels, datasets: geoChartData.datasets }} />}
          </Card>
          <Card>
            <Title>Expenses and Net Income</Title>
            {incomeChartData && <Bar data={{ labels: incomeChartData.labels, datasets: incomeChartData.datasets }} />}
          </Card>
          <Card >
            <Title>Research and Development Trend</Title>
            {researchData && <Bar data={{ labels: researchData.labels, datasets: researchData.datasets }} />}
          </Card>
          <Card >
            <Title>Expenses Breakdown</Title>
            {sellsData && (
              <Bar data={{ labels: sellsData.labels, datasets: sellsData.datasets }} />
            )}
          </Card>
          <Card >
            <Title>Expenses Analysis</Title>
            {expenseData && (
              <Bar data={{ labels: expenseData.labels, datasets: expenseData.datasets }} options={expenseChartOptions} />
            )}
          </Card>
          <Card >
            <Title>Interest Breakdown</Title>
            {interestData && (
              <Bar data={{ labels: interestData.labels, datasets: interestData.datasets }} options={expenseChartOptions}/>
            )}
          </Card>
          <Card >
            <Title>Non Operating Income Expense Breakdown</Title>
            {interestData && (
              <Bar data={{ labels: nonOperatingIncomeData.labels, datasets: nonOperatingIncomeData.datasets }}/>
            )}
          </Card>
          <Card >
            <Title>Ebitda</Title>
            {ebitdaData && (
              <Bar data={{ labels: ebitdaData.labels, datasets: ebitdaData.datasets }}/>
            )}
          </Card>
          <Card >
            <Title>Depreciation</Title>
            {interestData && (
              <Bar data={{ labels: deprciationData.labels, datasets: deprciationData.datasets }} options={expenseChartOptions}/>
            )}
          </Card>
          <Card >
            <Title>Operating Income</Title>
            {operatingIncomeData && (
              <Bar data={{ labels: operatingIncomeData.labels, datasets: operatingIncomeData.datasets }}/>
            )}
          </Card>
          <Card >
            <Title>Total Other Income Expense</Title>
            {totalOtherIncomeExpensesNetData && (
              <Bar data={{ labels: totalOtherIncomeExpensesNetData.labels, datasets: totalOtherIncomeExpensesNetData.datasets }}/>
            )}
          </Card>
          <Card >
            <Title>Income Before Tax</Title>
            {incomeBeforeTaxData && (
              <Bar data={{ labels: incomeBeforeTaxData.labels, datasets: incomeBeforeTaxData.datasets }}/>
            )}
          </Card>
          <Card >
            <Title>Income Tax Expense</Title>
            {incomeTaxExpenseData && (
              <Bar data={{ labels: incomeTaxExpenseData.labels, datasets: incomeTaxExpenseData.datasets }}/>
            )}
          </Card>
          <Card >
            <Title>Net Income</Title>
            {netIncomeData && (
              <Bar data={{ labels: netIncomeData.labels, datasets: netIncomeData.datasets }}/>
            )}
          </Card>
        </MainContent>
      </Container>
    </ThemeProvider>
  );
};

export default IncomeStatement;