import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

// Register necessary components
Chart.register(...registerables);

const GDPChartData = ({ data }) => {
  // Sort data by year
  data = data.sort((a, b) => a.Year - b.Year);
  const years = [...new Set(data.map(item => item.Year))]; // Unique years

  // Extract NGDP_R and NGDP values
  const ngdpRData = years.map(year => {
    const item = data.find(d => d['WEO Subject Code'] === 'NGDP_R' && d.Year === year);
    return item ? item.Value : 0;
  });

  const ngdpData = years.map(year => {
    const item = data.find(d => d['WEO Subject Code'] === 'NGDP' && d.Year === year);
    return item ? item.Value : 0;
  });

  // Calculate min/max values to align both axes
  const allValues = [...ngdpRData, ...ngdpData];
  const minValue = Math.min(...allValues);
  const maxValue = Math.max(...allValues);

  const chartData = {
    labels: years,
    datasets: [
      {
        label: 'Real GDP',
        data: ngdpRData,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        hoverBackgroundColor: 'black',
        yAxisID: 'y',
      },
      {
        label: 'GDP',
        data: ngdpData,
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        hoverBackgroundColor: 'black',
        yAxisID: 'y1',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        type: 'linear',
        position: 'left',
        title: {
          display: true,
          text: 'Real GDP (In billions)',
        },
        suggestedMin: minValue,
        suggestedMax: maxValue,
        ticks: {
          stepSize: Math.ceil((maxValue - minValue) / 8), // 7 ticks (min + 6 steps)
        },
      },
      y1: {
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'GDP (In billions)',
        },
        suggestedMin: minValue,
        suggestedMax: maxValue,
        ticks: {
          stepSize: Math.ceil((maxValue - minValue) / 8), // Same stepSize for consistency
        },
        grid: {
          drawOnChartArea: false, // Prevents grid overlap
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default GDPChartData;
