import React, { useState } from "react";
import { useSignUp, useClerk } from "@clerk/clerk-react";
import styled from "styled-components";
import CountryCitySelect from "./CountryCitySelect";
import logo from "../../assets/images/vegaveta/logo_tp.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


function SignUpModalComp({ onClose }) {
  const { signUp, isLoaded, setActive } = useSignUp();
  const { clerk } = useClerk();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [otp, setOtp] = useState("");
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [gender, setGender] = useState("");
  const [occupation, setOccupation] = useState("");
  const [company, setCompany] = useState("");
  const [dob, setDob] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    if (!isLoaded) return;

    try {
      const result = await signUp.create({
        firstName,
        lastName,
        emailAddress: email,
        password,
        unsafeMetadata: {
          country,
          city,
          gender,
          occupation,
          company,
          dob,
        },
      });

      await signUp.prepareEmailAddressVerification({ strategy: "email_code" });
      setShowOtpForm(true);
    } catch (err) {
      console.error("Signup error:", err);
      if (err.errors) {
        setError(err.errors.map((e) => e.long_message || e.message).join(", "));
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    if (!isLoaded) return;

    try {
      const result = await signUp.attemptEmailAddressVerification({ code: otp });

      if (result.status === "complete") {
        setActive({ session: result.createdSessionId });
        onClose();
      } else {
        console.log("Verification status:", result.status);
      }
    } catch (err) {
      console.error("OTP verification error:", err);
      setError("Invalid OTP. Please try again.");
    }
  };

  return (
    <Overlay>
      <Modal>
        <ModalHeader>
          <Title>{showOtpForm ? "Verify Email" : "Create your account"}</Title>
          <CloseButton onClick={onClose}>×</CloseButton>
        </ModalHeader>
        {!showOtpForm ? (
          <>
            <WelcomeMessage>Welcome! Please fill in the details to get started.</WelcomeMessage>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Form onSubmit={handleSubmit}>
              <FormRow>
                <FormGroup>
                  <Input
                    type="text"
                    placeholder="First name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  {errors.firstName && <ErrorText>{errors.firstName}</ErrorText>}
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    placeholder="Last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  {errors.lastName && <ErrorText>{errors.lastName}</ErrorText>}
                </FormGroup>
              </FormRow>

              <FormRow>
                <FormGroup>
                  <Input
                    type="email"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  {errors.emailAddress && <ErrorText>{errors.emailAddress}</ErrorText>}
                </FormGroup>
                <FormGroup>
                  <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  {errors.password && <ErrorText>{errors.password}</ErrorText>}
                </FormGroup>
              </FormRow>

              <FormRow>
                <FormGroup>
                  <Input
                    type="tel"
                    placeholder="Phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />              
                  {errors.phoneNumber && <ErrorText>{errors.phoneNumber}</ErrorText>}
                </FormGroup>
                <FormGroup>
                  <Select value={gender} onChange={(e) => setGender(e.target.value)}>
                    <option value="">Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                    <option value="prefer_not_to_say">Prefer not to say</option>
                  </Select>
                </FormGroup>
              </FormRow>

              <FormRow>
                <FormGroup>
                  <Input
                    type="text"
                    placeholder="Occupation"
                    value={occupation}
                    onChange={(e) => setOccupation(e.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    placeholder="Company name"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </FormGroup>
              </FormRow>

              <FormRow>
                <FormGroup>
                <DatePickerWrapper>
                  <DatePicker
                    selected={dob}
                    onChange={(date) => setDob(date)}
                    placeholderText="Date of Birth"
                  />
                </DatePickerWrapper>

                  {/* <Input
                    type="date"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => !e.target.value && (e.target.type = "text")}
                    placeholder="Date of birth"
                  /> */}
                </FormGroup>
                <FormGroup>
                  <CountryCitySelect
                    onCountryChange={(selectedCountry) => setCountry(selectedCountry)}
                    onCityChange={(selectedCity) => setCity(selectedCity)}
                    placeholder="Country"
                  />
                </FormGroup>
              </FormRow>

              <ContinueButton type="submit">Sign Up</ContinueButton>
            </Form>
          </>
        ) : (
          <>
            <WelcomeMessage>Please enter the OTP sent to your email.</WelcomeMessage>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Form onSubmit={handleOtpSubmit}>
              <FormRow>
                <FormGroup>
                  <Input
                    type="text"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup style={{ visibility: 'hidden' }}>
                  <Input disabled style={{ opacity: 0 }}/>
                </FormGroup>
              </FormRow>
              <ContinueButton type="submit">Verify OTP</ContinueButton>
            </Form>
          </>
        )}

        <SigninLink>
          Already have an account? <SigninLinkAnchor href="/sign-in">Sign in</SigninLinkAnchor>
        </SigninLink>

        <ClerkBranding>
          <Logo src={logo} alt="Vegaveta" />
        </ClerkBranding>
      </Modal>
    </Overlay>
  );
}

export default SignUpModalComp;

// Styled Components
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Modal = styled.div`
  background: white;
  padding: 30px 40px;
  border-radius: 12px;
  width: 600px;
  max-width: 90%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
  overflow-y: auto;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 1.8rem;
  color: #333;
  // margin: 0;
  margin-left: 120px;
  font-weight: 600;
  text-align:center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
  color: #666;
  padding: 5px;
  line-height: 1;
  &:hover {
    color: #333;
  }
`;

const WelcomeMessage = styled.p`
  color: #666;
  margin-bottom: 25px;
  text-align: center;
  font-size: 1.05rem;
`;

const ErrorMessage = styled.p`
  color: #e74c3c;
  text-align: center;
  margin-bottom: 20px;
  padding: 12px;
  background-color: #fdecea;
  border-radius: 6px;
  font-size: 0.95rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormRow = styled.div`
  display: flex;
  gap: 20px;
`;

const FormGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s;
  &::placeholder {
    color: #999;
  }
  &:focus {
    border-color: #4a90e2;
    outline: none;
    box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.2);
  }
`;
const DatePickerWrapper = styled.div`
  width: 100%;
  
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker__input-container input {
    width: 100%;
    padding: 14px;
    // padding-left: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    transition: all 0.2s;
    
    &::placeholder {
      color: #999;
    }
    
    &:focus {
      border-color: #4a90e2;
      outline: none;
      box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.2);
    }
  }
`;

const Select = styled.select`
  padding: 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23333%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 12px auto;
  transition: all 0.2s;
  color: ${props => props.value ? '#333' : '#999'};
  &:focus {
    border-color: #4a90e2;
    outline: none;
    box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.2);
  }
`;

const ErrorText = styled.p`
  color: #e74c3c;
  font-size: 0.85rem;
  margin-top: 6px;
`;

const ContinueButton = styled.button`
  background: #4a90e2;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 10px;
  &:hover {
    background: #3a7bc8;
    transform: translateY(-1px);
  }
  &:active {
    transform: translateY(0);
  }
`;

const SigninLink = styled.p`
  text-align: center;
  margin-top: 25px;
  color: #666;
  font-size: 1rem;
`;

const SigninLinkAnchor = styled.a`
  color: #4a90e2;
  text-decoration: none;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
`;

const ClerkBranding = styled.div`
  text-align: center;
  margin-top: 25px;
`;

const Logo = styled.img`
  width: 120px;
  height: auto;
`;