import React, { useState, useEffect } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Chart, registerables } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { supabase } from '../supabaseClient';
import Header from './Header';
import { useAuth } from "@clerk/clerk-react";
Chart.register(...registerables);

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.color};
  }
`;

const lightTheme = {
  background: '#f0f0f0',
  color: '#222',
  primary: '#AC544C',
  cardColor: '#fff',
};

const darkTheme = {
  background: 'rgb(31 41 55)',
  color: '#f0f0f0',
  primary: '#AC544C',
  cardColor: 'rgb(55 65 81)',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin-top: 100px;
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Card = styled.div`
  width: 33%;
  background-color: ${({ theme }) => theme.cardColor};
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  overflow-x: auto;
  &:hover {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2);
  }
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.primary};
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 1.5em;
`;

const BalanceSheet = () => {
  const { getToken } = useAuth();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getToken({ template: "supabase" });
        console.log("Your Clerk JWT:", token);
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };

    fetchToken();
  }, [getToken]);

  const [theme, setTheme] = useState(lightTheme);
  const [incomeData, setIncomeData] = useState([]);
  const [symbol, setSymbol] = useState('AAPL');

  const [cashData, setCashata] = useState(null);
  const [receivablesData, setReceivablesData] = useState(null);
  const [inventoryData, setInventoryData] = useState(null);
  const [prepaidData, setPrepaidData] = useState(null);
  const [currentAssetsData, setCurrentAssetsData] = useState(null);
  const [propertyPlantEquipmentNetData, setPropertyPlantEquipmentNetData] = useState(null);
  const [goodwillAndIntangibleAssetsData, setGoodwillAndIntangibleAssetsData] = useState(null);
  const [longTermInvestmentsData, setLongTermInvestmentsData] = useState(null);
  const [taxAssetsData, setTaxAssetsData] = useState(null);
  const [otherNonCurrentAssetsData, setOtherNonCurrentAssetsData] = useState(null);

  const [totalNonCurrentAssetsData, setTotalNonCurrentAssetsData] = useState(null);
  const [otherAssetsData, setOtherAssetsData] = useState(null);
  const [totalAssetsData, setTotalAssetsData] = useState(null);
  const [payblesData, setPayblesData] = useState(null);
  
  const [otherPayblesData, setOtherPayblesData] = useState(null);
  
  const [deferredRevenueData, setDeferredRevenueData] = useState(null);
  
  const [longTermDebtData, setLongTermDebtData] = useState(null);
  const [deferredRevenueNonCurrentData, setDeferredRevenueNonCurrentData] = useState(null);
  
  const [otherLiabilitiesData, setOtherLiabilitiesData] = useState(null);
  const [totalLiabilitiesData, setTotalLiabilitiesData] = useState(null);
  const [treasuryStockData, setTreasuryStockData] = useState(null);
  const [preferredStockData, setPreferredStockData] = useState(null);  
  const [retainedEarningsData, setRetainedEarningsData] = useState(null);
  const [additionalPaidInCapitalData, setAdditionalPaidInCapitalData] = useState(null);
  const [accumulatedOtherComprehensiveIncomeLossData, setAccumulatedOtherComprehensiveIncomeLossData] = useState(null);
  const [otherTotalStockholdersEquityData, setOtherTotalStockholdersEquityData] = useState(null);
  const [totalStockholdersEquityData, setTotalStockholdersEquityData] = useState(null);
  const [totalEquityData, setTotalEquityData] = useState(null);
  const [totalLiabilitiesAndTotalEquityData, setTotalLiabilitiesAndTotalEquityData] = useState(null);
  const [capitalLeaseObligationsCurrentData, setCapitalLeaseObligationsCurrentData] = useState(null);
  const [taxPayblesData, setTaxPayblesData] = useState(null);
  const [currentLiabilitiesData, setCurrentLiabilitiesData] = useState(null);
  const [nonCurrentLiabilitiesData, setNonCurrentLiabilitiesData] = useState(null);
  const [totalInvestmentData, setTotalInvestmentData] = useState(null);
  
  
  



  const fetchIncomeData = async () => {
    const { data, error } = await supabase
      .from('BalanceSheetStatement')
      .select('*')
      .eq('symbol', symbol)
      //   .gte('fiscalYear', 2019)
      //   .lte('fiscalYear', 2025)
      .eq('period', 'FY');
    if (error) console.error('Error fetching income data:', error);
    else setIncomeData(data || []);
  };

  useEffect(() => {
    fetchIncomeData();
  }, [symbol]);

  const chartOptions = {
    scales: {
      'y-left': {
        type: 'linear',
        position: 'left',
        beginAtZero: true,
      },
      'y-right': {
        type: 'linear',
        position: 'right',
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        display: true,
        position: 'top',
      },
    },
  };

  const transformOtherData = (data) => {
    setCashata({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Cash And Cash Equivalents',
          data: data.map((item) => item.cashAndCashEquivalents || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
        },
        {
          label: 'Short Term Investments',
          data: data.map((item) => item.shortTermInvestments || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },
      ],
    });

    setReceivablesData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Net Receivables',
          data: data.map((item) => item.netReceivables || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
          yAxisID: 'y-right',
        },
        {
          label: 'Accounts Receivables',
          data: data.map((item) => item.accountsReceivables || 0),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          yAxisID: 'y-left',
        },
        {
          label: 'Other Receivables',
          data: data.map((item) => item.otherReceivables || 0),
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
          yAxisID: 'y-left',
        },
      ],
    });
    setInventoryData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Inventory',
          data: data.map((item) => item.inventory || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },
      ],
    });
    setPrepaidData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Prepaid',
          data: data.map((item) => item.prepaid || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },
      ],
    });
    setCurrentAssetsData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Other Current Assets',
          data: data.map((item) => item.otherCurrentAssets || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          // type: 'line',
          // fill: false,
        },
        {
          label: 'Total Current Assets',
          data: data.map((item) => item.totalCurrentAssets || 0),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },
      ],
    });
    setPropertyPlantEquipmentNetData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Property Plant Equipment Net',
          data: data.map((item) => item.propertyPlantEquipmentNet || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },
      ],
    });
    setGoodwillAndIntangibleAssetsData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Goodwill',
          data: data.map((item) => item.goodwill || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
          yAxisID: 'y-right',
        },
        {
          label: 'Intangible Assets',
          data: data.map((item) => item.intangibleAssets || 0),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          yAxisID: 'y-left'
          // type: 'line',
          // fill: false,
        },
        {
          label: 'Goodwill and Intangible Assets',
          data: data.map((item) => item.goodwillAndIntangibleAssets || 0),
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
          yAxisID: 'y-left',
          // type: 'line',
          // fill: false,
        },
      ],
    });
    setLongTermInvestmentsData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Long Term Investments',
          data: data.map((item) => item.longTermInvestments || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          // type: 'line',
          // fill: false,
        },
      ],
    });
    setTaxAssetsData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Tax Assets',
          data: data.map((item) => item.taxAssets || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,          
        },
      ],
    });
    setOtherNonCurrentAssetsData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Other Non Current Assets',
          data: data.map((item) => item.otherNonCurrentAssets || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',          
          // type: 'line',
          // fill: false,
        },
      ],
    });
    setTotalNonCurrentAssetsData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Total Non Current Assets',
          data: data.map((item) => item.totalNonCurrentAssets || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },
      ],
    });
    setOtherAssetsData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Other Assets',
          data: data.map((item) => item.otherAssets || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          // type: 'line',
          // fill: false,
        },
      ],
    });

    setTotalAssetsData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Total Assets',
          data: data.map((item) => item.totalAssets || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },
      ],
    });
    
    setPayblesData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Total Payables',
          data: data.map((item) => item.totalPayables || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          yAxisID: 'y-left'
          // type: 'line',
          // fill: false,
        },
        {
          label: 'Account Payables',
          data: data.map((item) => item.accountPayables || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
          yAxisID: 'y-right'
        },
      ],
    });
    setOtherPayblesData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Other Payables',
          data: data.map((item) => item.otherPayables || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
          yAxisID: 'y-right'
        },
        {
          label: 'Accrued Expenses',
          data: data.map((item) => item.accruedExpenses || 0),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          yAxisID: 'y-left'
          // type: 'line',
          // fill: false,
        },
        {
          label: 'Short Term Debt',
          data: data.map((item) => item.shortTermDebt || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          yAxisID: 'y-left'
          // type: 'line',
          // fill: false,
        },
      ],
    });

    setCapitalLeaseObligationsCurrentData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Capital Lease Obligations Current',
          data: data.map((item) => item.capitalLeaseObligationsCurrent || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },
      ],
    });

    setTaxPayblesData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Tax Payables',
          data: data.map((item) => item.taxPayables || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          // type: 'line',
          // fill: false,
        },
      ],
    });

    setDeferredRevenueData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Deferred Revenue',
          data: data.map((item) => item.deferredRevenue || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          // type: 'line',
          // fill: false,
        },
      ],
    });

    setCurrentLiabilitiesData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Other Current Liabilities',
          data: data.map((item) => item.otherCurrentLiabilities || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          yAxisID: 'y-left'
          // type: 'line',
          // fill: false,
        },
        {
          label: 'Total Current Liabilities',
          data: data.map((item) => item.totalCurrentLiabilities || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
          yAxisID: 'y-right'
        },
      ],
    });

    setLongTermDebtData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Long Term Debt',
          data: data.map((item) => item.longTermDebt || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },
        
      ],
    });

    setDeferredRevenueNonCurrentData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Deferred Revenue Non Current',
          data: data.map((item) => item.deferredRevenueNonCurrent || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          yAxisID: 'y-left'
          // type: 'line',
          // fill: false,
        },
        {
          label: 'Deferred Tax Liabilities Non Current',
          data: data.map((item) => item.deferredTaxLiabilitiesNonCurrent || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
          yAxisID: 'y-right'
        },
        
      ],
    });

    setNonCurrentLiabilitiesData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Other Non Current Liabilities',
          data: data.map((item) => item.otherNonCurrentLiabilities || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          yAxisID: 'y-left'
          // type: 'line',
          // fill: false,
        },
        {
          label: 'Total Non Current Liabilities',
          data: data.map((item) => item.totalNonCurrentLiabilities || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
          yAxisID: 'y-right'
        },
        
      ],
    });

    setOtherLiabilitiesData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Other Liabilities',
          data: data.map((item) => item.otherLiabilities || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          yAxisID: 'y-left'
          // type: 'line',
          // fill: false,
        },
        {
          label: 'Capital Lease Obligations',
          data: data.map((item) => item.capitalLeaseObligations || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
          yAxisID: 'y-right'
        },
        
      ],
    });

    setTotalLiabilitiesData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Total Liabilities',
          data: data.map((item) => item.totalLiabilities || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },
                
      ],
    });

    setTreasuryStockData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Treasury Stock',
          data: data.map((item) => item.treasuryStock || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },
                
      ],
    });

    setPreferredStockData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Preferred Stock',
          data: data.map((item) => item.preferredStock || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          yAxisID: 'y-left'
          // type: 'line',
          // fill: false,
        },
        {
          label: 'Common Stock',
          data: data.map((item) => item.commonStock || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
          yAxisID: 'y-right'
        },
                
      ],
    });
    
    setRetainedEarningsData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Retained Earnings',
          data: data.map((item) => item.retainedEarnings || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },
                
      ],
    });

    setAdditionalPaidInCapitalData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Additional Paid In Capital',
          data: data.map((item) => item.additionalPaidInCapital || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          // type: 'line',
          // fill: false,
        },                
      ],
    });

    setAccumulatedOtherComprehensiveIncomeLossData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Accumulated Other Comprehensive Income Loss',
          data: data.map((item) => item.accumulatedOtherComprehensiveIncomeLoss || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },                
      ],
    });
    
    setOtherTotalStockholdersEquityData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Other Total Stockholders Equity',
          data: data.map((item) => item.otherTotalStockholdersEquity || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          // type: 'line',
          // fill: false,
        },                
      ],
    });

    setTotalStockholdersEquityData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Total Stockholders Equity',
          data: data.map((item) => item.totalStockholdersEquity || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },                
      ],
    });

    setTotalEquityData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Total Equity',
          data: data.map((item) => item.totalEquity || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },                
      ],
    });

    setTotalLiabilitiesAndTotalEquityData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Total Liabilities And Total Equity',
          data: data.map((item) => item.totalLiabilitiesAndTotalEquity || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          borderColor: 'rgb(255, 255, 0)',
          type: 'line',
          fill: false,
        },                
      ],
    });
    
    setTotalInvestmentData({
      labels: data.map((item) => item.date || ''),
      datasets: [
        {
          label: 'Total Investments',
          data: data.map((item) => item.totalInvestments || 0),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          type: 'line',
          fill: false,
          borderColor: 'rgb(255, 255, 0)',
          yAxisID: 'y-right',
          
        },
        {
          label: 'Total Debt',
          data: data.map((item) => item.totalDebt || 0),
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          yAxisID: 'y-left'
          // type: 'line',
          // fill: false,
        },
        {
          label: 'Net Debt',
          data: data.map((item) => item.netDebt || 0),
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          yAxisID: 'y-left'
          // type: 'line',
          // fill: false,
        },                
      ],
    });
    

    return {};
  };

  useEffect(() => {
    if (incomeData.length > 0) {
      transformOtherData(incomeData);
    }
  }, [incomeData, theme]);

  const handleThemeChange = () => {
    setTheme(theme === lightTheme ? darkTheme : lightTheme);
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Header layoutModeType={theme === lightTheme ? 'light' : 'dark'} onChangeLayoutMode={handleThemeChange} />
        <MainContent>
          <Card>
            <Title>Cash and Investments</Title>
            {cashData && (
              <Bar data={{ labels: cashData.labels, datasets: cashData.datasets }}  />
            )}
          </Card>

          <Card>
            <Title>Receivables</Title>
            {receivablesData && (
              <Bar data={{ labels: receivablesData.labels, datasets: receivablesData.datasets }} options={chartOptions} />
            )}
          </Card>

          <Card>
            <Title>Inventory</Title>
            {inventoryData && (
              <Bar data={{ labels: inventoryData.labels, datasets: inventoryData.datasets }} />
            )}
          </Card>

          <Card>
            <Title>Prepaid Expenses</Title>
            {prepaidData && (
              <Bar data={{ labels: prepaidData.labels, datasets: prepaidData.datasets }}  />
            )}
          </Card>

          <Card>
            <Title>Current Assets</Title>
            {currentAssetsData && (
              <Bar data={{ labels: currentAssetsData.labels, datasets: currentAssetsData.datasets }} />
            )}
          </Card>

          <Card>
            <Title>Property, Plant, and Equipment</Title>
            {propertyPlantEquipmentNetData && (
              <Bar
                data={{
                  labels: propertyPlantEquipmentNetData.labels,
                  datasets: propertyPlantEquipmentNetData.datasets,
                }}
                
              />
            )}
          </Card>

          <Card>
            <Title>Goodwill and Intangible Assets</Title>
            {goodwillAndIntangibleAssetsData && (
              <Bar
                data={{
                  labels: goodwillAndIntangibleAssetsData.labels,
                  datasets: goodwillAndIntangibleAssetsData.datasets,
                }}
                options={chartOptions}
              />
            )}
          </Card>

          <Card>
            <Title>Long-term Investments</Title>
            {longTermInvestmentsData && (
              <Bar
                data={{ labels: longTermInvestmentsData.labels, datasets: longTermInvestmentsData.datasets }}
                
              />
            )}
          </Card>

          <Card>
            <Title>Tax Assets</Title>
            {totalAssetsData && (
              <Bar data={{ labels: taxAssetsData.labels, datasets: taxAssetsData.datasets }} />
            )}
          </Card>

          <Card>
            <Title>Other Non-Current Assets</Title>
            {otherNonCurrentAssetsData && (
              <Bar
                data={{
                  labels: otherNonCurrentAssetsData.labels,
                  datasets: otherNonCurrentAssetsData.datasets,
                }}
                
              />
            )}
          </Card>


          {/* <Card>
            <Title>Total Assets</Title>
            {taxAssetsData && (
              <Bar data={{ labels: taxAssetsData.labels, datasets: taxAssetsData.datasets }} options={chartOptions} />
            )}
          </Card> */}
          <Card>
            <Title>Total Non Current Assets</Title>
            {totalNonCurrentAssetsData && (
              <Bar data={{ labels: totalNonCurrentAssetsData.labels, datasets: totalNonCurrentAssetsData.datasets }} />
            )}
          </Card>
          <Card>
            <Title>Other Assets</Title>
            {otherAssetsData && (
              <Bar data={{ labels: otherAssetsData.labels, datasets: otherAssetsData.datasets }} />
            )}
          </Card>
          <Card>
            <Title>Total Assets</Title>
            {totalAssetsData && (
              <Bar data={{ labels: totalAssetsData.labels, datasets: totalAssetsData.datasets }} />
            )}
          </Card>
          <Card>
            <Title>Paybles</Title>
            {payblesData && (
              <Bar data={{ labels: payblesData.labels, datasets: payblesData.datasets }} options={chartOptions} />
            )}
          </Card>
          <Card>
            <Title>Other Paybles</Title>
            {otherPayblesData && (
              <Bar data={{ labels: otherPayblesData.labels, datasets: otherPayblesData.datasets }} options={chartOptions} />
            )}
          </Card>
          <Card>
            <Title>Deferred Revenue</Title>
            {deferredRevenueData && (
              <Bar data={{ labels: deferredRevenueData.labels, datasets: deferredRevenueData.datasets }} />
            )}
          </Card>
          <Card>
            <Title>Long Term Debt</Title>
            {longTermDebtData && (
              <Bar data={{ labels: longTermDebtData.labels, datasets: longTermDebtData.datasets }}  />
            )}
          </Card>
          <Card>
            <Title>Deferred Revenue Non Current</Title>
            {deferredRevenueNonCurrentData && (
              <Bar data={{ labels: deferredRevenueNonCurrentData.labels, datasets: deferredRevenueNonCurrentData.datasets }} options={chartOptions} />
            )}
          </Card>
          <Card>
            <Title>Other Liabilities</Title>
            {otherLiabilitiesData && (
              <Bar data={{ labels: otherLiabilitiesData.labels, datasets: otherLiabilitiesData.datasets }} options={chartOptions} />
            )}
          </Card>
          <Card>
            <Title>Total Liabilities</Title>
            {totalLiabilitiesData && (
              <Bar data={{ labels: totalLiabilitiesData.labels, datasets: totalLiabilitiesData.datasets }} />
            )}
          </Card>
          <Card>
            <Title>Treasury Stock</Title>
            {treasuryStockData && (
              <Bar data={{ labels: treasuryStockData.labels, datasets: treasuryStockData.datasets }} />
            )}
          </Card>
          <Card>
            <Title>Preferred Stock</Title>
            {preferredStockData && (
              <Bar data={{ labels: preferredStockData.labels, datasets: preferredStockData.datasets }} options={chartOptions} />
            )}
          </Card>
          <Card>
            <Title>Retained Earnings</Title>
            {retainedEarningsData && (
              <Bar data={{ labels: retainedEarningsData.labels, datasets: retainedEarningsData.datasets }} />
            )}
          </Card>
          <Card>
            <Title>Additional Paid In Capital</Title>
            {additionalPaidInCapitalData && (
              <Bar data={{ labels: additionalPaidInCapitalData.labels, datasets: additionalPaidInCapitalData.datasets }} />
            )}
          </Card>
          <Card>
            <Title>Accumulated Other Comprehensive Income Loss</Title>
            {accumulatedOtherComprehensiveIncomeLossData && (
              <Bar data={{ labels: accumulatedOtherComprehensiveIncomeLossData.labels, datasets: accumulatedOtherComprehensiveIncomeLossData.datasets }}  />
            )}
          </Card>
          <Card>
            <Title>Other Total Stockholders Equity</Title>
            {otherTotalStockholdersEquityData && (
              <Bar data={{ labels: otherTotalStockholdersEquityData.labels, datasets: otherTotalStockholdersEquityData.datasets }} options={chartOptions} />
            )}
          </Card>
          <Card>
            <Title>Total Stockholders Equity</Title>
            {totalStockholdersEquityData && (
              <Bar data={{ labels: totalStockholdersEquityData.labels, datasets: totalStockholdersEquityData.datasets }} options={chartOptions} />
            )}
          </Card>
          <Card>
            <Title>Total Equity</Title>
            {totalEquityData && (
              <Bar data={{ labels: totalEquityData.labels, datasets: totalEquityData.datasets }} options={chartOptions} />
            )}
          </Card>
          <Card>
            <Title>Total Liabilities And Total Equity</Title>
            {totalLiabilitiesAndTotalEquityData && (
              <Bar data={{ labels: totalLiabilitiesAndTotalEquityData.labels, datasets: totalLiabilitiesAndTotalEquityData.datasets }}  />
            )}
          </Card>
          <Card>
            <Title>Capital Lease Obligations Current</Title>
            {capitalLeaseObligationsCurrentData && (
              <Bar data={{ labels: capitalLeaseObligationsCurrentData.labels, datasets: capitalLeaseObligationsCurrentData.datasets }} />
            )}
          </Card>
          <Card>
            <Title>Tax Paybles</Title>
            {taxPayblesData && (
              <Bar data={{ labels: taxPayblesData.labels, datasets: taxPayblesData.datasets }} options={chartOptions} />
            )}
          </Card>
          <Card>
            <Title>Current Liabilities</Title>
            {currentLiabilitiesData && (
              <Bar data={{ labels: currentLiabilitiesData.labels, datasets: currentLiabilitiesData.datasets }} options={chartOptions} />
            )}
          </Card>
          <Card>
            <Title>Total Investment</Title>
            {totalInvestmentData && (
              <Bar data={{ labels: totalInvestmentData.labels, datasets: totalInvestmentData.datasets }} />
            )}
          </Card>
          
  
  
        </MainContent>
      </Container>
    </ThemeProvider>
  );
};

export default BalanceSheet;