import React from "react";
import Chart from "react-apexcharts";

const DualAxisChart = ({ data }) => {
  // Extract unique years and sort them
  const years = [...new Set(data.map((item) => item.Year))].sort((a, b) => a - b);

  const totalInvestment = years.map(
    (year) =>
      data.find((item) => item.Year === year && item["Subject Descriptor"] === "Total investment")?.Value || 0
  );

  const grossSavings = years.map(
    (year) =>
      data.find((item) => item.Year === year && item["Subject Descriptor"] === "Gross national savings")?.Value || 0
  );

  const chartOptions = {
    chart: {
      type: "bar",
      toolbar: { show: true },
    },
    xaxis: {
      categories: years,
      title: { text: "Year", style: { fontSize: "14px", fontWeight: "bold" } },
      labels: { rotate: -45 },
    },
    yaxis: [
      {
        title: { text: "Investment & Savings", style: { fontSize: "14px", fontWeight: "bold" } },
        labels: { style: { fontSize: "12px" } },
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: "55%", // Improved bar width
        borderRadius: 0, // Slight rounded corners for a modern look
      },
    },
    stroke: {
      curve: "smooth",
      width: 3, // Makes the line clearer
    },
    colors: ["#007bff", "#28a745"],
    fill: { opacity: 1 },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: { enabled: false },
    legend: { position: "top", fontSize: "14px" },
  };

  const series = [
    {
      name: "Total Investment",
      type: "bar",
      data: totalInvestment,
    },
    {
      name: "Gross National Savings",
      type: "line",
      data: grossSavings,
    },
  ];

  return (
    <div style={{  padding: "20px", borderRadius: "10px" }}>
      <h3 style={{ textAlign: "center", marginBottom: "10px" }}>Economic Indicators</h3>
      <Chart options={chartOptions} series={series} type="bar" height={350} />
    </div>
  );
};

export default DualAxisChart;
