import React, { useState } from 'react';
import { useSignIn, useClerk } from '@clerk/clerk-react';
import styled from 'styled-components';

function SignInModal({ onClose, onSwitchToSignup }) {
    const { signIn, isLoaded, setActive } = useSignIn();
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false); // Add state for password visibility

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isLoaded) return;

        try {
            const result = await signIn.create({
                identifier: emailAddress,
                password: password,
            });

            if (result.status === "complete") {
                setActive({ session: result.createdSessionId });
                onClose();
            } else {
                console.log("Sign in status", result.status);
            }
        } catch (err) {
            console.error('Sign in error:', err);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Overlay>
            <Modal>
                <ModalHeader>
                    <Title>Sign in to VegaVeta</Title>
                    <CloseButton onClick={onClose}>X</CloseButton>
                </ModalHeader>
                
                <WelcomeMessage>Welcome back! Please sign in to continue</WelcomeMessage>
                <br/><br/>
                <Form onSubmit={handleSubmit}>
                    <Label htmlFor="email">Email address</Label>
                    <br/>
                    
                    <Input
                        type="email"
                        id="email"
                        placeholder=""
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                    />
                    <Label htmlFor="password">Password</Label>
                    <PasswordInputWrapper>
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            placeholder=""
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <PasswordToggle onClick={togglePasswordVisibility}>
                            {showPassword ? '👁️' : '👁️‍🗨️'}
                        </PasswordToggle>
                    </PasswordInputWrapper>

                    <ContinueButton type="submit">Sign In</ContinueButton>
                </Form>

                <SignupLink>
                    Don't have an account? <SignupSpan onClick={onSwitchToSignup}>Sign up</SignupSpan>
                </SignupLink>
            </Modal>
        </Overlay>
    );
}

export default SignInModal;

// Styled Components
const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const Modal = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

const Title = styled.h2`
    margin: 0;
    font-weight: 600;
    font-size:1.5em;
    text-align: center;
    width: 100%; // Ensure the title spans the full width
`;


const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 0.8rem;
    cursor: pointer;
`;

const WelcomeMessage = styled.p`
    margin-bottom: 15px;
    font-weight: 400; // Adjust font weight
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    margin-bottom: 5px;
    font-weight: 500; // Adjust font weight
    text-align:left;
`;

const Input = styled.input`
    padding: 10px;
    width:100%;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff9c4; // Light yellow background
    &::placeholder {
        color: #777; // Darker placeholder color
    }
`;

const PasswordInputWrapper = styled.div`
    position: relative;
`;

const PasswordToggle = styled.span`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
`;

const ContinueButton = styled.button`
    background: linear-gradient(to right, #333, #222); // Gradient background
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    position: relative; // For arrow positioning

    // &::after { // Add arrow
    //     content: '▶';
    //     position: absolute;
    //     right: 10px;
    //     top: 50%;
    //     transform: translateY(-50%);
    // }
`;

const SignupLink = styled.p`
    text-align: center;
    margin-top: 15px;
`;

const SignupSpan = styled.span`
    color: blue;
    cursor: pointer;
    text-decoration: underline;
`;