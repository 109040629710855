import React from "react";
import Chart from "react-apexcharts";

const DebtBarChart = ({ data }) => {
  // Organizing data
  const years = [...new Set(data.map(item => item.Year))].sort((a, b) => a - b);
  const grossDebt = years.map(year => {
    const entry = data.find(item => item.Year === year && item["Subject Descriptor"] === "General government gross debt");
    return entry ? entry.Value : 0;
  });
  const netDebt = years.map(year => {
    const entry = data.find(item => item.Year === year && item["Subject Descriptor"] === "General government net debt");
    return entry ? entry.Value : 0;
  });

  const options = {
    chart: { type: "bar", stacked: false, toolbar: { show: true } },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: { position: "top" },
        barHeight: "100%", // Increase bar thickness
      },
    },
    dataLabels: { enabled: true, style: { fontSize: "12px" } },
    xaxis: { categories: years, title: { text: "Year", style: { fontSize: "14px", fontWeight: "bold" } } },
    legend: { position: "top", fontSize: "14px" },
    colors: ["#007bff", "#28a745"], // Default colors
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 1, // Bars turn black on hover
        },
      },
    },
    tooltip: {
      theme: "dark",
    },
  };

  const series = [
    { name: "Gross Debt", data: grossDebt },
    { name: "Net Debt", data: netDebt },
  ];

  return (
    <div style={{  padding: "20px", borderRadius: "10px" }}>
      <h3 style={{ textAlign: "center", marginBottom: "10px" }}>Government Debt Levels</h3>
      <Chart options={options} series={series} type="bar" height={400} />
    </div>
  );
};

export default DebtBarChart;
