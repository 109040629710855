import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { FaTrashAlt } from 'react-icons/fa';
const ParentContainer = styled.div`
  width: 100%;
  height: 100%;
    display: flex;
  overflow: auto;
`;
const Table = styled.table`
  width: 100%;               // Make sure the table uses the full width of its parent container
position: relative;  
//   height: 80%;              // Table will take up full height of its parent container
  overflow: auto;            // Enable scrolling for overflowing content
        // Ensures the table position is relative for any child absolute positioning
  border-collapse: collapse;
//   margin-top:30%;

  // Optional: Add padding to improve appearance, e.g., padding inside cells
  th, td {
    padding: 10px;
    border: 1px solid #ccc;
  }
`;



const TableHeader = styled.th`
  border: 1px solid ${({ theme }) => theme.border};
  padding: 8px;
  text-align: left;
  background: #AC544C;  
`;

const TableCell = styled.td`
  border: 1px solid ${({ theme }) => theme.border};
  padding: 8px;
`;


const WatchListButton = styled.button`
  float: right;
  margin-left: 10px;
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.color};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  z-index: 10;
`;

const LosersData = () => {
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGainers = async () => {
      try {
        const response = await axios.get(
          'https://financialmodelingprep.com/stable/biggest-losers?apikey=11cd608ea5136b9fdb748e3a80c1724e'
        );

        const gainersData = response;

        // Map the API data to the structure needed for the table
        setCompanies(gainersData);
      } catch (err) {
        console.error('Error fetching biggest gainers:', err);
        setError('Failed to load biggest gainers');
      }
    };

    fetchGainers();
  }, []);

  const deleteFromWatchlist = async (symbol) => {
    // Handle deletion logic if needed
    toast.success('Successfully removed from watchlist!');
  };

  return (
    <>
      {error && <p>{error}</p>}
      {companies.length > 0 ? (
        <ParentContainer>
          <Table>
            <thead>
              <tr>
                <TableHeader>Name</TableHeader>
                <TableHeader>Symbol</TableHeader>
                <TableHeader>Price</TableHeader>
                <TableHeader>Change</TableHeader>
                <TableHeader>Change %</TableHeader>
                {/* <TableHeader>Exchange</TableHeader> */}
                {/* <TableHeader>Delete</TableHeader> */}
              </tr>
            </thead>
            <tbody>
              {companies.map((company, index) => (
                <tr key={index}>
                  <TableCell>{company.name}</TableCell>
                  <TableCell>{company.symbol}</TableCell>
                  <TableCell>${company.price}</TableCell>
                  <TableCell>{company.change.toFixed(2)}</TableCell>
                  <TableCell style={{color:"#f00"}}>{company.changesPercentage.toFixed(2)}%</TableCell>
                  {/* <TableCell>{company.exchange}</TableCell> */}
                  {/* <TableCell>
                    <WatchListButton
                      onClick={() => deleteFromWatchlist(company.symbol)}
                      style={{ background: 'transparent', border: 'none', cursor: 'pointer', zIndex: 10 }}
                    >
                      <FaTrashAlt style={{ color: 'red', fontSize: '14px' }} />
                    </WatchListButton>
                  </TableCell> */}
                </tr>
              ))}
            </tbody>
          </Table>
          <ToastContainer />
        </ParentContainer>
      ) : (
        <p>No items to display.</p>
      )}
    </>
  );
};

export default LosersData;
