import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Label, FormFeedback, Alert, Spinner, Button } from 'reactstrap';
import { Link, Navigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { supabase } from '../../supabaseClient';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../slices/auth/login/reducer';
import withRouter from "../../Components/Common/withRouter";
import './login.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { SignIn } from '@clerk/clerk-react';
import SignInModal from './SignInModal';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
const CenteredModalHeader = styled(ModalHeader)`
  display: flex;
  justify-content: center; // Center horizontally
  align-items: center;     // Center vertically (if needed)
  flex-direction: column; // Align title and subtitle vertically
  text-align: center; // Center text within header elements
`;

const LoginModal = ({ isOpen, toggle }) => {
    const dispatch = useDispatch();
    const [passwordShow, setPasswordShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [redirectToMarket, setRedirectToMarket] = useState(false);  // New state
    const navigate = useNavigate(); // Initialize navigate

    const togglePasswordVisibility = () => setPasswordShow(!passwordShow);

    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email format").required("Please Enter Your Email"),
        password: Yup.string().required("Please Enter Your Password"),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            setError(null);
            try {
                const { data, error } = await supabase.auth.signInWithPassword({
                    email: values.email,
                    password: values.password,
                });

                if (error) {
                    setError(error.message);
                } else if (data?.session?.access_token) {                    
                    console.log(data,'------------------');
                    
                    localStorage.setItem('supabaseToken', data.session.access_token);
                    localStorage.setItem('authenticated', 'true');
                    localStorage.setItem('authToken', data.session.access_token);
                    dispatch(loginSuccess({
                        token: data.session.access_token,
                        user: data.user
                    }));
                    // window.history.pushState({}, '', '/market');

                    setRedirectToMarket(true);  // Trigger redirect
                    // toggle(); // Close modal on success
                    
                }
            } catch (err) {
                setError("An unexpected error occurred. Please try again.");
            } finally {
                setLoading(false);
            }
        }
    });

    if (redirectToMarket) {                
        return <Navigate to="/market" />;  // Navigate to the market page
    }

    return (
        // <>
        //       <SignInModal isOpen={isOpen} onClose={toggle} />
        // </>
            // <SignIn isOpen={isOpen} toggle={toggle} />
        <Modal isOpen={isOpen} toggle={toggle} className="login-modal">
            {/* <ModalHeader toggle={toggle}> */}
            <CenteredModalHeader > {/* Use styled ModalHeader */}
                <h5 className="modal-title">Welcome Back!</h5>
                <p className="subtitle">Sign in to continue to VegaVeta.</p>
            </CenteredModalHeader>
            {/* </ModalHeader> */}
            <ModalBody>
                {error && <Alert color="danger">{error}</Alert>}
                <Form onSubmit={formik.handleSubmit} className="login-form">
                    <FormGroup>
                        <Label htmlFor="email">Email</Label>
                        <Input
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            invalid={formik.touched.email && !!formik.errors.email}
                        />
                        <FormFeedback>{formik.errors.email}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="position-relative">
                        <Label htmlFor="password">Password</Label>
                        <Input
                            type={passwordShow ? "text" : "password"}
                            name="password"
                            placeholder="Enter password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            invalid={formik.touched.password && !!formik.errors.password}
                        />
                        <FormFeedback>{formik.errors.password}</FormFeedback>
                        <div
                            className="btn-link position-absolute end-0 top-0 mt-2 me-2 text-decoration-none"
                            style={{ color: 'black' }} 
                            onClick={togglePasswordVisibility}
                        >
                            <i className={`ri-eye-${passwordShow ? 'fill' : 'fill'} align-middle`}></i>
                        </div>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" />
                            {' '}
                            Remember me
                        </Label>
                    </FormGroup>
                    <Button color="primary" disabled={loading} block type="submit" className="custom-btn">
                        {loading ? <Spinner size="sm" className='me-2' /> : 'Sign In'}
                    </Button>
                </Form>
            </ModalBody>
            <ModalFooter className="mb-0">
                <p>Don't have an account?&nbsp;&nbsp;<Link to="/register" className="fw-semibold loginColor">Sign up</Link></p>
            </ModalFooter>
        </Modal>
    );
};

export default withRouter(LoginModal);


