import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import logo from "../../assets/images/vegaveta/logo.png"; // Update this to the correct path of your image
import LoginModal from "../Login/LoginModal"; // Update the path to your LoginModal component
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ThemeToggle from "../../Layouts/ThemeToggle";
import { FaBars, FaUser } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUserSuccess } from "../../slices/auth/login/reducer";
import { useUser, useClerk } from '@clerk/clerk-react';
import { FaBell, FaMoon, FaCaretDown } from 'react-icons/fa';
import axios from 'axios';
import SignInModal from "../signup/SignInModal";
import SignUpModalComp from "../signup/SignUpModalComp";

// Styled components
const HeaderContainer = styled.div`
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  padding: 0px 20px;
  flex-wrap: wrap;
  position: fixed;
  top: 60px;  
  left: 0;
  width: 100%;
  background-color: rgba(55, 65, 81, 0.8); /* Adding transparency */
  backdrop-filter: blur(5px); /* Adds a subtle blur effect */
  z-index: 1000;
  box-sizing: border-box;
  height: 60px;
  font-size: 1.0em;
  border-bottom: 1px solid #ac544c;
  transition: transform 0.3s ease-in-out;
  gap:80px;
  // padding-left:150px;

  ${(props) => (props.isVisible ? "transform: translateY(0);" : "transform: translateY(-100%);")}

  @media (max-width: 768px) {
    padding: 10px 15px;
  }
`;

const HeaderContainerAuth = styled.div`
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  padding: 0px 20px;
  flex-wrap: wrap;
  position: fixed;
  top: 0px;  
  left: 0;
  width: 100%;
  background-color: rgba(55, 65, 81, 0.8); /* Adding transparency */
  backdrop-filter: blur(5px); /* Adds a subtle blur effect */
  z-index: 1000;
  box-sizing: border-box;
  height: 60px;
  font-size: 1.0em;
  border-bottom: 1px solid #ac544c;
  transition: transform 0.3s ease-in-out;
  gap:80px;
  // padding-left:150px;

  // ${(props) => (props.isVisible ? "transform: translateY(0);" : "transform: translateY(-100%);")}

  @media (max-width: 768px) {
    padding: 10px 15px;
  }
`;

const HeaderContent = styled.div`
  display: contents;
  align-items: center;
  justify-content: center; /* Ensures content is centered */
  width: 100%; /* Take full width to center content */
  max-width: 1200px; /* Optional: Set a max-width for larger screens */
  margin: 0 auto; /* Centers horizontally */
  gap: 150px;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;

const HeaderContainer1 = styled.div`
  display: flex;
  justify-content: space-between; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  padding: 0px 20px;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgb(55 65 81);
  z-index: 1001;
  box-sizing: border-box;
  height: 60px;
  font-size: 1.4em;
  border-bottom: 1px solid #ac544c;  

  @media (max-width: 768px) {
    padding: 10px 15px;
  }
`;

const LogoContainer = styled.div`
  position: absolute; /* Position the logo absolutely */
  left: 50%; /* Move to the horizontal center */
  transform: translateX(-50%); /* Adjust for exact centering */
`;

const drawAnimation = keyframes`
  0% {
    clip-path: inset(100% 0 0 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
`;

const AnimatedLogo = styled.img`
  width: 60px;
  height: auto;
  animation: ${drawAnimation} 5s ease-out 0s;
`;

const StyledButton = styled.button`
  background: linear-gradient(135deg, #dfe4eb 0%, #5a6b7c 100%);
  color: #000;
  border: none;
  padding: 10px 0px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 0.8em;
  width: 200px;
  transition: transform 0.3s ease, background 0.3s ease;
  float: left; /* Floats the button to the left */

  &:hover {
    background: linear-gradient(135deg, #5a6b7c 0%, #dfe4eb 100%);
    transform: scale(1.1);
  }
`;
const Logo = styled.img`
  position: fixed;
  width: 60px;
  margin-top: -20px;
  // opacity: 0.5;
  
  @media (max-width: 768px) {
    width: 50px;
  }
`;

const ButtonContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Button = styled.button`
  background-color: #ac544c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  
  &:hover {
    background-color: #b65e4d;
  }
`;

const Nav = styled.nav`
  display: contents;
  gap: 130px;
  // margin-left:100px;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  

  @media (max-width: 768px) {
    display: ${props => props.isNavOpen ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    background-color: #222122;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 999;
  }
`;

const NavLink = styled.a`
  color: white;
  text-decoration: none;
  padding: 10px;
  font-size: 1.2em;
  position: relative;
  &:hover {
    color: #AC544C;
  }

  @media (max-width: 768px) {
    font-size: 1em;
    padding: 15px;
  }
`;

const SubNav = styled.div`
  display: flex;
  margin-top: 30px;
  margin-left: 0px;
  // margin-left:10px;
  flex-direction: column; /* Horizontal layout */
  justify-content: flex-start;
  background-color: rgb(55 65 81);
  padding: 1px;
  position: absolute;
  top: 50%; /* Positioning it below the NavLink */
  left: 0;
  min-width: 100px;
  border-top: 1px solid #AC544C;
  // border-bottom: 1px solid #AC544C;
  // border-right: 1px solid #AC544C;
  // border-left: 1px solid #AC544C;
  z-index: 998;
`;

const SubNavLink = styled.a`
  color: white;
  text-decoration: none;
  margin: 10px 10px 15px 10px;  
  padding: 10px;
  white-space: nowrap;
  
  font-size: 1em;
  // border-bottom: 1px solid #AC544C;
  // border: 1px solid #AC544C;
  cursor: pointer;
  // min-width:200px;
  &:hover {
    color: #AC544C;
  }
`;

const LogoLink = styled.a`
  // margin-left: 20%;
  text-decoration: none;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const HamburgerMenu = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.5em;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const SearchBar = styled.input`
  width: 15%;
  padding: 10px;
  font-size: 1.2em;
  margin-bottom: 0px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  position: relative;
`;

const Suggestions = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid ${({ theme }) => theme.primary};
  max-height: 150px;
  overflow-y: auto;
  width: 15%; /* Matches the SearchBar width */
  position: absolute;
  top: calc(100% + 2px); 
  margin-left: 130px; 
  left: 0;
  z-index: 10;
  background-color: ${({ theme }) => theme.background};
  border-radius: 0 0 5px 5px;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.secondary};
    color: white;
  }
`;

const NavWrapper = styled.div`
  position: relative; /* Make sure the parent has relative positioning */
`;

const Header = ({ layoutModeType, onChangeLayoutMode, onCompanySelect }) => {
  const location = useLocation();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isNewsSubMenuVisible, setIsNewsSubMenuVisible] = useState(false);
  const [redirectToMarket, setRedirectToMarket] = useState(false);
  const navigate = useNavigate();
  const { signOut } = useClerk();
  const { isSignedIn, user } = useUser();
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState([]);
  const [query, setQuery] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [isSignInOpen, setIsSignInOpen] = useState(false);

  const openSignup = () => {
      setIsSignInOpen(false); // Close sign-in if open
      setIsSignupOpen(true);
  };

  const closeSignup = () => setIsSignupOpen(false);

  const openSignIn = () => {
      setIsSignupOpen(false); // Close signup if open
      setIsSignInOpen(true);
  };

  const closeSignIn = () => setIsSignInOpen(false);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setIsVisible(false); // Hide on scroll down
      } else {
        setIsVisible(true); // Show on scroll up
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const username = useSelector((state) => {
    console.log(state);
  }); // Adjust path as needed

  const fetchSearchResults = async (query) => {
    try {
      const response = await axios.get(`https://financialmodelingprep.com/api/v3/search?query=${query}&apikey=11cd608ea5136b9fdb748e3a80c1724e`);
      setSearchResults(response);
    } catch (error) {
      console.error('Error fetching search results', error);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setSelectedCompany(value);

    if (value) fetchSearchResults(value);
    else setSearchResults([]);
  };

  const handleSelectCompany = (company) => {
    setQuery(company.companyName);
    setSelectedCompany(company);
    setSearchResults([]);
    onCompanySelect(company);
  };

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  console.log(this);

  const isAuthenticated = useSelector((state) => {
    return isSignedIn;
  });

  const [activeMenu, setActiveMenu] = useState(() => {
    const currentPath = location.pathname.slice(1) || '';
    const keywordToCategory = {
      political: 'global',
      economic: 'global',
      socioeconomic: 'global',
      stock: 'news',
      crypto: 'news',
      forex: 'news',
      general: 'news',
      // overall: 'portfolio',
      clients: 'portfolio',
      portfolio: 'portfolio Analysis',
    };
    if (currentPath === 'summary') {
      setShowSearchBar(true);
    }
    // const savedState = localStorage.getItem('headerState',currentPath);      

    // return  keywordToCategory[currentPath];
  });

  const toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };

  const dispatch = useDispatch();

  if (isSignedIn && location.pathname === '/') {
    return <Navigate to="/market" />;  // Navigate to the market page
  }
  if (redirectToMarket) {
    window.location.reload();
    return <Navigate to="/market" />;  // Navigate to the market page
  }

  const toggleSubMenu = (menuName) => {
    setActiveMenu(menuName);
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleLogout1 = () => {
    localStorage.setItem('client_id', "");
    signOut();
  };

  const subMenus = {
    news: [
      { name: "General", link: "/general" },
      { name: "Stock", link: "/stock" },
      { name: "Crypto", link: "/crypto" },
      { name: "Forex", link: "/forex" },
    ],
    portfolio: [
      // { name: "Overall", link: "/overall" },
      { name: "Overall", link: "/clients" },
      { name: "Portfolio", link: "/portfolio" },      
      // { name: "Portfolio Charts", link: "/portfolio-charts" },
    ],
    pricing: [
      { name: "Basic", link: "/pricing/basic" },
      { name: "Premium", link: "/pricing/premium" },
      { name: "Enterprise", link: "/pricing/enterprise" },
    ],
    global: [
      { name: "Political", link: "/political" },
      { name: "Economic", link: "/economic" },
      { name: "Socioeconomic", link: "/socioeconomic" },
    ],
    company: [
      { name: "Summary", link: "/summary" },
      { name: "Income Statement", link: "/income-statement" },
      { name: "Balance Sheet", link: "/balancesheet" },
      { name: "Cash Flow", link: "/cashflow" },
    ],
  };

  const doRedirect = () => {
    navigate('/political');  // Redirect to the "/political" route
  };

  return (
    <>
    {!isAuthenticated &&
      <HeaderContainer1>
      {!isAuthenticated && <StyledButton onClick={openSignup}>Start your free trial</StyledButton> }
        <LogoContainer>
          {isAuthenticated ? (
            <LogoLink href="/market">
              <AnimatedLogo src={logo} alt="Logo" />
            </LogoLink>
          ) : (
            <LogoLink href="/">
              <AnimatedLogo src={logo} alt="Logo" />
            </LogoLink>
          )}
        </LogoContainer>
        {!isAuthenticated && (
          // onClick={openSignIn}
          <Button onClick={openSignIn}>Login</Button>
          // <LoginModal isOpen={isLoginModalOpen} toggle={toggleLoginModal} />
        )}
        
      </HeaderContainer1>
      
}

{ !isAuthenticated &&
      <HeaderContainer isVisible={isVisible}>
        <HeaderContent>
          {isAuthenticated && showSearchBar && (
            <>
              <SearchBar
                type="text"
                placeholder="Search by company name or symbol..."
                value={query}
                onChange={handleSearchChange}
              />
              {searchResults.length > 0 && (
                <Suggestions>
                  {searchResults.map((result) => (
                    <SuggestionItem
                      key={result.symbol}
                      onClick={() => handleSelectCompany(result)}
                    >
                      {result.name} ({result.symbol})
                    </SuggestionItem>
                  ))}
                </Suggestions>
              )}
            </>
          )}
          <HamburgerMenu onClick={toggleNav}>
            <FaBars />
          </HamburgerMenu>

          <Nav isNavOpen={isNavOpen}>
            {!isAuthenticated && <NavLink href="/" >Home</NavLink>}
            {isAuthenticated && <NavLink href="/market-page" onMouseOver={() => toggleSubMenu("")}><i className="fas fa-chart-line fa-xs"></i>&nbsp;Market</NavLink>}
            {isAuthenticated && (
              <NavWrapper>
                {activeMenu === 'news' && (
                  <SubNav onMouseLeave={() => toggleSubMenu("")}>
                    {subMenus[activeMenu].map((subMenuItem) => (
                      <SubNavLink key={subMenuItem.name} href={subMenuItem.link}>
                        {subMenuItem.name}
                      </SubNavLink>
                    ))}
                  </SubNav>
                )}
                {isAuthenticated && <NavLink href="#" onMouseOver={() => toggleSubMenu("news")}><i className="fas fa-newspaper fa-xs"></i>&nbsp;News  <FaCaretDown /></NavLink>}
              </NavWrapper>
            )}
            {isAuthenticated && (
              <NavWrapper>
                {activeMenu === 'company' && (
                  <SubNav onMouseLeave={() => toggleSubMenu("")}>
                    {subMenus[activeMenu].map((subMenuItem) => (
                      <SubNavLink key={subMenuItem.name} href={subMenuItem.link}>
                        {subMenuItem.name}
                      </SubNavLink>
                    ))}
                  </SubNav>
                )}
                {isAuthenticated && <NavLink href="#" onMouseOver={() => toggleSubMenu("company")}><i className="fas fa-building fa-xs"></i>&nbsp;Company  <FaCaretDown /></NavLink>}
              </NavWrapper>
            )}
            {!isAuthenticated && <NavLink href="/services" onMouseOver={() => toggleSubMenu("")}>Services</NavLink>}
            {!isAuthenticated && <NavLink href="/public-articles" onMouseOver={() => toggleSubMenu("")}>Articles</NavLink>}
            {!isAuthenticated && <NavLink href="/pricing">Pricing</NavLink>}
            {!isAuthenticated && <NavLink href="/about">About Us</NavLink>}
            {isAuthenticated && <NavLink href="/chart" onMouseOver={() => toggleSubMenu("")}><i className="fas fa-chart-bar fa-sm"></i>&nbsp;Charts</NavLink>}
            <NavWrapper>
              {activeMenu === 'portfolio' && (
                <SubNav onMouseLeave={() => toggleSubMenu("")}>
                  {subMenus[activeMenu].map((subMenuItem) => (
                    <SubNavLink key={subMenuItem.name} href={subMenuItem.link}>
                      {subMenuItem.name}
                    </SubNavLink>
                  ))}
                </SubNav>
              )}
              {isAuthenticated && <NavLink href="#" onMouseOver={() => toggleSubMenu("portfolio")}><i className="fas fa-wallet fa-xs"></i>&nbsp;Portfolio  <FaCaretDown /></NavLink>}
            </NavWrapper>
            {isAuthenticated && <NavLink href="/political" ><i className="fas fa-globe earth-icon fa-xs"></i>&nbsp;Political  </NavLink>}
              {isAuthenticated && <NavLink href="/economic" ><i className="fas fa-globe earth-icon fa-xs"></i>&nbsp;Economic  </NavLink>}
              {isAuthenticated && <NavLink href="/socioeconomic" ><i className="fas fa-globe earth-icon fa-xs"></i>&nbsp;Socioeconomic  </NavLink>}
            {/* <NavWrapper>
              {activeMenu === 'global' && (
                <SubNav onMouseLeave={() => toggleSubMenu("")}>
                  {subMenus[activeMenu].map((subMenuItem) => (
                    <SubNavLink key={subMenuItem.name} href={subMenuItem.link}>
                      {subMenuItem.name}
                    </SubNavLink>
                  ))}
                </SubNav>
              )}
              {isAuthenticated && <NavLink href="" onMouseOver={() => toggleSubMenu("global")}><i className="fas fa-globe earth-icon fa-xs"></i>&nbsp;Global  <FaCaretDown /></NavLink>}
              
            </NavWrapper> */}
            {/* {isAuthenticated && <NavLink href="/articles" onMouseOver={() => toggleSubMenu("")}><i className="fas fa-file-alt fa-xs"></i>&nbsp;Articles</NavLink>} */}
            {isAuthenticated && (
              <div className="d-flex align-items-center" onMouseOver={() => toggleSubMenu("")}>
                <ThemeToggle
                  layoutMode={layoutModeType}
                  onChangeLayoutMode={onChangeLayoutMode}
                />
                <div className="position-relative me-4">
                  <FaBell size={20} style={{ color: '#fff' }} />
                  <span className="badge bg-danger rounded-pill position-absolute top-0 start-100 translate-middle">
                    3
                  </span>
                </div>
                <div className="position-relative" style={{ background: '#fff', padding: '10px', color: '#000' }}>
                  <div
                    className="d-flex align-items-center"
                    onClick={toggleDropdown}
                    style={{ cursor: 'pointer' }}
                  >
                    <div
                      className="rounded-circle bg-secondary text-white d-flex justify-content-center align-items-center me-2"
                      style={{ width: '40px', height: '40px' }}
                    >
                      <FaUser />
                    </div>
                    {user.firstName.toLocaleUpperCase()}
                  </div>
                  {showDropdown && (
                    <div
                      className="dropdown-menu show position-absolute end-0 mt-2 p-2 shadow-lg"
                      style={{ minWidth: '130px', color: '#fff', background: '#000' }}
                    >
                      <a href="/settings" className="dropdown-item" style={{ color: '#fff', background: '#000' }}>
                        Settings
                      </a>
                      <div className="dropdown-divider" style={{ borderBlockColor: '#b65e4d' }}></div>
                      <button className="dropdown-item" onClick={handleLogout1} style={{ color: '#fff', background: '#000' }}>
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Nav>
        </HeaderContent>
        <LoginModal isOpen={isLoginModalOpen} toggle={toggleLoginModal} />
      </HeaderContainer>
}
{isAuthenticated &&
<HeaderContainerAuth >
        <HeaderContent>
          {isAuthenticated && showSearchBar && (
            <>
              <SearchBar
                type="text"
                placeholder="Search by company name or symbol..."
                value={query}
                onChange={handleSearchChange}
              />
              {searchResults.length > 0 && (
                <Suggestions>
                  {searchResults.map((result) => (
                    <SuggestionItem
                      key={result.symbol}
                      onClick={() => handleSelectCompany(result)}
                    >
                      {result.name} ({result.symbol})
                    </SuggestionItem>
                  ))}
                </Suggestions>
              )}
            </>
          )}
          <HamburgerMenu onClick={toggleNav}>
            <FaBars />
          </HamburgerMenu>

          <Nav isNavOpen={isNavOpen}>
            {!isAuthenticated && <NavLink href="/">Home</NavLink>}
            {isAuthenticated && <NavLink href="/market-page" onMouseOver={() => toggleSubMenu("")}><i className="fas fa-chart-line fa-xs"></i>&nbsp;Market</NavLink>}
            {isAuthenticated && (
              <NavWrapper>
                {activeMenu === 'news' && (
                  <SubNav onMouseLeave={() => toggleSubMenu("")}>
                    {subMenus[activeMenu].map((subMenuItem) => (
                      <SubNavLink key={subMenuItem.name} href={subMenuItem.link}>
                        {subMenuItem.name}
                      </SubNavLink>
                    ))}
                  </SubNav>
                )}
                {isAuthenticated && <NavLink href="#" onMouseOver={() => toggleSubMenu("news")}><i className="fas fa-newspaper fa-xs"></i>&nbsp;News  <FaCaretDown /></NavLink>}
              </NavWrapper>
            )}
            {isAuthenticated && (
              <NavWrapper>
                {activeMenu === 'company' && (
                  <SubNav onMouseLeave={() => toggleSubMenu("")}>
                    {subMenus[activeMenu].map((subMenuItem) => (
                      <SubNavLink key={subMenuItem.name} href={subMenuItem.link}>
                        {subMenuItem.name}
                      </SubNavLink>
                    ))}
                  </SubNav>
                )}
                {isAuthenticated && <NavLink href="#" onMouseOver={() => toggleSubMenu("company")}><i className="fas fa-building fa-xs"></i>&nbsp;Company  <FaCaretDown /></NavLink>}
              </NavWrapper>
            )}
            {!isAuthenticated && <NavLink href="/services" onMouseOver={() => toggleSubMenu("")}>Services</NavLink>}
            {!isAuthenticated && <NavLink href="/public-articles" onMouseOver={() => toggleSubMenu("")}>Articles</NavLink>}
            {!isAuthenticated && <NavLink href="/pricing">Pricing</NavLink>}
            {!isAuthenticated && <NavLink href="/about">About Us</NavLink>}
            {isAuthenticated && <NavLink href="/chart" onMouseOver={() => toggleSubMenu("")}><i className="fas fa-chart-bar fa-sm"></i>&nbsp;Charts</NavLink>}
            <NavWrapper>
              {activeMenu === 'portfolio' && (
                <SubNav onMouseLeave={() => toggleSubMenu("")}>
                  {subMenus[activeMenu].map((subMenuItem) => (
                    <SubNavLink key={subMenuItem.name} href={subMenuItem.link}>
                      {subMenuItem.name}
                    </SubNavLink>
                  ))}
                </SubNav>
              )}
              {isAuthenticated && <NavLink href="#" onMouseOver={() => toggleSubMenu("portfolio")}><i className="fas fa-wallet fa-xs"></i>&nbsp;Portfolio  <FaCaretDown /></NavLink>}
            </NavWrapper>
            {isAuthenticated && <NavLink href="/political" ><i className="fas fa-globe earth-icon fa-xs"></i>&nbsp;Political  </NavLink>}
              {isAuthenticated && <NavLink href="/economic" ><i className="fas fa-globe earth-icon fa-xs"></i>&nbsp;Economic  </NavLink>}
              {isAuthenticated && <NavLink href="/socioeconomic" ><i className="fas fa-globe earth-icon fa-xs"></i>&nbsp;Socioeconomic  </NavLink>}
            {/* <NavWrapper>
              {activeMenu === 'global' && (
                <SubNav onMouseLeave={() => toggleSubMenu("")}>
                  {subMenus[activeMenu].map((subMenuItem) => (
                    <SubNavLink key={subMenuItem.name} href={subMenuItem.link}>
                      {subMenuItem.name}
                    </SubNavLink>
                  ))}
                </SubNav>
              )}
              {isAuthenticated && <NavLink href="" onMouseOver={() => toggleSubMenu("global")}><i className="fas fa-globe earth-icon fa-xs"></i>&nbsp;Global  <FaCaretDown /></NavLink>}
              
            </NavWrapper> */}
            {/* {isAuthenticated && <NavLink href="/articles" onMouseOver={() => toggleSubMenu("")}><i className="fas fa-file-alt fa-xs"></i>&nbsp;Articles</NavLink>} */}
            {isAuthenticated && (
              <div className="d-flex align-items-center" onMouseOver={() => toggleSubMenu("")}>
                <ThemeToggle
                  layoutMode={layoutModeType}
                  onChangeLayoutMode={onChangeLayoutMode}
                />
                <div className="position-relative me-4">
                  <FaBell size={20} style={{ color: '#fff' }} />
                  <span className="badge bg-danger rounded-pill position-absolute top-0 start-100 translate-middle">
                    3
                  </span>
                </div>
                <div className="position-relative" style={{ background: '#fff', padding: '10px', color: '#000' }}>
                  <div
                    className="d-flex align-items-center"
                    onClick={toggleDropdown}
                    style={{ cursor: 'pointer' }}
                  >
                    <div
                      className="rounded-circle bg-secondary text-white d-flex justify-content-center align-items-center me-2"
                      style={{ width: '40px', height: '40px' }}
                    >
                      <FaUser />
                    </div>
                    {user.firstName.toLocaleUpperCase()}
                  </div>
                  {showDropdown && (
                    <div
                      className="dropdown-menu show position-absolute end-0 mt-2 p-2 shadow-lg"
                      style={{ minWidth: '130px', color: '#fff', background: '#000' }}
                    >
                      <a href="/settings" className="dropdown-item" style={{ color: '#fff', background: '#000' }}>
                        Settings
                      </a>
                      <div className="dropdown-divider" style={{ borderBlockColor: '#b65e4d' }}></div>
                      <button className="dropdown-item" onClick={handleLogout1} style={{ color: '#fff', background: '#000' }}>
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Nav>
        </HeaderContent>
        <LoginModal isOpen={isLoginModalOpen} toggle={toggleLoginModal} />
      </HeaderContainerAuth>
}
{isSignupOpen && <SignUpModalComp onClose={closeSignup} />}
{isSignInOpen && (
                <SignInModal onClose={closeSignIn} onSwitchToSignup={openSignup} />
            )}
    </>
    
  );
};

export default Header;