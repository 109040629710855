import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <Banner>
      <Logo>
        <span role="img" aria-label="cookie">
          🍪
        </span>
      </Logo>
      <Text>
        We use cookies to collect data to improve your experience on our site. Read our <a href="#">cookie policy</a>.
        You can <a href="#">change your preferences</a> at any time.
      </Text>
      <ButtonGroup>
        <ButtonDecline onClick={handleDecline}>Decline</ButtonDecline>
        <ButtonAccept onClick={handleAccept}>Accept</ButtonAccept>
      </ButtonGroup>
    </Banner>
  );
};

const Banner = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background: #fff;
  color: #333;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 90%;
  font-family: Arial, sans-serif;
`;

const Logo = styled.div`
  font-size: 24px;
  margin-right: 15px;
`;

const Text = styled.p`
  flex: 1;
  font-size: 14px;
  margin: 0;
  a {
    color: #6a5acd;
    text-decoration: none;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
`;

const ButtonAccept = styled(Button)`
  background: #333;
  color: white;
`;

const ButtonDecline = styled(Button)`
  background: none;
  color: #333;
`;

export default CookieConsent;
