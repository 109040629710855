import React from "react";
import { Routes, Route } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

// Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";

// Routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import AuthProtected from "./AuthProtected";

const Index = () => {
  const { isSignedIn } = useUser(); // Clerk authentication status

  return (
    <Routes>
      {/* Public Routes */}
      {publicRoutes.map((route, idx) => (
        <Route
          key={idx}
          path={route.path}
          element={
            <NonAuthLayout>{route.component}</NonAuthLayout>
          }
        />
      ))}

      {/* Protected Routes */}
      {authProtectedRoutes.map((route, idx) => (
        <Route
          key={idx}
          path={route.path}
          element={
            <AuthProtected>
              <>{route.component}</>
            </AuthProtected>
          }
        />
      ))}
    </Routes>
  );
};

export default Index;
