import React, { useState } from "react";
import styled from "styled-components";
import { FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import {  FaXTwitter } from "react-icons/fa6";
import logo from "../../assets/images/vegaveta/vegaveta_footer";
import { useSelector } from "react-redux";
import {toast, ToastContainer } from 'react-toastify';
import { useUser } from "@clerk/clerk-react";
import { supabase } from '../../supabaseClient';

const FooterContainer = styled.footer`
  background-color: rgb(55 65 81);
  color: white;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5em;
  border-top: 1px solid #ac544c;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 120px;
  }
`;

const Logo = styled.img`
  width: 80px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const LogoLink = styled.a`
  text-decoration: none;
  margin-left: 10%;

  @media (min-width: 768px) {
    margin-left: 20%;
  }
`;

const Links = styled.div`
  display: contents;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-bottom: 0;
    gap: 30px;
  }
`;

const FooterLink = styled.a`
  color: white;
  text-decoration: none;
  padding: 5px;
  &:hover {
    color: #ac544c;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;

  @media (min-width: 768px) {
    margin-top: 0;
  }
`;

const SocialIcon = styled.a`
  color: white;
  text-decoration: none;
  font-size: 1.5em;
  &:hover {
    color: #ac544c;
  }
`;

const SubscribeButton = styled.button`
  // margin-top: 20px;
  padding: 10px 20px;
  background-color: #ac544c;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.5em;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #8b3f38;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  text-align: center;
    position: relative; // Needed for absolute positioning of close button
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const CloseButton = styled.button`
  background-color: #ac544c;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #8b3f38;
  }
`;
const CloseButton1 = styled.button`
  position: absolute;  // Position it absolutely within ModalContent
  top: 10px;          // Adjust top and right as needed
  right: 10px;
  background-color: #ac544c;
  color: white;
  border: none;
  padding: 5px 10px;   // Smaller padding for a cleaner look
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #8b3f38;
  }
`;

const Footer = () => {
  const { isSignedIn } = useUser();
  const isAuthenticated = useSelector(() => isSignedIn);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  
    const [message, setMessage] = useState('');

    const handleSubmit = async () => {
        // e.preventDefault();

        const { error } = await supabase
            .from('newsletter_signups')
            .insert([{ name, email }]);

            if (error) {
                console.log(error);
                if(error.message.includes("duplicate")){
                    toast.info('You are already subscribed');    
                }else{
                    toast.error('There was an error signing up. Please try again.');
                }
                
                
            } else {
                toast.success('Thank you for subscribing!');
                setName('');
                setEmail('');
                setIsModalOpen(false);
            }
    };

  return (
    <>
      <FooterContainer>
        {isAuthenticated ? (
          <LogoLink href="/market">
            <Logo src={logo} alt="Logo" />
          </LogoLink>
        ) : (
          <LogoLink href="/">
            <Logo src={logo} alt="Logo" />
          </LogoLink>
        )}
        <Links>
          <FooterLink href="/careers">Careers</FooterLink>
          <FooterLink href="/media">Media</FooterLink>
          <FooterLink href="/legal">Legal</FooterLink>
          <FooterLink href="/contact">Contact</FooterLink>
          
        </Links>
        <SubscribeButton onClick={() => setIsModalOpen(true)}>
          Subscribe
        </SubscribeButton>
        <SocialIcons>
          <SocialIcon href="https://www.linkedin.com/company/106406760/" target="_blank">
            <FaLinkedin />
          </SocialIcon>
          <SocialIcon href="https://x.com/vegaveta1" target="_blank">
            <FaXTwitter />
          </SocialIcon>
          <SocialIcon href="https://www.youtube.com/@Vegaveta" target="_blank">
            <FaYoutube />
          </SocialIcon>
        </SocialIcons>
        
      </FooterContainer>
      {isModalOpen && (
        <ModalOverlay>
          <ModalContent>
          
            <h2>Subscribe to our Newstletter</h2>
            <CloseButton1 onClick={() => setIsModalOpen(false)}>&times;</CloseButton1>
            <Input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              type="tel"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <CloseButton onClick={handleSubmit}>
              Submit
            </CloseButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default Footer;
