import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO tags
import serviceImage1 from '../../assets/images/vegaveta/optimized/service3.jpg';
import serviceImage7 from '../../assets/images/vegaveta/optimized/3.jpg';
import serviceImage2 from '../../assets/images/vegaveta/optimized/5.jpg';
import serviceImage9 from '../../assets/images/vegaveta/optimized/2.jpg';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

// Animation to slide in cards from left and right
const slideInLeft = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const slideInRight = keyframes`
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  // gap: 40px;
`;

const CardTitle = styled.h3`
  font-size: 1.8em;
  font-weight: 500;
  color: #fff;
  font-family: Montserrat, Helvetica, Arial, sans-serif;

  @media (max-width: 768px) {
    font-size: 1.8em;
  }
`;

const Card = styled.div`
  position: relative;
  width: 48%; /* Adjust width to ensure proper positioning */
  max-width: 1000px;
  height: 320px;
  overflow: hidden;
  display: flex;
  // flex-direction: ${({ isEven }) => (isEven ? 'row-reverse' : 'row')};
  justify-content: ${({ isEven }) => (isEven ? 'flex-end' : 'flex-start')};
  opacity: 0; /* Start with the card hidden */
  transform: translateX(0);
  animation: ${({ isInView, isEven }) => (isInView ? (isEven ? slideInRight : slideInLeft) : '')} 1s forwards; /* Apply animation if in view */  
  margin-left: 8%;
  margin-right: 8%;
  ${({ isEven }) => (isEven ? 'margin-left: auto;' : 'margin-right: auto;')}  
  margin-top: ${({ isEven }) => (isEven ? '-20px' : '0px')};
  &:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }

  @media (max-width: 768px) {
    width: 100%; /* Full width on mobile */
    flex-direction: column;
    height: auto;
    margin: 20px auto;
  }
`;


const CardImage = styled.img`
  width: 60%;
  height: 90%;
  object-fit: cover;
  &:hover {
    transform: scale(1.05); /* Zoom effect */
    transition: transform 0.3s ease-in-out;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
  }
`;

const CardContent = styled.div`
  position: absolute;
  left: 75%;
  top: 50%;  
  transform: translate(-50%, -50%);
  width: 50%;
  height: 55%;
  // background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;  
  background-color:rgba(55, 65, 81, 0.8);
  @media (max-width: 768px) {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
`;

const CardContent3 = styled.div`
  position: absolute;
  left: 25%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 55%;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color:rgba(55, 65, 81, 0.8);

  @media (max-width: 768px) {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
`;

const CardText = styled.p`
  font-size: 1rem;
  // color: #444;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const CardButton = styled.button`
  background-color: rgb(55 65 81); 
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    transform: scale(1.05); /* Zoom effect */
    transition: transform 0.3s ease-in-out;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Cards = () => {
  return (
    <CardsContainer>
      {/* SEO Tags for Card 1 */}
      <Helmet>
        <meta name="keywords" content="abstract, accounting, analysis, background, banking, block chain, board, business, capital, chart, commodities, concept, corona virus, currency, data, diagram, digital, display, economic, economy, exchange, finance, financial, global, graphic, growth, increase, index, information, investment, management, market, money, monitor, price, profit, rate, report, risk, sell, statistic, statistics, stock exchange, stock market, success, technology, ticker, trade, trend, up" />
      </Helmet>
      <CardContentItem serviceImage={serviceImage1} isEven={false} title="Advanced Financial Data" text="Access real-time and historical data on thousands of stocks, ETFs, and indices." href="/comprehensive"/>

      {/* SEO Tags for Card 2 */}
      <Helmet>
        <meta name="keywords" content="analytics, analyzing financial data, analyzing stock charts, business analytics, business environment, business office, business strategy, businessman, company, computer, corporate finance, data visualization, data-driven decisions, economic analysis, economic forecasts, executive office, experienced manager, financial analysis, financial data, financial planning, investment analysis, investment portfolio manager, investment research, investment strategies, market analysis, market projections, market trends, modern office, office setup, portfolio management, professional workspace, sophisticated computer screen, stock charts, stock market, stock trading, strategic planning, trading desk" />
      </Helmet>
      <CardContentItem serviceImage={serviceImage9} isEven={true} title="Dynamic Charting Tools" text="Visualize market trends and performance with our interactive charting tools." href="/interactive"/>

      {/* SEO Tags for Card 3 */}
      <Helmet>
        <meta name="keywords" content="abstract, account, accounting, advisor, analysis, analyzing, annual, background, balance, banking, business, calculator, chart, commerce, concept, credit, currency, data, datum, diagram, documents, earnings, economics, figures, finance, financial, forecast, graph, growth, index, invest, investment, management, market, marketing, performance, pie, planning, report, research, services, statistic, stock, success, summary, trade, wide view" />
      </Helmet>
      <CardContentItem serviceImage={serviceImage2} isEven={false} title="Customizable Dashboards" text="Tailor your dashboard to the metrics that matter most." />

      {/* SEO Tags for Card 4 */}
      <Helmet>
        <meta name="keywords" content="abstract, background, banner, blue, business, chart, concept, data, design, economic, economy background, economy concept, exchange, finance, finance background, finance banner, financial, forex, global, global economy, global map, graph, information, investment, map, market, news, number, pattern, picture, stock, stock market, ticker" />
      </Helmet>
      <CardContentItem serviceImage={serviceImage7} isEven={true} title="Premier Economic Data" text="Gain insights into macroeconomic indicators and forecasts." href={"/economicdata"}/>
    </CardsContainer>
  );
};

const CardContentItem = ({ serviceImage, isEven, title, text, href="/" }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger only once when the element enters the view
    threshold: 0.5, // 50% of the element should be in view
  });

  return (
    <Card ref={ref} isInView={inView} isEven={isEven}>
      <CardImage src={serviceImage} alt="Image" />
      {isEven ? (
        <CardContent3>
          <CardTitle>{title}</CardTitle>
          <CardText>{text}</CardText>
          <br/>
          <CardButton as={Link} to={href}>More about this</CardButton>
        </CardContent3>
      ) : (
        <CardContent>
          <CardTitle>{title}</CardTitle>
          <CardText>{text}</CardText>
          <br/>
          <CardButton style={{background: "linear-gradient(135deg, #dfe4eb 0%, #5a6b7c 100%)", color:"#000"}} as={Link} to={href}>More about this</CardButton>
        </CardContent>
      )}
    </Card>
  );
};

export default Cards;