import React from "react";
import Chart from "react-apexcharts";

const CurrentAccountLineChart = ({ data }) => {
  // Extract years and values dynamically
  const years = data.map((item) => item.Year);
  const values = data.map((item) => item.Value);

  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: { show: true },
    },
    xaxis: {
      categories: years,
      title: { text: "Year" },
    },
    yaxis: {
      title: { text: "Current Account Balance (in billions)" },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    colors: ["#007bff"],
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 1, // Turns black on hover
        },
      },
    },
    markers: {
      size: 5,
      hover: {
        size: 7,
        fillColor: "#000", // Changes marker color to black on hover
      },
    },
    tooltip: {
      theme: "dark",
    },
  };

  const series = [
    {
      name: "Current Account Balance",
      data: values,
    },
  ];

  return (
    <div className="chart-container">
      <h3>Current Account Balance Trend</h3>
      <Chart options={chartOptions} series={series} type="line" height={350} />
    </div>
  );
};

export default CurrentAccountLineChart;
