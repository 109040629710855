import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import Header from '../../pages/Header/index';
import Footer from '../../Components/Common/Footer';
import backGround from '../../assets/images/vegaveta/bg.jpg';

const lightTheme = {
  color: "#000000",
  cardColor: "#000000",
  titleColor: "#AC544C",
  textColor: "#333333",
  // backgroundImage: `url(${backGround})`,
  background: "#fff"
};

const darkTheme = {
  color: "#000000",
  cardColor: "#000000",
  titleColor: "#AC544C",
  textColor: "#333333",
  // backgroundImage: `url(${backGround})`,
  background: "#fff"
};

const GlobalStyle = createGlobalStyle`
  body {
    // background: ${({ theme }) => theme.backgroundImage};
    background: "#fff"
    color: ${({ theme }) => theme.color};
    transition: background-color 0.3s, color 0.3s;
    font-family: 'Lora', serif; /* Consistent font */
  }
`;

const Container = styled.div`
  padding: 50px;
  margin-top: 100px;
`;

const ContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
  gap: 30px; /* Increased gap */
`;

const Card = styled.div`
  background-color: #fff;
  color: ${({ theme }) => theme.cardColor};
  padding: 30px; /* Increased padding */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Softer shadow */
  // overflow: hidden; /* Prevents content from overflowing */
  height: 500px; /* Fixed height for consistent card sizes */
  display: flex;
  flex-direction: column;

`;

const Title = styled.h1`
  font-size: 1.8em; /* Adjusted font size */
  // color: ${({ theme }) => theme.titleColor};
  margin-bottom: 15px;
  font-weight: bold;
`;
const Title1 = styled.h2`
  font-size: 2em; /* Adjusted font size */
  // color: ${({ theme }) => theme.titleColor};
  margin-bottom: 15px;
  // margin-left:auto;
  // margin-right:auto;
  display:flex;
  justify-content:center;
  font-weight: bold;
`;

const Text = styled.p`
  font-size: 1.2em; /* Adjusted font size */
  line-height: 1.6;
  color: ${({ theme }) => theme.textColor};
  flex-grow: 1; /* Allows text to fill available space */
  // overflow-y: auto; /* Scrollbar if text overflows */
`;

const LearnMoreLink = styled.a`
  font-size: 1em;
  color: #000; /* Blue link color */
  margin-top: 15px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Subref2 = styled.div` /* Changed to div for better styling control */
  text-align: center;
  padding: 80px 0; /* Increased padding */
  // background: linear-gradient(90deg, #8e8e8e 25%, #AC544C 100%);
  // color: #fff;
  font-size: 1.5em; /* Adjusted font size */

  @media (max-width: 768px) {
    font-size: 1.2em;
    padding: 50px 0;
  }
`;

const StyledButton = styled.button`
  background: linear-gradient(135deg, #dfe4eb 0%, #5a6b7c 100%);
  color: #000;
  border: none;
  padding: 15px 40px; /* Adjusted padding */
  border-radius: 5px; /* Less rounded corners */
  cursor: pointer;
  margin-top: 30px;
  font-size: 1em;
  transition: background-color 0.3s; /* Smooth transition on hover */

  &:hover {
    background: linear-gradient(135deg, #5a6b7c 0%, #dfe4eb 100%);
    transform: scale(1.1);
  }
`;
const FutureServicesWrapper = styled.div`
  max-width: 1200px;
  margin: 30px auto; // Added margin
  padding: 0 30px; // Added padding
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
`;

const Services = () => {
  const [theme] = useState(lightTheme);

  const futureServices = [
    { title: "Securities Recommendations", text: "VegaVeta delivers well-researched, unbiased securities recommendations through a rigorous analytical approach. Our expert-driven analysis covers equities, commodities, and other financial instruments, leveraging fundamental and technical research to identify high-quality investment opportunities. By combining evidence-based assessments with market expertise, we help clients make informed investment decisions with confidence.", link: "/security" },
    { title: "Forecasting", text: "VegaVeta’s advanced forecasting leverages state-of-the-art predictive models and artificial intelligence to deliver highly accurate market projections. By analyzing historical data, real-time trends, and complex economic patterns, our forecasting methods provide data-driven guidance into potential future market movements. Designed for investors and businesses, our predictive analytics enhance decision-making and strategic planning.", link: "/forecasting" },
  ];

  const otherServices = [
    // ... all your other service data
    { title: "Comprehensive Analysis", text: "Our comprehensive company-level fundamental analysis examines financial health through key indicators like revenue, profitability, cash flow, and debt structure. It evaluates the sustainability of the business model, competitive standing within the industry, and potential for long-term growth. By analyzing historical performance, market trends, and strategic initiatives, we provide a deep understanding of a company's overall strength and trajectory.", link: "/comprehensive" },
    { title: "Economic Data", text: "Our economic data service provides in-depth understanding of macroeconomic indicators, trends, and forecasts to support strategic decision-making. We analyze key metrics such as GDP growth, inflation, interest rates, employment figures, and consumer spending. By leveraging real-time data and historical patterns, we help businesses and investors anticipate economic shifts and adapt to changing market conditions.", link: "/economicData" },
    { title: "Interactive Chart", text: "Our interactive charts enable clients to create dynamic, customized visualizations using our extensive financial and economic data. With access to a vast database, users can tailor charts to highlight key metrics, track trends, and analyze indicators in real time. This powerful tool enhances informed decision-making by transforming complex information into clear, actionable guidance.", link: "/interactive" },
    { title: "Regional Analysis", text: "Our regional analysis provides in-depth evaluations of specific geographic markets, assessing the economic, social, and industry-specific factors that shape local market dynamics. By analyzing key indicators such as economic growth, consumer behavior, regulatory landscape, and demographic trends, we deliver a clear understanding of regional strengths, challenges, and market potential to support strategic decision-making.", link: "/regional" },
    { title: "Weekly Chartbook", text: "Our Weekly Chartbook delivers up-to-date graphical analyses of market trends and performance in a convenient PDF format. Featuring a curated selection of charts, it provides clear visual interpretations of key financial, economic, and industry movements. Designed for quick reference, this resource helps clients stay informed with the latest market perspectives.", link: "/chartbook" },
    { title: "Market Insights", text: "Our Market Insights service provides comprehensive coverage of key financial metrics and indicators, offering a deeper understanding of market dynamics. We analyze trends across equities, mutual funds, ETFs, REITs, commodities, and macroeconomic factors, delivering actionable intelligence to support informed decision-making. With real-time data and expert analysis, we help clients navigate evolving market conditions with confidence.", link: "/market-insights" },
    { title: "Portfolio Analysis", text: "VegaVeta’s Portfolio Analysis service offers a comprehensive evaluation of your investment portfolio, going beyond traditional performance metrics. We assess asset allocation, risk exposure, diversification, and overall efficiency to ensure alignment with your financial goals. By leveraging advanced analytics and industry benchmarks, we provide effective strategies to optimize portfolio performance and enhance long-term investment strategies.", link: "/portfolio-analysis" },

  ];

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header />
      <Container>
        <ContentWrapper>
          {otherServices.map((service, index) => (
            <Card key={index}>
              <Title>{service.title}</Title>
              <Text>{service.text}</Text>
              <LearnMoreLink href={service.link}>Learn more..</LearnMoreLink>
            </Card>
          ))}
        </ContentWrapper>

        {/* Future Services Section */}
        <Title1>Future Services</Title1>
        {/* <hr/> */}
        <FutureServicesWrapper>
          {futureServices.map((service, index) => (
            <Card key={index}>
              <Title>{service.title}</Title>
              <Text>{service.text}</Text>
              <LearnMoreLink href={service.link}>Learn more..</LearnMoreLink>
            </Card>
          ))}
        </FutureServicesWrapper>
      </Container>
      <Subref2>
        See how VegaVeta can help your management firm reach new and exciting goals for your clients.<br />
        <StyledButton>Start your free trial</StyledButton>
      </Subref2>
      <Footer />
    </ThemeProvider>
  );
};

export default Services;